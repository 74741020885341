/**
 * Created by hajer on 07/09/17.
 */
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class BlogCard extends Component {
    onClick(link) {
        window.location.href = link;
    }

    render() {
        let blogPicture = this.props.image.replace('{size}', '_lg');
        let backgroundImage = {
            backgroundImage: ['url(' + blogPicture + ')'],
        };
        let entryText = this.props.blog.entryText.substring(0, 50) + '...';
        return (
            <div className="blog-card">
                <Col
                    xs={12}
                    md={4}
                    style={{ cursor: 'pointer' }}
                    onClick={this.onClick.bind(this, this.props.button_link)}
                >
                    <Row
                        style={backgroundImage}
                        className="no-gutter card-background small-card card"
                    ></Row>
                    <Row>
                        <h5>{this.props.blog.title}</h5>
                        <p>{entryText}</p>
                        <a
                            className="card-link"
                            href={this.props.button_link}
                            target={this.props.button_link_target}
                        >
                            Les mer
                        </a>
                    </Row>
                </Col>
            </div>
        );
    }
}
