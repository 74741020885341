import 'bootstrap/dist/css/bootstrap.css';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Route, Switch } from 'react-router-dom';
import './css/app.css';
import './css/blog.css';
import './css/card.css';
import './css/form.css';
import './css/index.css';
import './css/navigation.css';
import ArticleDetail from './js/components/ArticleDetail';
import Blog from './js/components/blog/Blog.js';
import BlogDetails from './js/components/blog/BlogDetails.js';
import BoatDetail from './js/components/boat/BoatDetail';
import BoatRequestForm from './js/components/boat/BoatRequestForm';
import Booking from './js/components/booking/Booking';
import Turkalender from './js/components/calendar/Turkalender.js';
import Footer from './js/components/common/Footer.js';
import ExperienceDetail from './js/components/experience/ExperienceDetail';
import ExperienceRequestForm from './js/components/experience/ExperienceRequestForm';
import Front from './js/components/Front';
import Navigation from './js/components/navigation/Navigation';
import AllProducts from './js/components/product-list/AllProducts.js';
import TripDetail from './js/components/trip/TripDetail';
import { setApiToken } from './js/reducers/CognitoActions';
import { setLoadedState } from './js/reducers/ContentActions';

class Simple extends Component {
    render() {
        return (
            <Row className="content short-row">
                <Col xs={8} xsOffset={2}>
                    <h2 className="black">Tusen takk for bestillingen</h2>
                    <p className="centred">
                        Vi sender deg nå mer informasjon om turen også sees vi på brygga.
                    </p>
                </Col>
            </Row>
        );
    }
}

class App extends Component {
    componentDidMount() {
        this.props.dispatch(setApiToken());
        this.props.dispatch(setLoadedState(true));
    }

    componentDidUpdate() {
        const {
            loaded,
            dataFetched,
            articleFetched,
            tripFetched,
            productDataFetched,
            boatFetched,
            experienceFetched,
        } = this.props;

        var contentReady = false;

        if (
            dataFetched ||
            articleFetched ||
            tripFetched ||
            productDataFetched ||
            boatFetched ||
            experienceFetched
        ) {
            contentReady = true;
        }

        if (loaded && contentReady) {
            this.props.dispatch(setLoadedState(true));
        } else {
            this.props.dispatch(setLoadedState(false));
        }
    }

    render() {
        const { loaded } = this.props;

        return (
            <div className="App test">
                <Helmet>
                    <title>SeilNorge</title>
                    <script
                        type="text/javascript"
                        id="hs-script-loader"
                        async
                        defer
                        src="//js.hs-scripts.com/5373500.js"
                    />
                    <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NVLVQJP"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
                    <meta property="og:site_name" content="seilnorge.no" />
                    <meta property="og:type" content="website" />
                    <meta
                        name="description"
                        content="SeilNorge er landets ledende arrangør av inspirerende seilkurs, seilturer og ekspedisjoner. Vi seiler fra øy til fjell for å oppleve, se og lære. Vi leker oss i vinden, i vannet, på fjorden, i skjærgården, og i høyden i fjellene. Vi kombinerer seiling med fjell- og toppturer, kajakkpadling, fiske, musikk, mat, festivaler, skikjøring, brevandring og mye mer!"
                    />
                    <meta
                        name="keywords"
                        content="seil seiling seiltur seilkurs norge norskekysten kyst kysten lofoten"
                    />

                    <meta name="author" content="SeilNorge" />
                    <meta name="copyright" content="SeilNorge" />
                    <meta name="application-name" content="SeilNorge" />

                    <meta property="og:title" content="Unik tilgang til det beste av Nord-Norge" />

                    <meta
                        property="og:image"
                        content="https://seilnorge-images.imgix.net/images/fotoseilas/MG_Fotoseilas_0002_xl.jpg"
                    />
                    <meta
                        property="og:image"
                        content="https://seilnorge-images.imgix.net/images/forside_2_xl.jpg"
                    />
                    <meta
                        property="og:image"
                        content="https://seilnorge-images.imgix.net/images/fb_forsiden.jpg"
                    />
                    <meta
                        property="og:image"
                        content="https://seilnorge-images.imgix.net/images/header_turkalender_xl.jpg"
                    />

                    <meta
                        property="og:description"
                        content="Vi seiler fra øy til fjell for å oppleve, se og lære. Vi leker oss i vinden, i vannet, på fjorden, i skjærgården, og i høyden i fjellene. Vi kombinerer seiling med fjell- og toppturer, kajakkpadling, fiske, musikk, mat, festivaler, skikjøring, brevandring og mye mer!"
                    />
                </Helmet>
                <Switch>
                    <Route path={['/blog', '/blog-details']}>
                        <Navigation classNavbar="navbar-static-fixed-top-black" />
                        <Switch>
                            <Route path="/blog/:filter" component={Blog} />
                            <Route path="/blog-details/:id(\d+)-*" component={BlogDetails} />
                        </Switch>
                    </Route>
                    <Route>
                        {!loaded ? (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '0',
                                    left: '0',
                                    right: '0',
                                    bottom: '0',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Loader type="TailSpin" color="#FF6600" height={100} width={100} />
                            </div>
                        ) : (
                            <>
                                <Navigation classNavbar="navbar-static-fixed-top" />
                                <Switch>
                                    <Route exact path="/" component={Front} />
                                    <Route path="/trips/:id(\d+)-*" component={TripDetail} />
                                    <Route
                                        path="/experiences/:id(\w+)-*"
                                        component={ExperienceDetail}
                                    />
                                    <Route
                                        path="/experiences/:id(\w+)/request"
                                        component={ExperienceRequestForm}
                                    />
                                    <Route path="/boats/:id(\w+)-*" component={BoatDetail} />
                                    <Route
                                        path="/boats/:id(\w+)/request"
                                        component={BoatRequestForm}
                                    />
                                    <Route path="/booking" component={Booking} />
                                    <Route path="/articles/:id(\d+)-*" component={ArticleDetail} />
                                    <Route path="/alltrips/:filter" component={AllProducts} />
                                    <Route path="/turkalender/:filter" component={Turkalender} />
                                    <Route path="/bookingthanks" component={Simple} />
                                </Switch>
                                <Footer />
                            </>
                        )}
                    </Route>
                </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        loaded: state.content.loaded,
        dataFetched: state.trip.fetchedFront,
        articleFetched: state.article.fetched,
        tripFetched: state.trip.fetched,
        productDataFetched: state.calendar.fetchedProductData,
        boatFetched: state.boat.fetched,
        experienceFetched: state.experience.fetched,
    };
}

export default connect(mapStateToProps, null)(App);
