import React, { Component } from 'react';

class FlagUK extends Component {
    render() {
        return (
            <div className="flag-container">
                <svg
                    id="GREAT_BRITAIN"
                    data-name="GREAT BRITAIN"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 19.7"
                >
                    <title>gb for web</title>
                    <rect width="30" height="19.7" fill="#084ca1" />
                    <path
                        d="M30,1.15H26.25L18.43,6.28V1.15H11.55V6.49L3.29,1.15H0V3.81L5.93,7.66H0v6.82H5.88L0,18.36v2.49H3.89l7.66-5v5h6.88V15.72l7.91,5.13H30V18.38l-6-3.9h6V7.66H24l6-3.94Z"
                        transform="translate(0 -1.15)"
                        fill="#fff"
                    />
                    <path
                        d="M9.66,7.66,0,1.15V2.87L7.22,7.66Z"
                        transform="translate(0 -1.15)"
                        fill="#ed1c24"
                    />
                    <path
                        d="M20.36,14.48,30,20.85h0v-1.6l-7.2-4.77Z"
                        transform="translate(0 -1.15)"
                        fill="#ed1c24"
                    />
                    <path
                        d="M11.55,14.48h-2L0,20.85H2.29l9.26-6.05Z"
                        transform="translate(0 -1.15)"
                        fill="#ed1c24"
                    />
                    <path
                        d="M18.43,7.66h1.85L30,1.31V1.15H27.81L18.43,7.26Z"
                        transform="translate(0 -1.15)"
                        fill="#ed1c24"
                    />
                    <polygon
                        points="30 7.96 16.96 7.96 16.96 0 13.02 0 13.02 7.96 0 7.96 0 11.88 13.02 11.88 13.02 19.7 16.96 19.7 16.96 11.88 30 11.88 30 7.96"
                        fill="#ed1c24"
                    />
                </svg>
            </div>
        );
    }
}

export default FlagUK;
