import * as _ from 'lodash';

export default function reducer(
    state = {
        fetching: false,
        fetched: false,
        error: null,
        booking: [],
        participantCompleted: false,
        bookingComplete: false,
        bookingFailed: false,
        chevronState: false,
        termsState: true,
        subscribeComplete: false,
    },
    action
) {
    let newState;

    switch (action.type) {
        case 'DUMMY':
            newState = {
                ...state,
                form: {
                    participant: {
                        firstName: 'Eric Test',
                    },
                },
            };
            return newState;
        case 'ADD_PARTICIPANT':
            return {
                ...state,
                booking: action.payload,
                participantCompleted: true,
                termsState: true,
            };
        case 'ADD_PAYMENT':
            return {
                ...state,
                participantCompleted: false,
                bookingComplete: true,
                termsState: true,
            };
        case 'ADD_PAYMENT_REJECTED':
            return {
                ...state,
                participantCompleted: false,
                bookingComplete: false,
                bookingFailed: true,
                termsState: true,
                error: action.payload,
            };
        case 'TOGGLE_CHEVRON':
            return {
                ...state,
                chevronState: action.payload.chevronState,
            };
        case 'TOGGLE_FORM_BUTTON':
            return {
                ...state,
                termsState: action.payload.termsState,
            };
        case 'TOGGLE_TRIP_OPTION':
            newState = _.cloneDeep(state);
            if (newState.booking.options.find(o => o.id == action.payload.id))
                newState.booking.options = newState.booking.options.filter(
                    o => o.id != action.payload.id
                );
            else newState.booking.options.push(action.payload);
            return newState;
        case 'ADD_SUBSCRIBER':
            return {
                ...state,
                subscribeComplete: true,
            };
        case 'WAITINGLIST_REGISTRATION_SUCCESS':
            return {
                ...state,
                waitingListRegistrationComplete: true,
            };
        case 'WAITINGLIST_REGISTRATION_REJECTED':
            return {
                ...state,
                waitingListRegistrationComplete: false,
                error: action.payload,
            };
        case 'ADD_SUBSCRIBER_REJECTED':
            return {
                ...state,
                subscribeComplete: false,
                error: action.payload,
            };
        case 'START_BUSY_SCREEN':
            return {
                ...state,
                busyScreenActive: true,
            };
        case 'STOP_BUSY_SCREEN':
            return {
                ...state,
                busyScreenActive: false,
            };
        default:
            return state;
    }
}
