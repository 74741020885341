import * as _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Grid, Row, Col, Clearfix, Panel, PanelGroup } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom';

import LineHorisontal from '../common/LineHorisontal';
import Quote from './Quote';
import HeaderSeparator from '../common/HeaderSeparator';
import BoatCard from '../boat/BoatCard';

import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import '../../../css/image-gallery.css';
import '../../../css/trip-detail.css';

import { fetchExperience } from '../../reducers/ExperienceActions';
import { setLoadedState } from '../../reducers/ContentActions';
import { connect } from 'react-redux';

import URLBuilder from '../../helpers/URLBuilder';
import Utsolgt from '../gfx/Utsolgt';
import UtsolgtEnglish from '../gfx/UtsolgtEnglish';

class ExperienceDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chevronStateProgram: false,
            chevronStateIncluded: false,
            chevronStateInfo: false,
        };
    }

    componentDidMount() {
        const { match, experience, token, dataFetched, locale } = this.props;
        if (!dataFetched) {
            this.props.dispatch(fetchExperience(this.props.match.params.id));
        } else {
            this.props.dispatch(setLoadedState(true));
        }
    }

    componentDidUpdate() {
        console.log('componentDidUpdate');

        const { match, experience, token, dataFetched, locale } = this.props;

        moment.locale(locale);

        if (dataFetched) {
            if (match.params.id != experience.id) {
                console.log('dispatching');
                this.props.dispatch(fetchExperience(token, match.params.id, locale));
                window.scrollTo(0, 0);
            } else {
                this.props.dispatch(setLoadedState(true));
            }
        } else {
            this.props.dispatch(fetchExperience(this.props.match.params.id));
        }
    }

    toggleChevron(event) {
        var newChevronStateProgram;
        var newChevronStateIncluded;
        var newChevronStateInfo;

        switch (event) {
            case '1':
                newChevronStateProgram = !this.state.chevronStateProgram;
                newChevronStateIncluded = false;
                newChevronStateInfo = false;
                break;
            case '2':
                newChevronStateProgram = false;
                newChevronStateIncluded = !this.state.chevronStateIncluded;
                newChevronStateInfo = false;
                break;
            case '3':
                newChevronStateProgram = false;
                newChevronStateIncluded = false;
                newChevronStateInfo = !this.state.chevronStateInfo;
                break;
            default:
        }

        this.setState({
            chevronStateProgram: newChevronStateProgram,
            chevronStateIncluded: newChevronStateIncluded,
            chevronStateInfo: newChevronStateInfo,
        });
    }

    convertToLocalPrice(price, locale) {
        const originalPrice = String(price);
        const priceThousands = originalPrice.slice(0, originalPrice.length - 3);
        const priceHundreds = originalPrice.slice(originalPrice.length - 3, originalPrice.length);

        switch (locale) {
            case 'en_US':
                return priceThousands + ',' + priceHundreds;
            default:
                return priceThousands + '.' + priceHundreds + ',-';
        }
    }

    createImageList(images, locale) {
        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        const imageList = images.map(function (image) {
            return {
                original: s3BaseUrl + image.s3Id.replace('{size}', '_sm'),
                srcSet:
                    s3BaseUrl +
                    image.s3Id.replace('{size}', '_md') +
                    ' 768w, ' +
                    s3BaseUrl +
                    image.s3Id.replace('{size}', '_lg') +
                    ' 992w, ' +
                    s3BaseUrl +
                    image.s3Id.replace('{size}', '_xl') +
                    ' 1200w',
            };
        });

        return imageList;
    }

    render() {
        const { dataFetched, experience, locale, lang } = this.props;

        var chevronIconProgram = this.state.chevronStateProgram
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';
        var chevronIconIncluded = this.state.chevronStateIncluded
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';
        var chevronIconInfo = this.state.chevronStateInfo
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        const headerCollaborationPartners = (
            <Row>
                <Col xs={8}>
                    <h5 className="bold">{lang.sidebarHeadingCollaborationPartners}</h5>
                </Col>
                <Col xs={4}>
                    <span className={chevronIconProgram}></span>
                </Col>
            </Row>
        );

        if (!experience) return <Grid />;

        var soldOutBanner;

        switch (locale) {
            case 'en_US':
                soldOutBanner = <UtsolgtEnglish />;
                break;
            default:
                soldOutBanner = <Utsolgt />;
                break;
        }

        // Preprocess experience.text: on 12 January this field was upgraded in CMS to be a TinyMCE field, i.e. we have to expect
        // that experience.text can contain HTML. Since this field will sometimes be non-HTML, sometimes HTML, and that text
        // inside should use <p>s for correct layout (the original template below was <p>{experience.text}</p>, check for non-HTML
        // and if so, pad the text with <p> and </p>.
        // RegExp gotten from: https://stackoverflow.com/a/15458987
        if (!/<[a-z][\s\S]*>/i.test(experience.text[locale])) {
            experience.text[locale] = '<p>' + experience.text[locale] + '</p>';
        }

        console.log(URLBuilder.experienceRequest(this.props.match.params.id));

        return (
            <Grid fluid={true}>
                {dataFetched && (
                    <div>
                        <Row className="no-gutter gallery-card">
                            <Col xs={12}>
                                <ImageGallery
                                    items={this.createImageList(experience.images, locale)}
                                    showThumbnails={false}
                                    showPlayButton={false}
                                    showFullscreenButton={false}
                                    userBrowserFullscreen={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} mdHidden lgHidden>
                                <div className="book-trip-container">
                                    {experience.soldOut == 0 && (
                                        <Link
                                            to={URLBuilder.experienceRequest(
                                                this.props.match.params.id
                                            )}
                                            className="btn btn-default orange book-trip"
                                        >
                                            {lang.bookingButton}
                                        </Link>
                                    )}
                                    {experience.soldOut == 1 && (
                                        <div className="soldout-banner-small">{soldOutBanner}</div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h2 className="black">{experience.heading[locale]}</h2>
                            </Col>
                        </Row>
                        <Row className="content">
                            <Col xs={12} md={7}>
                                <Row>
                                    <Col xs={12}>
                                        <p className="bold">{experience.ingress[locale]}</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: experience.text[locale]
                                                    .replace(/\n/g, '<br />')
                                                    .replace(
                                                        /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                        'https://seilnorge-images.imgix.net/images'
                                                    ),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <LineHorisontal />
                                {experience.quoteText[locale] && (
                                    <Quote
                                        text={experience.quoteText[locale]}
                                        name={experience.quoteName[locale]}
                                    />
                                )}
                            </Col>
                            <Col xs={12} md={5}>
                                <Row>
                                    <Col xs={12} xsHidden smHidden className="centred">
                                        <Link
                                            to={URLBuilder.experienceRequest(
                                                this.props.match.params.id
                                            )}
                                            className="btn btn-default large orange"
                                        >
                                            {lang.bookingButton}
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <PanelGroup
                                            accordion
                                            onSelect={this.toggleChevron.bind(this)}
                                            id="partners"
                                        >
                                            {experience.collaborationPartners.length > 0 && (
                                                <Panel eventKey="1" className="accordion-large">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            {headerCollaborationPartners}
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        {experience.collaborationPartners.map(
                                                            function (spec, i) {
                                                                return (
                                                                    <div key={i}>
                                                                        <p className="bold">
                                                                            {spec.heading[locale]}
                                                                        </p>
                                                                        <p>{spec.text[locale]}</p>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </Panel.Body>
                                                </Panel>
                                            )}
                                        </PanelGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Clearfix></Clearfix>
                        {experience.boats.length > 0 && (
                            <HeaderSeparator header={lang.relatedTripsHeading} />
                        )}
                        {experience.boats.length > 0 && (
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        {experience.boats.map(boat => (
                                            <BoatCard
                                                key={boat.id}
                                                listing_intro={boat.listingIntro[locale]}
                                                listing_header={boat.listingHeading[locale]}
                                                image={s3BaseUrl + boat.listingImage}
                                                button_link={URLBuilder.boat(
                                                    boat.id,
                                                    boat.slug[locale]
                                                )}
                                                button_text={lang.buttonTextReadMore}
                                            />
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </Grid>
        );
    }
}

const i18n = {
    nb_NO: {
        travelDays: 'dager',
        day: 'Dag',
        sidebarHeadingCollaborationPartners: 'Våre samarbeidspartnere',
        relatedTripsHeading: 'Andre opplevelser med seilbåt',
        buttonTextReadMore: 'Les mer',
        bookingButton: 'Be om forslag til tilbud',
        waitingListButton: 'Bestill - NB! Få plasser!',
        included: 'Inkludert',
        notIncluded: 'Ikke inkludert',
        currency: '',
    },
    en_US: {
        travelDays: 'days',
        day: 'Day',
        sidebarHeadingSpecification: 'Our collaboration partners',
        relatedTripsHeading: 'Other experiences by sailboat',
        buttonTextReadMore: 'Read more',
        bookingButton: 'Ask for suggestion and offer',
        waitingListButton: 'Book now - Few available seats!',
        included: 'Included',
        notIncluded: 'Not included',
        currency: ' NOK',
    },
};

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        dataFetched: state.experience.fetched,
        experience: state.experience.experience,
        locale: state.locale,
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(ExperienceDetail);
