import axios from 'axios';

const API_URL = 'https://loopback.seilnorge.no/lambda';
const LOOPBACK_URL = 'https://loopback.seilnorge.no/api';

export function dummy() {
    return (dispatch, getState) => {
        dispatch({
            type: 'DUMMY',
        });
    };
}

export function addParticipant() {
    return (dispatch, getState) => {
        const trip = getState().trip.trip;

        /***
         * There are two main modes of operation for this action. Until 27 November 2017, there was only the first mode, but
         * on that date the second mode was introduced. If the book_participants_on_waiting_list_event_not_main_event flag
         * is turned on, participants shall be put on a waiting list, instead of proceeding with payment and booking a
         * ticket.
         * This action is dispatched following completion of the personal information form, making it the perfect place to
         * either continue to the next screen in the current flow (payment screen), or directly proceed to registring the
         * participant on the waiting list.
         */

        if (trip.book_participants_on_waiting_list_event_not_main_event)
            doRegisterParticipantOnWaitingList(dispatch, getState);
        else addParticipantForNormalBooking(dispatch, getState);
    };
}

function doRegisterParticipantOnWaitingList(dispatch, getState) {
    dispatch({ type: 'START_BUSY_SCREEN' });

    const form = getState().form;
    const trip = getState().trip.trip;

    const waitingListEntry = {
        participant: prepareParticipantObject(form, trip),
        trip_id: trip.trip_id,
    };

    axios
        .post(LOOPBACK_URL + '/waitingListEntries', waitingListEntry)
        .then(function (response) {
            dispatch({ type: 'STOP_BUSY_SCREEN' });
            dispatch({ type: 'WAITINGLIST_REGISTRATION_SUCCESS', payload: response });
        })
        .catch(function (error) {
            dispatch({ type: 'STOP_BUSY_SCREEN' });
            dispatch({ type: 'WAITINGLIST_REGISTRATION_REJECTED', payload: error });
        });
}

function addParticipantForNormalBooking(dispatch, getState) {
    const form = getState().form;
    const trip = getState().trip.trip;

    const participant = prepareParticipantObject(form, trip);

    // Debug code added on 2018-02-02: send participant object to temporary loopback endpoint,
    // which in turn forwards this by email to Seilnorge
    axios
        .post(LOOPBACK_URL + '/_special/sendParticipantFormData', participant)
        .then(function (resp) {
            console.log('Participant form debug submit done. Response:');
            console.log(resp);
        })
        .catch(function (error) {
            console.log('Participant form debug submit resulted in error:');
            console.log(error);
        });

    dispatch({
        type: 'ADD_PARTICIPANT',
        payload: participant,
    });
}

function prepareParticipantObject(form, trip) {
    const participant = {
        trip_id: trip.trip_id,
        event_id: trip.event_id,
        price: trip.price,
        firstName: form.participant.values.firstName,
        lastName: form.participant.values.lastName,
        email: form.participant.values.email,
        mobile: form.participant.values.mobile,
        address: form.participant.values.address,
        postcode: form.participant.values.postcode,
        city: form.participant.values.city,
        options: [],
    };

    return participant;
}

export function doCardPayment() {
    return (dispatch, getState) => {
        dispatch({ type: 'ADD_PAYMENT' });
    };
}

export function doInvoicePayment() {
    return (dispatch, getState) => {
        dispatch({ type: 'ADD_PAYMENT' });
    };
}

export function toggleChevron() {
    return (dispatch, getState) => {
        const chevronState = { chevronState: getState().booking.chevronState ? false : true };

        dispatch({ type: 'TOGGLE_CHEVRON', payload: chevronState });
    };
}

export function toggleButtonFromCheckbox() {
    return (dispatch, getState) => {
        const termsState = { termsState: getState().booking.termsState ? false : true };

        dispatch({ type: 'TOGGLE_FORM_BUTTON', payload: termsState });
    };
}

export function toggleTripOption(tripOption) {
    return (dispatch, getState) => {
        dispatch({
            type: 'TOGGLE_TRIP_OPTION',
            payload: tripOption,
        });
    };
}

export function addSubscriber() {
    return (dispatch, getState) => {
        const form = getState().form;

        const header = {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': getState().cognito.apiToken,
            },
        };

        const body = {
            email: form.newsletter.values.email,
        };

        axios
            .post(API_URL + '/newsletter', body, header)
            .then(function (response) {
                dispatch({ type: 'ADD_SUBSCRIBER', payload: response });
            })
            .catch(function (error) {
                dispatch({ type: 'ADD_SUBSCRIBER_REJECTED', payload: error });
            });
    };
}
