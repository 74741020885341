import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';

import contentReducer from './ContentReducer';
import cognitoReducer from './CognitoReducer';
import tripReducer from './TripReducer';
import bookingReducer from './FormReducer';
import articleReducer from './ArticleReducer';
import socialReducer from './SocialReducer';
import localeReducer from './LocaleReducer';
import blogReducer from './BlogReducer';
import calendarReducer from './CalendarReducer';
import boatReducer from './BoatReducer';
import experienceReducer from './ExperienceReducer';
import requestReducer from './RequestReducer';

const seilNorgeReducers = combineReducers({
    content: contentReducer,
    cognito: cognitoReducer,
    trip: tripReducer,
    booking: bookingReducer,
    form: reduxFormReducer,
    article: articleReducer,
    social: socialReducer,
    locale: localeReducer,
    blog: blogReducer,
    calendar: calendarReducer,
    boat: boatReducer,
    experience: experienceReducer,
    request: requestReducer,
});

let store;

export function configureStore(preloadedState) {
    const middleware = applyMiddleware(thunk);
    let newStore = createStore(seilNorgeReducers, preloadedState, composeWithDevTools(middleware));
    store = newStore;
    return newStore;
}

export function deprecatedGetStoreState() {
    return store.getState();
}
