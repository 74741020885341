import * as _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Grid, Row, Col, Clearfix, Glyphicon, PanelGroup, Panel } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import LineHorisontal from '../common/LineHorisontal';
import Quote from './Quote';
import HeaderSeparator from '../common/HeaderSeparator';
import TripCard from './TripCard';

import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import '../../../css/image-gallery.css';
import '../../../css/trip-detail.css';

import { fetchTrip } from '../../reducers/TripActions';
import { setLoadedState } from '../../reducers/ContentActions';
import { connect } from 'react-redux';

import URLBuilder from '../../helpers/URLBuilder';
import Utsolgt from '../gfx/Utsolgt';
import UtsolgtEnglish from '../gfx/UtsolgtEnglish';

import convertToLocalPrice from '../../helpers/ConvertToLocalPrice';

class TripDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chevronStateProgram: false,
            chevronStateIncluded: false,
            chevronStateInfo: false,
        };
    }

    componentDidMount() {
        if (!this.props.dataFetched) {
            this.props.dispatch(
                fetchTrip(this.props.token, this.props.match.params.id, this.props.locale)
            );
            this.props.dispatch(setLoadedState(false));
        }
    }

    componentDidUpdate() {
        const { match, trip, token, dataFetched, locale } = this.props;

        moment.locale(locale);

        if (dataFetched) {
            if (match.params.id != trip.trip_id) {
                this.props.dispatch(fetchTrip(token, match.params.id, locale));
                window.scrollTo(0, 0);
            } else {
                this.props.dispatch(setLoadedState(true));
            }
        }
    }

    toggleChevron(event) {
        var newChevronStateProgram;
        var newChevronStateIncluded;
        var newChevronStateInfo;

        switch (event) {
            case '1':
                newChevronStateProgram = !this.state.chevronStateProgram;
                newChevronStateIncluded = false;
                newChevronStateInfo = false;
                break;
            case '2':
                newChevronStateProgram = false;
                newChevronStateIncluded = !this.state.chevronStateIncluded;
                newChevronStateInfo = false;
                break;
            case '3':
                newChevronStateProgram = false;
                newChevronStateIncluded = false;
                newChevronStateInfo = !this.state.chevronStateInfo;
                break;
            default:
        }

        this.setState({
            chevronStateProgram: newChevronStateProgram,
            chevronStateIncluded: newChevronStateIncluded,
            chevronStateInfo: newChevronStateInfo,
        });
    }

    createImageList(images) {
        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        const imageList = images.map(function (image) {
            return {
                original: s3BaseUrl + image.s3_id.replace('{size}', '_sm'),
                srcSet:
                    s3BaseUrl +
                    image.s3_id.replace('{size}', '_md') +
                    ' 768w, ' +
                    s3BaseUrl +
                    image.s3_id.replace('{size}', '_lg') +
                    ' 992w, ' +
                    s3BaseUrl +
                    image.s3_id.replace('{size}', '_xl') +
                    ' 1200w',
            };
        });

        return imageList;
    }

    render() {
        const { dataFetched, trip, locale, lang } = this.props;

        // Quickfix added 4 July 2017 by Eric: in some cases, the backend populates the trip.related_trips array with
        // unusable objects: { s3_id: "<some text>", caption: "" }. Filter away unusable object by checking for presence
        // of id attribute
        let relatedTrips;
        if (dataFetched) relatedTrips = trip.related_trips.filter(o => _.has(o, 'id') && o.id);
        else relatedTrips = [];

        var chevronIconProgram = this.state.chevronStateProgram
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';
        var chevronIconIncluded = this.state.chevronStateIncluded
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';
        var chevronIconInfo = this.state.chevronStateInfo
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        const headerProgram = (
            <Row>
                <Col xs={8}>
                    <h5 className="bold">{lang.sidebarHeadingProgram}</h5>
                </Col>
                <Col xs={4}>
                    <span className={chevronIconProgram}></span>
                </Col>
            </Row>
        );

        const headerIncluded = (
            <Row>
                <Col xs={8}>
                    <h5 className="bold">{lang.sidebarHeadingIncluded}</h5>
                </Col>
                <Col xs={4}>
                    <span className={chevronIconIncluded}></span>
                </Col>
            </Row>
        );

        const headerInfo = (
            <Row>
                <Col xs={8}>
                    <h5 className="bold">{lang.sidebarHeadingInfo}</h5>
                </Col>
                <Col xs={4}>
                    <span className={chevronIconInfo}></span>
                </Col>
            </Row>
        );

        if (!trip) return <Grid />;

        var startDate;

        switch (locale) {
            case 'en_US':
                startDate = moment(trip.departure).utc().format('MMMM D');
                break;
            default:
                startDate = moment(trip.departure).utc().format('D[.] MMMM');
                break;
        }

        const endDate = moment(trip.departure)
            .utc()
            .add(trip.travel_days - 1, 'day')
            .format('LL');

        var soldOutBanner;

        switch (locale) {
            case 'en_US':
                soldOutBanner = <UtsolgtEnglish />;
                break;
            default:
                soldOutBanner = <Utsolgt />;
                break;
        }

        // Preprocess trip.text: on 12 January this field was upgraded in CMS to be a TinyMCE field, i.e. we have to expect
        // that trip.text can contain HTML. Since this field will sometimes be non-HTML, sometimes HTML, and that text
        // inside should use <p>s for correct layout (the original template below was <p>{trip.text}</p>, check for non-HTML
        // and if so, pad the text with <p> and </p>.
        // RegExp gotten from: https://stackoverflow.com/a/15458987
        if (!/<[a-z][\s\S]*>/i.test(trip.text)) {
            trip.text = '<p>' + trip.text + '</p>';
        }

        return (
            <Grid fluid={true}>
                {dataFetched && (
                    <div>
                        <Helmet>
                            <title>{trip.heading}</title>
                            <meta property="og:title" content={trip.heading} />
                            {trip.images.map(img => (
                                <meta
                                    property="og:image"
                                    content={`https://seilnorge-images.imgix.net/images/${img.s3_id.replace(
                                        '{size}',
                                        '_xl'
                                    )}`}
                                />
                            ))}
                            <meta property="og:description" content={trip.ingress} />
                        </Helmet>
                        <Row className="no-gutter gallery-card">
                            <Col xs={12}>
                                <ImageGallery
                                    items={this.createImageList(trip.images)}
                                    showThumbnails={false}
                                    showPlayButton={false}
                                    showFullscreenButton={false}
                                    userBrowserFullscreen={false}
                                    onImageLoad={() => this.props.dispatch(setLoadedState(true))}
                                    disableSwipe={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} mdHidden lgHidden>
                                <div className="book-trip-container">
                                    {trip.sold_out == 0 && (
                                        <Link
                                            to={'/booking'}
                                            className="btn btn-default orange book-trip"
                                        >
                                            {lang.bookingButton}
                                        </Link>
                                    )}
                                    {trip.sold_out == 1 && (
                                        <div className="soldout-banner-small">{soldOutBanner}</div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h2 className="black">{trip.heading}</h2>
                            </Col>
                        </Row>
                        <Row className="content">
                            <Col xs={12} md={7}>
                                <Row>
                                    <Col xs={12}>
                                        <h1 className="orange">
                                            {convertToLocalPrice(trip.price, locale)}
                                            <span className="currency">{lang.currency}</span>
                                        </h1>
                                    </Col>
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={6} className="travel-stats">
                                                <span className="pull-right">
                                                    <Glyphicon
                                                        glyph="calendar"
                                                        className="orange"
                                                    />
                                                    <p>
                                                        {startDate} - {endDate}
                                                    </p>
                                                </span>
                                            </Col>
                                            <Col xs={6} className="travel-stats">
                                                <Glyphicon glyph="time" className="orange" />
                                                <p>
                                                    {trip.travel_days} {lang.travelDays}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <p className="bold">{trip.ingress}</p>
                                    </Col>
                                    <Col xs={12}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: trip.text
                                                    .replace(/\n/g, '<br />')
                                                    .replace(
                                                        /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                        'https://seilnorge-images.imgix.net/images'
                                                    ),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <LineHorisontal />
                                <Quote text={trip.quote_text} name={trip.quote_name} />
                            </Col>
                            <Col xs={12} md={5}>
                                <Row>
                                    <Col xs={12} xsHidden smHidden className="centred">
                                        {/*TODO: move trip to redux store insted of passing it down*/}
                                        <Link
                                            to={'/booking'}
                                            className="btn btn-default large orange"
                                            trip={trip} 
                                        >
                                            {!trip.book_participants_on_waiting_list_event_not_main_event &&
                                                lang.bookingButton}
                                            {trip.book_participants_on_waiting_list_event_not_main_event ===
                                                1 && lang.waitingListButton}
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <PanelGroup
                                            accordion
                                            id="trip-details"
                                            onSelect={this.toggleChevron.bind(this)}
                                        >
                                            {trip.itinerary.length > 0 && (
                                                <Panel eventKey="1" className="accordion-large">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            {headerProgram}
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        {trip.itinerary.map(function (day) {
                                                            return (
                                                                <div key={day.id}>
                                                                    <p className="bold">
                                                                        {day.heading}
                                                                    </p>
                                                                    <p>{day.text}</p>
                                                                </div>
                                                            );
                                                        })}
                                                    </Panel.Body>
                                                </Panel>
                                            )}
                                            {trip.included.length > 0 && (
                                                <Panel eventKey="2" className="accordion-large">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            {headerIncluded}
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        <p className="bold">{lang.included}</p>
                                                        <ul>
                                                            {trip.included.map(function (bullet) {
                                                                return (
                                                                    <li key={bullet.id}>
                                                                        {bullet.text}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                        <p className="bold">{lang.notIncluded}</p>
                                                        <ul>
                                                            {trip.not_included.map(function (
                                                                bullet
                                                            ) {
                                                                return (
                                                                    <li key={bullet.id}>
                                                                        {bullet.text}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Panel.Body>
                                                </Panel>
                                            )}
                                            {trip.practical_info.length > 0 && (
                                                <Panel eventKey="3" className="accordion-large">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            {headerInfo}
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        {trip.practical_info.map(function (info) {
                                                            return (
                                                                <div key={info.id}>
                                                                    <p className="bold">
                                                                        {info.heading}
                                                                    </p>
                                                                    <p
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: info.text
                                                                                .replace(
                                                                                    /\n/g,
                                                                                    '<br />'
                                                                                )
                                                                                .replace(
                                                                                    /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                                                    'https://seilnorge-images.imgix.net/images'
                                                                                ),
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </Panel.Body>
                                                </Panel>
                                            )}
                                        </PanelGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Clearfix></Clearfix>
                        {relatedTrips.length > 0 && (
                            <HeaderSeparator header={lang.relatedTripsHeading} />
                        )}
                        {relatedTrips.length > 0 && (
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        {trip.related_trips.map(trip => (
                                            <TripCard
                                                key={trip.id}
                                                listing_intro={trip.listing_intro}
                                                listing_header={trip.listing_heading}
                                                image={s3BaseUrl + trip.listing_image}
                                                button_link={URLBuilder.trip(trip.id, trip.slug)}
                                                button_text={lang.buttonTextReadMore}
                                            />
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </Grid>
        );
    }
}

const i18n = {
    nb_NO: {
        travelDays: 'dager',
        day: 'Dag',
        sidebarHeadingProgram: 'Program',
        sidebarHeadingIncluded: 'Hva er inkludert',
        sidebarHeadingInfo: 'Praktisk informasjon',
        relatedTripsHeading: 'Lignende turer',
        buttonTextReadMore: 'Les mer',
        bookingButton: 'Bestill',
        waitingListButton: 'Bestill - NB! Få plasser!',
        included: 'Inkludert',
        notIncluded: 'Ikke inkludert',
        currency: '',
    },
    en_US: {
        travelDays: 'days',
        day: 'Day',
        sidebarHeadingProgram: 'Itinerary',
        sidebarHeadingIncluded: "What's included",
        sidebarHeadingInfo: 'Practical information',
        relatedTripsHeading: 'Similar trips',
        buttonTextReadMore: 'Read more',
        bookingButton: 'Book now',
        waitingListButton: 'Book now - Few available seats!',
        included: 'Included',
        notIncluded: 'Not included',
        currency: ' NOK',
    },
};

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        dataFetched: state.trip.fetched,
        trip: state.trip.trip,
        locale: state.locale,
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(TripDetail);
