import * as _ from 'lodash';

export default function reducer(
    state = {
        filter: 'Alle',
        fetchedProductData: false,
    },
    action
) {
    switch (action.type) {
        case 'CHANGE_FILTER':
            return {
                ...state,
                activeFilter: action.payload,
                filteredProducts: filterProductsByKeyword(state.calendarProducts, action.payload),
            };
        case 'FETCH_ALL_PRODUCTS_FULFILLED':
            const calendarProducts = action.payload.data.filter(
                prod => prod.active_on_trip_calendar && prod.type == 'Trip'
            );
            return {
                ...state,
                fetching: false,
                fetchedProductData: true,
                allProducts: action.payload.data,
                calendarProducts,
                filteredProducts: filterProductsByKeyword(calendarProducts, state.activeFilter),
            };
        case 'FETCH_ALL_PRODUCTS_REJECTED':
            return { ...state, fetching: false, error: action.payload, fetchedProductData: false };
        default:
            return state;
    }
}

function filterProductsByKeyword(products, filterKeyword) {
    if (!products || !_.isArray(products) || products.length == 0) return [];
    if (!filterKeyword || filterKeyword === '' || filterKeyword === 'Alle') return products;
    const filterKeywords = filterKeyword.split('-');
    console.log('hey');

    return products.filter(p => _.some(p.keywords, keyword => _.includes(filterKeywords, keyword)));
}
