import React from 'react';
import { Row, Col } from 'react-bootstrap';

const i18n = {
    nb_NO: {
        thankYouHeading: 'Takk for at du vil høre mer fra oss',
        thankYouInformation: 'Vi sender deg nå litt informasjon om hva vi driver med.',
    },
    en_US: {
        thankYouHeading: 'Takk for at du vil høre mer fra oss',
        thankYouInformation: 'Vi sender deg nå litt informasjon om hva vi driver med.',
    },
};

const NewsletterThanks = ({ locale }) => {
    var lang = i18n[locale];

    return (
        <Row>
            <Col xs={8} xsOffset={2}>
                <h2 className="black">{lang.thankYouHeading}</h2>
                <p className="centred">{lang.thankYouInformation}</p>
            </Col>
        </Row>
    );
};

export default NewsletterThanks;
