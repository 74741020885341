import * as _ from 'lodash';

export default function reducer(
    state = {
        fetching: false,
        fetched: false,
        fetchingBoatData: false,
        fetchedBoatData: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case 'FETCH_BOAT_BY_ID_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_BOAT_BY_ID_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                boat: action.payload.data,
            };
    }
    return state;
}
