import React, { Component } from 'react';
import convertToNorwegianPrice from '../../helpers/ConvertToNorwegianPrice';
import TripBookingConfig from '../../../json/TripBookingConfig.json';
import moment from 'moment';
import 'moment/locale/nb';

const i18n = {
    nb_NO: {
        summary: 'Sammendrag',
        description: 'Beskrivelse',
        cardFee: 'Kortavgift',
        basePrice: 'Grunnpris',
        orderTotal: 'Totalt',
        depositPayableNow: 'Depositum å betale nå',
        payableNow: 'Totalt å betale nå',
        remainingAmountDue60DaysBeforeDeparture: 'Restbeløp å betale 60 dager før avreise',
    },
    en_US: {
        summary: 'Order summary',
        description: 'Description',
        cardFee: 'Card fee',
        basePrice: 'Sum',
        orderTotal: 'Order total',
        depositPayableNow: 'Deposit, payable now',
        payableNow: 'Payable now',
        remainingAmountDue60DaysBeforeDeparture: 'The remainder, due 60 days before departure',
    },
};

class PaymentSummary extends Component {
    render() {
        const {
            booking: { booking },
            trip: { trip },
            paymentType,
            cardFundingType,
            serverTime,
            locale,
        } = this.props;

        const lang = i18n[locale];

        let tripPrice = trip.price;
        let cardFee = null,
            cardFundingTypeName = null;
        if (paymentType == 'card') {
            switch (cardFundingType) {
                case 'debit':
                    cardFee = TripBookingConfig.debitCardFee;
                    cardFundingTypeName = 'Debetkort';
                    break;

                case 'credit':
                    cardFee = TripBookingConfig.creditCardFee;
                    cardFundingTypeName = 'Kredittkort';
                    break;

                case 'prepaid':
                    cardFee = TripBookingConfig.prepaidCardFee;
                    cardFundingTypeName = 'Forhåndsbetalt kort';
                    break;
            }
        }

        const totalPrice =
            tripPrice + cardFee + booking.options.reduce((sum, o) => sum + o.price, 0);

        const now = moment(serverTime);
        const departure = moment(trip.departure);
        const threshold = departure
            .hours(3)
            .subtract(TripBookingConfig.depositDaysThreshold, 'days');

        const isBeforeThreshold = now.isBefore(threshold);

        if (isBeforeThreshold) {
            const deposit = trip.deposit;
            return this.renderWithDeposit({
                trip,
                options: booking.options,
                paymentType,
                tripPrice,
                deposit,
                cardFee,
                cardFundingTypeName,
                totalPrice,
                departure,
                lang,
            });
        } else {
            return this.renderWithoutDeposit({
                trip,
                options: booking.options,
                paymentType,
                tripPrice,
                cardFee,
                cardFundingTypeName,
                totalPrice,
                lang,
            });
        }
    }

    renderWithDeposit(params) {
        return (
            <div>
                <h4 className="black">{params.lang.summary}</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{params.lang.description}</th>
                            <th className="text-right">Sum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {params.lang.basePrice} {params.trip.heading}
                            </td>
                            <td className="text-right">
                                {convertToNorwegianPrice(params.tripPrice)}
                            </td>
                        </tr>
                        {params.options.map(o => (
                            <tr key={o.id}>
                                <td>{o.name}</td>
                                <td className="text-right">{convertToNorwegianPrice(o.price)}</td>
                            </tr>
                        ))}
                        {params.paymentType == 'card' && params.cardFee && (
                            <tr>
                                <td>{params.lang.cardFee}</td>
                                <td className="text-right">
                                    {convertToNorwegianPrice(params.cardFee)}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td style={{ padding: '5px 10px' }}>
                                <strong>{params.lang.total}</strong>
                            </td>
                            <td style={{ padding: '5px 10px' }} className="text-right">
                                <strong>{convertToNorwegianPrice(params.totalPrice)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="table table-bordered">
                    <tbody>
                        <tr style={{ backgroundColor: '#fbfbfb' }}>
                            <td>
                                <strong>
                                    <span style={{ fontSize: 'larger' }}>
                                        {params.lang.depositPayableNow}
                                    </span>
                                </strong>
                            </td>
                            <td className="text-right">
                                <strong>
                                    <span style={{ fontSize: 'larger' }}>
                                        {convertToNorwegianPrice(params.deposit)}
                                    </span>
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <em>
                                    <span>
                                        {params.lang.remainingAmountDue60DaysBeforeDeparture} (
                                        {params.departure.format('D. MMMM YYYY')})
                                    </span>
                                </em>
                            </td>
                            <td className="text-right">
                                <em>
                                    <span style={{ fontSize: 'larger' }}>
                                        {convertToNorwegianPrice(
                                            params.totalPrice - params.deposit
                                        )}
                                    </span>
                                </em>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderWithoutDeposit(params) {
        return (
            <div>
                <h4 className="black">{params.lang.summary}</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{params.lang.description}</th>
                            <th className="text-right">Sum</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {params.lang.basePrice} {params.trip.heading}
                            </td>
                            <td className="text-right">
                                {convertToNorwegianPrice(params.tripPrice)}
                            </td>
                        </tr>
                        {params.options.map(o => (
                            <tr>
                                <td>{o.name}</td>
                                <td className="text-right">{convertToNorwegianPrice(o.price)}</td>
                            </tr>
                        ))}
                        {params.paymentType == 'card' && params.cardFee && (
                            <tr>
                                <td>{params.lang.cardFee}</td>
                                <td className="text-right">
                                    {convertToNorwegianPrice(params.cardFee)}
                                </td>
                            </tr>
                        )}
                        <tr style={{ backgroundColor: '#fbfbfb' }}>
                            <td style={{ padding: '5px 10px' }}>
                                <strong>{params.lang.payableNow}</strong>
                            </td>
                            <td style={{ padding: '5px 10px' }} className="text-right">
                                <strong>{convertToNorwegianPrice(params.totalPrice)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PaymentSummary;