import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { Radio } from 'react-bootstrap';
import Header from '../common/Header';
import BookingFormParticipant from './BookingFormParticipant';
import StripeCheckout from '../stripe/StripeCheckout';
import BookingFormPayment from './BookingFormPayment';
import BookingThanks from './BookingThanks';
import BookingFailed from './BookingFailed';
import WaitingListThanks from './WaitingListThanks';

import {
    dummy,
    addParticipant,
    doCardPayment,
    doInvoicePayment,
    toggleChevron,
    toggleButtonFromCheckbox,
    toggleTripOption,
} from '../../reducers/FormActions';
import { connect } from 'react-redux';

class Booking extends Component {
    //Why is there a constructer here with when the application uses redux?
    //When handleing stripe payments with stripe checkout, the user is redirected to stripes paymentpage and then back to /booking. 
    //This whipes the privous state, therefor a query in the url is used to complete the customer flow (user is redirected to payment success or payment canceled). 
    constructor(props){
        super(props);
        this.state = {
            paymentSuccess: null,
            paymentCanceled: null,
            useStripeCheckout: false,
        }
    }

    componentDidMount(){
        let success = new URLSearchParams(window.location.search).get("success");
        let canceled = new URLSearchParams(window.location.search).get("canceled");
        if(success){
            this.setState({ paymentSuccess: success })
        } else if (canceled) {
            this.setState({ paymentCanceled: canceled })
        }
    }

    render() {
        const {
            trip,
            participantCompleted,
            bookingComplete,
            bookingFailed,
            waitingListRegistrationComplete,
            lang,
        } = this.props;
        
        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';
        if (participantCompleted) {
            return (
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_booking_xl.jpg'} header={lang.headerText} />
                    <Row className="content" style={{paddingBottom:"0", marginBottom:"0"}}>
                        <Col xs={12} sm={6} smOffset={3}>
                            <h3 className="black">{lang.headingPayment}</h3>
                            <h5 className="black">{lang.methodPayment}</h5>
                            
                            <Radio
                            type="radio" 
                            id="invoce" 
                            name="priceOption" 
                            onChange={() => this.setState({useStripeCheckout: false})} 
                            defaultChecked
                            >
                                {lang.methodInvoice}
                            </Radio>
                            <Radio
                            type="radio" 
                            id="card"  
                            name="priceOption" 
                            onChange={() => this.setState({useStripeCheckout: true})} 
                            >
                                {lang.methodCard}
                            </Radio>
                        </Col>      
                    </Row>
                    <Row className="content" style={{paddingTop:"0", marginTop:"0"}}>
                        <Col>
                            {!this.state.useStripeCheckout &&
                                <BookingFormPayment {...this.props} />
                            }
                            {this.state.useStripeCheckout &&
                                <StripeCheckout/>                    
                            }
                        </Col>      
                    </Row>
                </Grid>
            );
        } else if (waitingListRegistrationComplete) {
            return (
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_booking_xl.jpg'} header={lang.headerText} />
                    <WaitingListThanks {...this.props} />
                </Grid>
            );
        } else if (bookingComplete || this.state.paymentSuccess) {
            return (
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_booking_xl.jpg'} header={lang.headerText} />
                    <BookingThanks {...this.props} />
                </Grid>
            );
        } else if (bookingFailed || this.state.paymentCanceled) {
            return (
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_booking_xl.jpg'} header={lang.headerText} />
                    <BookingFailed {...this.props} />
                </Grid>
            );
        } else if(trip) {
            return (
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_booking_xl.jpg'} header={lang.headerText} />
                    <BookingFormParticipant {...this.props} />
                </Grid>
            );
        } else {
            return (
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_booking_xl.jpg'} header={lang.headerText} />
                </Grid>
            );
        }
    }
}

const i18n = {
    nb_NO: {
        headerText: 'Ut på tur',
        headingPayment: 'Nå er det like før vi kan heise seil',
        methodPayment: "Velg betalingsmetode",
        methodInvoice: "Faktura på epost",
        methodCard: "Kort",
    },
    en_US: {
        headerText: 'Trip booking',
        headingPayment: 'Thank you, we are soon ready to set sail',
        methodPayment: "Choose payment method",
        methodInvoice: "Invoice by email",
        methodCard: "Card",
    },
};

function mapStateToProps(state) {
    return {
        participantCompleted: state.booking.participantCompleted,
        chevronState: state.booking.chevronState,
        termsState: state.booking.termsState,
        bookingComplete: state.booking.bookingComplete,
        bookingFailed: state.booking.bookingFailed,
        waitingListRegistrationComplete: state.booking.waitingListRegistrationComplete,
        busyScreenActive: state.booking.busyScreenActive,
        locale: state.locale,
        lang: i18n[state.locale],
        trip: state.trip.trip,
    };
}

const mapDispatchToProps = dispatch => ({
    dummy: () => dispatch(dummy()),
    addParticipant: () => dispatch(addParticipant()),
    doCardPayment: () => dispatch(doCardPayment()),
    doInvoicePayment: () => dispatch(doInvoicePayment()),
    toggleChevron: () => dispatch(toggleChevron()),
    toggleButtonFromCheckbox: () => dispatch(toggleButtonFromCheckbox()),
    toggleTripOption: tripOption => dispatch(toggleTripOption(tripOption)),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
