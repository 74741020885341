import * as _ from 'lodash';

import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Header from '../common/Header';
import CalendarCard from './CalendarCard.js';
import CalendarFilter from './CalendarFilter';
import { changeCalendarFilter } from '../../reducers/CalendarActions';
import { Helmet } from 'react-helmet';

import { fethcAllProducts } from '../../reducers/CalendarActions';
import { connect } from 'react-redux';

class Turkalender extends Component {
    componentDidMount() {
        const { match, productDataFetched, locale, lang, activeFilter, filteredProducts } =
            this.props;

        if (!activeFilter) {
            this.props.dispatch(changeCalendarFilter(match.params.filter));
        }

        if (!productDataFetched) {
            this.props.dispatch(fethcAllProducts(locale));
        }
    }

    componentDidUpdate() {
        const { match, productDataFetched, locale, lang, activeFilter, filteredProducts } =
            this.props;

        if (!activeFilter) {
            this.props.dispatch(changeCalendarFilter(match.params.filter));
        }

        if (!productDataFetched) {
            this.props.dispatch(fethcAllProducts(locale));
        }
    }

    render() {
        const { match, productDataFetched, locale, lang, activeFilter, filteredProducts } =
            this.props;

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        if (productDataFetched) {
            let products = filteredProducts;

            var sortedTrips = _.sortBy(products, p => p.departure_date);
            var xtrips = sortedTrips.map(trip => (
                <CalendarCard key={trip.trip_id} trip={trip} locale={locale} />
            ));
        }

        return (
            <>
                <Helmet>
                    <title>{lang.header}</title>
                </Helmet>
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_turkalender_xl.jpg'} header={lang.header} />
                    <CalendarFilter {...this.props} />
                    <Row className="content">
                        <Col xs={12}>{xtrips}</Col>
                    </Row>
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        productDataFetched: state.calendar.fetchedProductData,
        filter: state.trip.filters,
        locale: state.locale,
        lang: i18n[state.locale],
        activeFilter: state.calendar.activeFilter,
        allProducts: state.calendar.allProducts,
        filteredProducts: state.calendar.filteredProducts,
    };
}

const i18n = {
    nb_NO: {
        header: 'Turkalender',
    },
    en_US: {
        header: 'Activity calendar',
    },
};

export default connect(mapStateToProps, null)(Turkalender);
