import React from 'react';
import { Row, Col } from 'react-bootstrap';

const i18n = {
    nb_NO: {
        errorHeading: 'Oi da...',
        errorDescription:
            'Noe gikk feil under bestillingen. Ta gjerne kontakt med Linda på e-post <a href="mailto:flaskepost@seilnorge.no" title="Send e-post til SeilNorge">flaskepost@seilnorge.no</a> eller telefon <a href="tel:004741482061" title="Ring SeilNorge">+47 414 82 061</a> for å fullføre bestillingen.',
    },
    en_US: {
        errorHeading: 'Oh snap...',
        errorDescription:
            'Something went wrong during the booking. Please contact Linda at <a href="mailto:flaskepost@seilnorge.no" title="Send e-post til SeilNorge">flaskepost@seilnorge.no</a> or by phone <a href="tel:004741482061" title="Call SailNorway">+47 414 82 061</a> to complete the booking.',
    },
};

const BookingFailed = ({ locale }) => {
    const lang = i18n[locale];
    return (
        <Row className="content short-row">
            <Col xs={8} xsOffset={2}>
                <h2 className="black">{lang.errorHeading}</h2>
                <p
                    className="centred"
                    dangerouslySetInnerHTML={{ __html: lang.errorDescription }}
                />
            </Col>
        </Row>
    );
};

export default BookingFailed;
