import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import FilterBlogs from '../blog/FilterBlogs';
import { Helmet } from 'react-helmet';

import { fetchBlog } from '../../reducers/BlogActions';
import { fetchAllBlogsByCategory } from '../../reducers/BlogActions';
import { connect } from 'react-redux';

class BlogDetails extends Component {
    constructor() {
        super();
        this.state = {
            filterText: '',
        };
    }

    createMarkup(txt) {
        return { __html: txt };
    }

    handleUserInput(searchTerm) {
        if (searchTerm !== this.state.filterText) {
            this.state.filterText = searchTerm;
            this.props.dispatch(fetchAllBlogsByCategory(this.state.filterText, this.props.locale));
        } else {
            this.state.filterText = '';
            this.props.dispatch(fetchAllBlogsByCategory('all', this.props.locale));
        }

        // history.pushState("", "", "/blog/" + searchTerm)
        window.location.href = '/blog/' + searchTerm;
    }

    formatTime(number) {
        if (number < 10) {
            return '0' + number;
        }

        return number;
    }

    componentDidMount() {
        const { match, blog, fetchedBlogData } = this.props;

        if (!fetchedBlogData) {
            this.props.dispatch(fetchBlog(match.params.id));
            this.props.dispatch(fetchAllBlogsByCategory(this.state.filterText, this.props.locale));
        }
    }

    componentDidUpdate() {
        const { match, blog, fetchedBlogData } = this.props;

        if (!fetchedBlogData) {
            this.props.dispatch(fetchBlog(match.params.id));
            this.props.dispatch(fetchAllBlogsByCategory(this.state.filterText, this.props.locale));
        }
    }

    render() {
        const { match, blog, fetchedBlogData } = this.props;
        let blogd = null;

        if (fetchedBlogData) {
            if (blog.blog.authorImageS3Id === null)
                var img = 'https://seilnorge-images.imgix.net/images/' + blog.blog.authorImageS3Id;
            else
                var img =
                    'https://seilnorge-images.imgix.net/images/' +
                    blog.blog.authorImageS3Id.replace('{size}', '_lg');
            var date =
                this.formatTime(new Date(blog.blog.date).getDate()) +
                '/' +
                this.formatTime(new Date(blog.blog.date).getUTCMonth() + 1) +
                '/' +
                new Date(blog.blog.date).getFullYear();

            blogd = (
                <div>
                    <Helmet>
                        <title>{blog.blog.title}</title>
                        <meta
                            property="og:image"
                            content={`https://seilnorge-images.imgix.net/images/${blog.blog.imageS3Id.replace(
                                '{size}',
                                '_xl'
                            )}`}
                        />
                        <meta property="og:description" content={blog.blog.entryText} />
                    </Helmet>
                    <Row
                        style={{
                            backgroundImage: [
                                'url(https://seilnorge-images.imgix.net/images/' +
                                    blog.blog.imageS3Id.replace('{size}', '_lg') +
                                    ')',
                            ],
                        }}
                        className="no-gutter card-background header-card card"
                    ></Row>
                    <FilterBlogs onUserInput={this.handleUserInput.bind(this)} />
                    <Row className="content blog-details">
                        <Row>
                            <h5>{blog.blog.title}</h5>
                            <p>{blog.blog.entryText}</p>
                        </Row>
                        <div className="author-description">
                            <Row style={{ display: 'flex' }}>
                                <div className="img-author-row">
                                    <img className="img-author" src={img}></img>
                                </div>
                                <div className="blog-details-author-date">
                                    <h6 className="date-blog">{date}</h6>
                                    <h6 className="author">{blog.blog.author}</h6>
                                </div>
                                <div className="author-description-text">
                                    <p>{blog.blog.authorDescription}</p>
                                </div>
                            </Row>
                        </div>
                        <Row>
                            <span
                                dangerouslySetInnerHTML={this.createMarkup(
                                    blog.blog.mainText.replace(
                                        /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                        'https://seilnorge-images.imgix.net/images'
                                    )
                                )}
                            ></span>
                        </Row>
                    </Row>
                </div>
            );
        }
        return <Grid fluid={true}>{blogd}</Grid>;
    }
}

function mapStateToProps(state) {
    return {
        fetchedBlogData: state.blog.fetched,
        blog: state.blog,
        allblogs: state.blog.allblogs,
        locale: state.locale,
    };
}

export default connect(mapStateToProps, null)(BlogDetails);
