import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class ExperienceCard extends Component {
    render() {
        let backgroundImage = {
            backgroundImage: ['url(' + this.props.image.replace('{size}', '_md') + '?width=1000)'],
        };

        const cardCount = this.props.trip_card_count;
        const cardIndex = this.props.trip_card_index;
        const threshold = cardCount - (cardCount % 3);

        let mdCount = 4;
        if (cardCount % 3 == 1 && cardIndex + 1 > threshold) {
            mdCount = 12;
        } else if (cardCount % 3 == 2 && cardIndex + 1 > threshold) {
            mdCount = 6;
        }

        return (
            <Link to={this.props.button_link} className="card-link">
                <Col xs={12} md={mdCount}>
                    <Row
                        style={backgroundImage}
                        className="no-gutter card-background small-card card"
                    >
                        <Col xs={12}>
                            <div className="content-container">
                                <p className="teaser"></p>
                                <h3>{this.props.listing_header}</h3>
                                <div className="btn btn-default">{this.props.button_text}</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Link>
        );
    }
}

export default ExperienceCard;
