import React, { useMemo, useCallback } from 'react';
import { Row, Col, Checkbox, Button, Clearfix, PanelGroup, Panel } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import Terms from './Terms';

const renderField = ({ input, label, placeholder, type, meta: { touched, error, warning } }) => (
    <div className="form-group">
        <label>{label}</label>
        <div>
            <input
                className="form-control"
                {...input}
                placeholder={placeholder ? placeholder : label}
                type={type}
            />
            {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span className="warning">{warning}</span>))}
        </div>
    </div>
);

const i18n = {
    nb_NO: {
        required: 'Må fylles ut',
        maxLengthPrefix: 'Må være',
        maxLengthPostfix: 'tegn eller færre',
        requiredNumber: 'Må være tall',
        invalidEmail: 'Ugyldig e-post',
        heading: 'Vi gleder oss til å ha deg med på tur!',
        waitingListHeading: 'Auda, denne turen er fullbooket! Sett deg gjerne opp på venteliste!',
        waitingListExplanation1:
            'Vi har erfaring med at deltagere på venteliste kan få plass, da noen kan trekke seg eller blir hindret fra å delta. Spesielt gjelder dette på turer med flere båter og deltagere. Har vi mange på venteliste, kan det hende vi setter opp en ekstra tur!',
        waitingListExplanation2:
            'Fyller du ut informasjonen her så blir du lagt til en uforpliktende venteliste, og vi tar kontakt med deg så snart vi har en ledig plass.',
        subHeading: 'Litt info om deg',
        fieldFirstNameLabel: 'Fornavn',
        fieldLastNameLabel: 'Etternavn',
        fieldEmailLabel: 'E-post',
        fieldMobileLabel: 'Telefon',
        fieldAddressLabel: 'Adresse',
        fieldPostcodeLabel: 'Postnummer',
        fieldCityLabel: 'Poststed',
        fieldCountryLabel: 'Land',
        acceptedConditions: 'Jeg har lest og aksepterer reisebetingelsene',
        proudMembersOf: 'Stolte medlemmer av',
        travelConditions: 'Reisebetingelser',
        nextButtonText: 'Neste',
        waitlistNextButtonText: 'Sett meg på venteliste',
        processing: 'Behandler...',
    },
    en_US: {
        required: 'Required',
        maxLengthPrefix: 'Must be',
        maxLengthPostfix: 'characters or less',
        requiredNumber: 'Must be a number',
        invalidEmail: 'Invalid email',
        heading: 'We are looking forward to having you on board',
        waitingListHeading:
            'Sorry, this trip is fully booked, please register to the waiting list!',
        waitingListExplanation1: '',
        waitingListExplanation2: '',
        subHeading: 'Personal Details',
        fieldFirstNameLabel: 'First name',
        fieldLastNameLabel: 'Last name',
        fieldEmailLabel: 'Email',
        fieldMobileLabel: 'Phone number',
        fieldAddressLabel: 'Street address',
        fieldPostcodeLabel: 'Postal code/zip',
        fieldCityLabel: 'City',
        fieldCountryLabel: 'Country',
        acceptedConditions: 'I have read and agree to the Terms and Conditions',
        proudMembersOf: 'Proud member of',
        travelConditions: 'Terms and conditions',
        nextButtonText: 'Continue',
        waitlistNextButtonText: 'Register to waiting list',
        processing: 'Processing...',
    },
};

const maxLength = max => value =>
    value && value.length > max
        ? `${lang.maxLengthPrefix} ${max} ${lang.maxLengthPostfix}`
        : undefined;

const emailRegex = /^[A-Z0-9a-z._%+-A-Z0-9a-z]+@[A-Z0-9a-z]{3,99}\.[A-Z0-9a-z]{2,4}/;

const BookingFormParticipant = ({
    addParticipant,
    toggleChevron,
    chevronState,
    toggleButtonFromCheckbox,
    termsState,
    fields: { firstName, lastName, email, mobile, address, postcode, city, country },
    handleSubmit,
    locale,
    trip,
    busyScreenActive,
}) => {
    const lang = i18n[locale];

    const required = useCallback(value => (value ? undefined : lang.required), [lang]);
    const maxLength25 = useMemo(() => maxLength(25), []);
    const maxLength6 = useMemo(() => maxLength(6), []);
    const properEmail = useCallback(
        value => (value && !emailRegex.test(value) ? lang.invalidEmail : undefined),
        [lang]
    );
    const number = useCallback(
        value => (value && isNaN(Number(value)) ? lang.requiredNumber : undefined),
        [lang]
    );

    let header = (
        <Row>
            <Col xs={8}>
                <p className="bold">{lang.travelConditions}</p>
            </Col>
            <Col xs={4}>
                <span
                    className={
                        chevronState
                            ? 'glyphicon glyphicon-chevron-up pull-right'
                            : 'glyphicon glyphicon-chevron-down pull-right'
                    }
                ></span>
            </Col>
        </Row>
    );

    var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

    const rgfLogo = s3BaseUrl + 'eksterne/rgf_logo_jpg.jpg';

    return (
        <Row className="content">
            <Col xs={12} sm={6} smOffset={3}>
                <h3 className="black">
                    {trip.book_participants_on_waiting_list_event_not_main_event == 0 &&
                        lang.heading}
                    {trip.book_participants_on_waiting_list_event_not_main_event == 1 &&
                        lang.waitingListHeading}
                </h3>
                {trip.book_participants_on_waiting_list_event_not_main_event == 1 && (
                    <div>
                        <p>{lang.waitingListExplanation1}</p>
                        <p>{lang.waitingListExplanation2}</p>
                    </div>
                )}

                <div>
                    <form onSubmit={handleSubmit(addParticipant)}>
                        <h4 className="black">{lang.subHeading}</h4>

                        <Field
                            name="firstName"
                            label={lang.fieldFirstNameLabel}
                            type="text"
                            component={renderField}
                            validate={[required, maxLength25]}
                        />
                        <Field
                            name="lastName"
                            label={lang.fieldLastNameLabel}
                            type="text"
                            component={renderField}
                            validate={[required, maxLength25]}
                        />
                        <Field
                            name="email"
                            label={lang.fieldEmailLabel}
                            type="email"
                            component={renderField}
                            validate={[required, properEmail]}
                        />
                        <Field
                            name="mobile"
                            label={lang.fieldMobileLabel}
                            type="number"
                            component={renderField}
                            validate={[required, number]}
                        />
                        <Field
                            name="address"
                            label={lang.fieldAddressLabel}
                            type="text"
                            component={renderField}
                            validate={[required]}
                        />
                        <Field
                            name="postcode"
                            label={lang.fieldPostcodeLabel}
                            type="text"
                            component={renderField}
                            validate={[required]}
                        />
                        <Field
                            name="city"
                            label={lang.fieldCityLabel}
                            type="text"
                            component={renderField}
                            validate={[required]}
                        />
                        {locale == 'en_US' && (
                            <Field
                                name="country"
                                label={lang.fieldCountryLabel}
                                type="text"
                                component={renderField}
                                validate={[required]}
                            />
                        )}

                        <div className="form-group">
                            <Checkbox onChange={toggleButtonFromCheckbox}>
                                <p>{lang.acceptedConditions}</p>
                            </Checkbox>
                        </div>

                        <Button type="submit" disabled={termsState} className="orange pull-right">
                            {trip.book_participants_on_waiting_list_event_not_main_event != 1 &&
                                lang.nextButtonText}
                            {trip.book_participants_on_waiting_list_event_not_main_event == 1 &&
                                lang.waitlistNextButtonText}
                        </Button>
                    </form>
                </div>
                <Clearfix />
                <div className="centred" style={{ padding: '30px 0' }}>
                    <p className="bold centred">{lang.proudMembersOf}</p>
                    <a href="https://reisegarantifondet.no">
                        <img
                            src={rgfLogo}
                            style={{ width: '100px' }}
                            alt="Reisegarantifondet logo"
                        />
                    </a>
                </div>
                <PanelGroup accordion onSelect={toggleChevron} id="terms">
                    <Panel eventKey="1" className="accordion-small">
                        <Panel.Heading>
                            <Panel.Title toggle>{header}</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                            <Terms locale={locale} />
                        </Panel.Body>
                    </Panel>
                </PanelGroup>
            </Col>

            {busyScreenActive && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        background: 'rgba(0,0,0,0.7)',
                        zIndex: 9999999,
                    }}
                >
                    <h3 style={{ position: 'absolute', left: '45%', top: '50%' }}>
                        {lang.processing}
                    </h3>
                </div>
            )}
        </Row>
    );
};

export default reduxForm({
    form: 'participant',
    fields: ['firstName', 'lastName', 'email', 'mobile', 'address', 'postcode', 'city', 'country'],
})(BookingFormParticipant);
