import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class InfoCardLeft extends Component {
    render() {
        let backgroundImage = {
            backgroundImage: ['url(' + this.props.image.replace('{size}', '_xl') + ')'],
        };

        var cardTextBackground = '';
        var textColour = 'black';

        if (this.props.card_colour === 'black') {
            cardTextBackground = 'card-background-black';
            textColour = 'white';
        }

        return (
            <Row className="no-gutter info-card card">
                <Col xs={12} sm={6} style={backgroundImage} className="card-background"></Col>
                <Col xs={12} sm={6} className={cardTextBackground}>
                    <div className="content-container">
                        <h4 className={textColour + ' centred'}>{this.props.info_header}</h4>
                        <p className={textColour + ' ingress centred'}>{this.props.info_text}</p>
                        <p className={textColour + ' centred uppercase text-link'}>
                            <a href={this.props.info_link}>{this.props.link_text}</a>
                        </p>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default InfoCardLeft;
