/**
 * Created by hajer on 17/08/17.
 */
export default function reducer(
    state = {
        fetching: false,
        fetched: false,
        fetchingFront: false,
        fetchedFront: false,
        fetchingBlogData: false,
        fetchedBlogData: false,
        fetchedBlogCategoriesData: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case 'FETCH_BLOG_START':
            return { ...state, fetching: true };
        case 'FETCH_BLOG_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_BLOG_FULFILLED':
            return { ...state, fetching: false, fetched: true };
        case 'FETCH_BLOG_BY_ID_START':
            return { ...state, fetching: true };
        case 'FETCH_BLOG_BY_ID_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_BLOG_BY_ID_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                blog: action.payload.data,
            };
        case 'FETCH_ALL_BLOGS_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_ALL_BLOGS_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetchedBlogData: true,
                allblogs: action.payload.data,
            };
        case 'FETCH_ALL_BLOGS_BY_CATEGORY_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_ALL_BLOGS_BY_CATEGORY_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetchedBlogData: true,
                allblogs: action.payload.data,
            };
        case 'FETCH_FRONT_BLOGS_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_FRONT_BLOGS_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetchedBlogData: true,
                frontBlogs: action.payload.data,
            };
        case 'FETCH_SLIDER_BLOGS_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_SLIDER_BLOGS_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetchedBlogData: true,
                sliderBlogs: action.payload.data,
            };
        case 'FETCH_ALL_BLOGS_CATEGORIES_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_ALL_BLOGS_CATEGORIES_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetchedBlogCategoriesData: true,
                categories: action.payload.data,
            };

        case 'CHANGE_FILTER':
            return { ...state, filteredBlogs: action.payload };
        default:
            return state;
    }
}
