import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class InfoCard extends Component {
    render() {
        let backgroundImage = {
            backgroundImage: ['url(' + this.props.image.replace('{size}', '_xl') + ')'],
        };

        const teaserClass = 'teaser ' + this.props.text_class;

        return (
            <a
                className="card-link"
                href={this.props.button_link}
                target={this.props.button_link_target}
            >
                <Row
                    style={backgroundImage}
                    className="no-gutter card-background-medium medium-card card"
                >
                    <Col xs={12}>
                        <div className="content-container">
                            <p className={teaserClass}>{this.props.listing_intro}</p>
                            <h4 className={this.props.text_class}>{this.props.listing_header}</h4>
                            <div className="btn btn-default">{this.props.button_text}</div>
                        </div>
                    </Col>
                </Row>
            </a>
        );
    }
}

export default InfoCard;
