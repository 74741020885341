import React, {useState, useEffect, useCallback} from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Radio } from 'react-bootstrap';
import PaymentSummary from "./PaymentSummaryStripeCheckout";
import moment from "moment";
import TripBookingConfig from "../../../json/TripBookingConfig.json";
import TripOptions from "./TripOptions";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


const i18n = {
  nb_NO: {
      payButton: 'Gå til betaling',
      payHeading: "Betalingsinformasjon",
      tripSelected: "Valgt tur: ",
      date: "Dato: ",
      paymentDescription: "Ønsker du å betale det fulle beløpet nå eller bare depositumet?", 
      deposit: "Bare depositumet", 
      fullPrice: "Betal hele beløpet nå",
      depositAmount: "Depositum",
      fullPriceAmount: "Fult beløp",
  },
  en_US: {
      payButton: 'Go to payment',
      payHeading: "Payment information",
      tripSelected: "Trip selected: ",
      date: "Date: ",
      paymentDescription: "Do you want to pay the full paymant now or just the deposit?",
      deposit: "Only pay deposit", 
      fullPrice: "Pay the full amount now",
      depositAmount: "Deposit amount",
      fullPriceAmount: "Full price",
  },
};

const API_URL = 'https://loopback.seilnorge.no';

function createOrder(booking, type = 'invoice') {
  return axios.post(API_URL + '/api/orders', { type, booking, status: 'pending' });
}


export default function StripeCheckout() {
  const [fullPayment, setFullPayment] = useState("fullPrice")

  let locale = useSelector((state) => state.locale);
  const lang = i18n[locale];
  const trip = useSelector((state) => state.trip.trip);
  const booking = useSelector((state) => state.booking.booking);
  console.log("trip in checkout", trip)

  let now = moment(Date.now())
  let departure = null
  let threshold = null
  let isBeforeThreshold = null
  if(trip){
    departure = moment(trip.departure);
    threshold = departure
        .hours(3)
        .subtract(TripBookingConfig.depositDaysThreshold, 'days');
  
    isBeforeThreshold = now.isBefore(threshold);    
  }
  
  let amountToPay = trip.price
  let tripOptionInfo = "None"

  if(booking){
    amountToPay = trip.price + booking.options.reduce((sum, o) => sum + o.price, 0)  
    tripOptionInfo = JSON.stringify(booking.options)
  } 

  //This is set up like this to get the trigger function inside handleSubmit
  useEffect(() => {
    sendRequests()
  }, [sendRequests])

  const sendRequests = useCallback(async (data) => {
    if(data){
      //This is outdated, will be handled in the stripe backend. 
      //Handle loopback backend call
      let res = await createOrder(booking)
      //console.log("Loopback request complete", res)
      let newBookingData = {
        uuid: data.uuid,
        booking: booking
      }

      //axios.post("https://seilnorge-stripe-backend.test.iterate.no/new-booking", newBookingData) 
      axios.post("https://seilnorge-stripe-backend.app.iterate.no/new-booking", newBookingData) 
      //axios.post("http://localhost:8080/new-booking", newBookingData) 


      //Handle the stripe request and redirect
      //let response = await axios.post("https://seilnorge-stripe-backend.test.iterate.no/create-checkout-session", data) 
      let response = await axios.post("https://seilnorge-stripe-backend.app.iterate.no/create-checkout-session", data) 
      //let response = await axios.post("http://localhost:8080/create-checkout-session", data) 
      //console.log("Stripe request complete", response)   
      let stripeURL = response.data
      window.location.replace(stripeURL)
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    let uuid = uuidv4();
    console.log("uuid: ", uuid)

    const data = {
      uuid: uuid, 
      heading: trip.heading,
      departure: trip.departure,
      tripId: trip.trip_id,
      deposit: trip.deposit,
      price: trip.price,
      tripOptions: tripOptionInfo,
      priceOption: fullPayment,
    }
    sendRequests(data)
  }  

  return (
    <Row className="content">
      <Col xs={12} sm={6} smOffset={3}>
        <h3 className="black">
          {!trip && <p>Error: Trip not selected</p>}
        </h3>

        {trip &&
          <div>
            {/*TODO: change url enverionmental/production*/}
            <TripOptions locale={locale} />

            <form onSubmit={handleSubmit}>
              { isBeforeThreshold && 
                <div>
                  <h5>{lang.paymentDescription}</h5>
                    <Radio
                      type="radio" 
                      id="onlyDeposit" 
                      value="onlyDeposit" 
                      name="priceOption" 
                      onChange={() => setFullPayment("onlyDeposit")} 
                    >
                      {lang.deposit}: NOK {trip.deposit}
                    </Radio>
                    <Radio 
                      type="radio" 
                      id="fullPrice" 
                      value="fullPrice" 
                      name="priceOption" 
                      onChange={() => setFullPayment("fullPrice")}
                      defaultChecked
                    >
                      {lang.fullPrice}: NOK {trip.price}
                    </Radio>

                </div>              
              }
              <PaymentSummary
                  locale={locale}
                  isBeforeThreshold={isBeforeThreshold}
                  fullPayment={fullPayment}
              />
              <Button type="submit" value="submit" className="orange pull-right">
                {lang.payButton}
              </Button>
            </form>
          </div>
        }
    </Col>      
  </Row>
  )
}