/**
 * Created by hajer on 17/08/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { fetchSliderBlogs } from '../../reducers/BlogActions';
import { fetchAllBlogsByCategory } from '../../reducers/BlogActions';
import { fetchAllCategoriesBlogs } from '../../reducers/BlogActions';
import { connect } from 'react-redux';
import FilteredBlogs from '../blog/FilteredBlogs';
import FilterBlogs from '../blog/FilterBlogs';
import URLBuilder from '../../helpers/URLBuilder';
import { SlideShow } from './SlideShow';

class Blog extends Component {
    constructor() {
        super();
        this.state = {
            filterText: '',
        };
    }

    handleUserInput(searchTerm) {
        if (searchTerm !== this.state.filterText) {
            this.state.filterText = searchTerm;
            var allblogsfiltered = this.props.allblogs.filter(
                blog => blog.tags.indexOf(searchTerm.toLowerCase()) !== -1
            );
            this.props.dispatch(fetchAllBlogsByCategory(this.state.filterText, this.props.locale));
        } else {
            this.state.filterText = '';
            var allblogfiltered = this.props.allblogs;
            this.props.dispatch(fetchAllBlogsByCategory('all', this.props.locale));
        }

        history.pushState('', '', '/blog/' + searchTerm);
    }

    componentDidMount() {
        const { fetchedBlogCategoriesData, fetchedBlogData, locale } = this.props;
        this.state.filterText = this.props.match.params.filter;

        if (!fetchedBlogData) {
            this.props.dispatch(fetchAllBlogsByCategory(this.state.filterText, locale));
            this.props.dispatch(fetchSliderBlogs(locale));
        }

        if (!fetchedBlogCategoriesData && fetchedBlogCategoriesData) {
            this.props.dispatch(fetchAllCategoriesBlogs(this.state.filterText, locale));
        }
    }

    componentDidUpdate() {
        const { fetchedBlogCategoriesData, fetchedBlogData, locale } = this.props;
        if (!fetchedBlogData) {
            this.props.dispatch(fetchAllBlogsByCategory(this.state.filterText, locale));
            this.props.dispatch(fetchSliderBlogs(locale));
        }

        if (!fetchedBlogCategoriesData && fetchedBlogCategoriesData) {
            this.props.dispatch(fetchAllCategoriesBlogs(this.state.filterText, locale));
        }
    }

    render() {
        const { token, fetchedBlogCategoriesData, fetchedBlogData, sliderBlogs, locale } =
            this.props;

        let _slides = [];

        if (sliderBlogs !== undefined) {
            var SliderBlogs = [
                {
                    imageUrl: 'https://seilnorge-images.imgix.net/images/forside_2_xl.jpg',
                    caption: {
                        slug: '',
                        title: '',
                        description: '',
                        link: '',
                        first: true,
                    },
                },
            ];
            for (var blog in sliderBlogs) {
                if (sliderBlogs[blog].featuredSlideshow) {
                    if (this.state.filterText === 'all') {
                        SliderBlogs.push({
                            imageUrl:
                                'https://seilnorge-images.imgix.net/images/' +
                                sliderBlogs[blog].imageS3Id,
                            caption: {
                                slug: sliderBlogs[blog].slug,
                                title: sliderBlogs[blog].title,
                                description: sliderBlogs[blog].entryText,
                                link: URLBuilder.blog(sliderBlogs[blog].id, sliderBlogs[blog].tags),
                                first: false,
                            },
                        });
                    } else {
                        SliderBlogs = [
                            {
                                imageUrl:
                                    'https://s3-eu-west-1.amazonaws.com/seilnorge-no-images/' +
                                    this.state.filterText +
                                    '.jpg',
                                caption: {
                                    slug: '',
                                    title: '',
                                    description: '',
                                    link: '',
                                    first: true,
                                },
                            },
                        ];
                    }
                }
            }
            _slides = SliderBlogs;
        }

        return (
            <>
                <Helmet>
                    <meta
                        property="og:image"
                        content="https://seilnorge-images.imgix.net/images/forside_2_xl.jpg"
                    />
                    <title>{this.props.locale === 'nb_NO' ? 'Blogg' : 'Blog'}</title>
                </Helmet>
                <Grid fluid={true}>
                    <SlideShow
                        key="slideShow"
                        slides={_slides}
                        hidden={this.state.filterText !== 'all'}
                    />
                    <FilterBlogs
                        filterText={this.state.filterText}
                        onUserInput={this.handleUserInput.bind(this)}
                    />
                    <FilteredBlogs filterText={this.state.filterText} />
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        fetchedBlogData: state.blog.fetchedBlogData,
        fetchedBlogCategoriesData: state.blog.fetchedBlogCategoriesData,
        allblogs: state.blog.allblogs,
        sliderBlogs: state.blog.sliderBlogs,
        allcategories: state.blog.categories,
        filter: state.blog.filters,
        locale: state.locale,
    };
}

Blog.propTypes = {
    allblogs: PropTypes.arrayOf(PropTypes.object),
};
export default connect(mapStateToProps, null)(Blog);
