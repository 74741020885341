export default function reducer(
    state = {
        instaFetched: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case 'FETCH_INSTAGRAM_LATEST_FULFILLED':
            return {
                ...state,
                instaFetched: true,
                insta_latest: action.payload.data.data,
            };
        default:
            return state;
    }
}
