import React from 'react';

const Terms = ({ locale }) => {
    switch (locale) {
        case 'en_US':
            return (
                <div>
                    <p className="bold">General Travel Terms and Conditions – SeilNorge AS</p>
                    <p>
                        Thank you for deciding to book a trip with SeilNorge (hereinafter referred
                        to as: "SN"). Before making a definite booking, we recommend you
                        (hereinafter referred to as "contracting party") to read carefully the
                        following travel conditions. These general travel conditions and the term
                        contracting party apply both to private persons (direct booking) and to
                        (travel) organisations (see par. 1.2).
                    </p>
                    <p>
                        N.B.: when referring to SN, this automatically includes the affiliates,
                        owners, officers, agents, employees, associate and/or sister companies of
                        SN.
                    </p>
                    <h5>1. Reservation of trip / travel notification</h5>
                    <p className="bold">1.1</p>
                    <p>
                        The contract comes about by means of a written acceptance by the contracting
                        party of the offer of SN, and/or payment of the required deposit or invoiced
                        total amount, upon which a binding travel contract with SN is effected, to
                        which all conditions set out in paragraph 1.3 up to and including paragraph
                        11.3 apply. If the contracting party in addition to him/herself also lists
                        one or more other persons on one notification form, this contracting party
                        is automatically personally responsible for all obligations ensuing from the
                        travel contract (in line with the general travel conditions of SN) which
                        concern himself and the other persons booked.
                    </p>
                    <p className="bold">1.2</p>
                    <p>
                        If the contract has come about by means of a written acceptance and/or
                        payment as per paragraph 1.1 by a (travel) organisation (as in the case of
                        but not limited to tour operators, agencies, travel agents, foundations,
                        societies, schools, universities etc.), this (travel) organisation enters
                        into a binding travel contract with SN. The (travel) organisation in
                        question is then exclusively considered the contracting party to which all
                        conditions set out from paragraph 1.3 up to and including paragraph 11.3
                        apply, irrespective of the possibility that the (travel) organisation in
                        turn enters into a binding travel contract with clients it has recruited
                        (such as but not limited to, private persons, members of the (travel)
                        organisation, travel agents and third parties in general).
                    </p>
                    <p className="bold">1.3</p>
                    <p>
                        The contracting party shall – before the effecting of the travel contract
                        and its implementation – provide SN with the necessary (personal) data
                        concerning him/herself or third parties – in the case of a (travel)
                        organisation: the client(s) of the (travel) organiser. SN shall not be held
                        liable for the consequences of such incorrect or incomplete provision of
                        (personal) data.
                    </p>
                    <p className="bold">1.4</p>
                    <p>
                        All trips booked through SN will be confirmed by SN by means of a
                        confirmation(invoice) sent to the contracting party.
                    </p>
                    <h5>2. The travel offer</h5>
                    <p className="bold">2.1</p>
                    <p>
                        The scope of the travel offer (together with any additional the travel
                        elements required) booked by the contracting party is contractually recorded
                        in the travel confirmation (invoice), in combination with the description
                        from the most up‐to‐date brochure / programme description, sailing
                        schedules, day‐by‐day itineraries of SN and / or (other) relevant
                        publications of SN.
                    </p>
                    <p className="bold">2.2</p>
                    <p>
                        SN bears no responsibility for photos, brochures and other information
                        material, insofar as these have been published or distributed under the
                        responsibility of third parties, nor for any undertakings, expectations or
                        promises of third parties towards the contracting party.
                    </p>
                    <h5>3. Payment</h5>
                    <p className="bold">3.1</p>
                    <p>
                        On the effecting of the travel contract, the contracting party must pay an
                        advance as stated in the travel information, of the total price to SN,
                        unless otherwise stated in the travel confirmation. The remainder of the
                        price must have been received 60 days before departure date by SN. If the
                        travel contract has been agreed within 60 days of departure, the full price
                        must be paid immediately. Other (deviating) payment policies may be
                        applicable; these are valid only if confirmed by SN in writing (in e.g. the
                        original offer made, travel confirmation, (block charter)
                        agreements/contracts or in additional (separate) written confirmations) and
                        these payment policies shall prevail over those of the here above mentioned
                        standard payment policy.
                    </p>
                    <p className="bold">3.2</p>
                    <p>
                        After full payment has been received, confirmation and relevant
                        documentation will be sent to the contracting party.
                    </p>
                    <p className="bold">3.3</p>
                    <p>
                        If the contracting party fails to fulfil the payment obligations, SN will
                        send him/her a written reminder and he/she has the possibility to make
                        immediate payment. If the payment is still not made, the contracting party
                        is liable to pay interest on the sum due of 1% for each month or part of a
                        month of default. Furthermore he/she is liable to pay compensation of
                        extra‐legal collection costs equal to 15% of the sum claimed, with a minimum
                        of NOK500. If the contracting party fails to comply with his/her payment
                        obligations, SN reserves the right to cancel the contract on the day of
                        default, with no further obligations. SN is (however) entitled to charge the
                        cancellation costs incurred (in line with paragraph 6 or as agreed otherwise
                        in the travel confirmation).
                    </p>
                    <h5>4. Changes in travel elements / price changes</h5>
                    <p className="bold">4.1</p>
                    <p>
                        Changes in the travel offer, agreed in the travel confirmation / invoice
                        (which occur before the start of the journey) are permitted by SN only if
                        they do not substantially alter the nature of the trip. This includes, among
                        other things, minor changes in the travel programme or excursions agreed.
                        Such changes may not be used by the contracting party as a reason for
                        cancelling the travel contract or to put forward a claim of any kind to SN.
                    </p>
                    <p className="bold">4.2</p>
                    <p>
                        The agreed price is based on the prices, exchange rates, duties and taxes as
                        known to SN at the time the publication went to press and at the time of the
                        travel confirmation. SN reserves the right to raise the agreed price (on the
                        grounds of, among other things, unforeseeable increases of e.g. exchange
                        rates, sudden increased prices of duties, taxes, harbour dues and fuel
                        prices). In the case that a price alteration is considered necessary by SN,
                        SN is obliged to inform the contracting party in writing of this at the very
                        latest 60 days before the day of departure. Price increases made within 60
                        days of the start of the journey are not permitted. In the case of a price
                        increase of more than 5 % of the total price of the trip, the contracting
                        party is entitled to cancel the trip without incurring any costs.
                    </p>
                    <h5>5. Cancellation of the trip by SN</h5>
                    <p className="bold">5.1</p>
                    <p>
                        Notwithstanding to what is mentioned elsewhere, SN reserves the right to
                        cancel any trip prior to or during the departure for compelling reasons, in
                        which case the payment in advance (indicated in the invoice / travel
                        confirmation) but no additional sum, will be paid back to the contracting
                        party and SN does not accept any claims, that may be related to such a
                        cancellation, such as but not limited to loss of joy (of holiday),
                        consequential and/or indirect (commercial) damages, loss of earnings and or
                        profit, loss of market, loss of business, loss of customers, loss of
                        opportunities, commercial losses or damages, loss of or damage to commercial
                        image and or reputation.
                    </p>
                    <p className="bold">5.2</p>
                    <p>
                        Not withstanding to what is mentioned elsewhere, SN has the right to cancel
                        the scheduled trip up to 30 days before departure, if the minimum number of
                        participants has not been achieved. Any payment already achieved from the
                        contracting party will be paid back by SN if the contracting party is not in
                        agreement with an alternative trip offered by SN.
                    </p>
                    <p className="bold">5.3</p>
                    <p>
                        Not withstanding to what is mentioned elsewhere, SN has the right in the
                        case of force majeure (such as but not limited to war, uprising, natural
                        disasters, abnormal / exceptional weather and ice conditions, legal
                        stipulations of the local/regional authorities and other events and
                        situations that are beyond the control of SN) to cancel the trip. SN may not
                        be held liable for force majeure. If situations of force majeure occur
                        before departure date and SN should cancel the trip, payments made in
                        advance will be paid back to the contracting party. If situations of force
                        majeure occur during the trip, SN will try to offer an alternative
                        programme. If this is not possible, either SN or the contracting party is
                        entitled to cancel the trip. In such a case, SN may not be held financially
                        liable. SN is obliged to assist the contracting party ‐ in the case of a
                        (travel) organisation: the client(s) of the (tour) organiser ‐ in obtaining
                        a return trip/flight. The contracting party him/herself is responsible for
                        the costs of this.
                    </p>
                    <h5>6. Cancellation of the trip by the contracting party</h5>
                    <p className="bold">6.1</p>
                    <p>
                        The contracting party may cancel the travel contract (exclusively in
                        writing) at any time before the beginning of the trip. In the case of
                        cancellation by the contracting party, SN is entitled to charge the
                        following cancellation fees to the contracting party:
                    </p>
                    <ul>
                        <li>up to and including 60 days prior to departure: full deposit;</li>
                        <li>
                            from 59 days up to and including 30 days prior to departure: 50 % of the
                            total price;
                        </li>
                        <li>
                            from 29 days up to and including the day of departure: 100 % of the
                            total price.
                        </li>
                    </ul>
                    <p className="bold">6.2</p>
                    <p>
                        Other (deviating) cancellation policies / fees (for example in the case of
                        group charters, block charters or whole boat charters) may be applicable;
                        these are valid only if confirmed by SN in writing (in e.g. the original
                        offer made, travel confirmation, (block) charter agreements or in
                        communication sent later) and these cancellation policies shall prevail over
                        those of the cancellation policy / fees as mentioned in paragraph 6.1.
                    </p>
                    <p className="bold">6.3</p>
                    <p>
                        If the contracting party after booking wishes to introduce changes into the
                        booked trip, this is considered a cancellation and the cancellation costs
                        stated in paragraph 6.1 and 6.2 apply. This will not apply to minor changes
                        at SN’s discretion.
                    </p>
                    <p className="bold">6.4</p>
                    <p>
                        SN strongly recommends the contracting party, in the case of a (travel)
                        organisation: the client(s) of the (travel) organiser, to take out a
                        cancellation insurance to cover the costs of any cancellation of the trip
                        (see also par. 10.4).
                    </p>
                    <h5>7. Liability of SN</h5>
                    <p>
                        SN is obliged to aim for a correct execution of the trip according to the
                        travel contract and in line with the expectations the traveller may
                        reasonably have on the grounds of the contract.
                    </p>
                    <h5>8. Exclusion and limitation of the liability of SN</h5>
                    <p>
                        Each of the provisions of this paragraph 8 shall take effect to the fullest
                        extent permitted by law:
                    </p>
                    <p className="bold">8.1</p>
                    <p>
                        SN acts as intermediary for the sale of travel elements between on the one
                        hand providers of accommodation/services (such as but not limited to a stay
                        in a hotel, a voyage with a ship, services of dive bases and providers of
                        transport, including helicopters) and on the other hand the contracting
                        party and therefore the liability of SN is excluded. In these cases the
                        conditions of the relevant providers of accommodation/services and/or the
                        stipulations of (inter)national law applies and SN can not be held liable
                        for events such as but not limited to complaints, claims, loss and damage of
                        possessions/baggage, personal injury, death etc.
                    </p>
                    <p className="bold">8.2</p>
                    <p>
                        All travel elements booked with SN, such as but not limited to a stay on
                        board of a ship and/or excursions and/or programmes off ship (such as but
                        not limited to walking, sightseeing, hiking, camping, mountaineering and/or
                        excursions or programmes on or in the water, incl. kayaking, (scuba) diving,
                        swimming, snorkelling, zodiac driving/cruising and flying activities
                        including helicopter flights) are for 100 % own risk of the contracting
                        party ‐ in the case of a (travel) organisation: the client(s) or the
                        (travel) organiser. SN is therefore not liable for any damage, such as but
                        not limited to (bodily) injury, illness, death etc. whatever the reason or
                        cause may be, including consequential and/or indirect (commercial) damages
                        as set forth in paragraph 5.1. For diving trips and or supplements and/or
                        for programmes and/or trips for which this is explicitly indicated (such as
                        but not limited to trips with helicopters), the contracting party must sign
                        a Liability Release Form (Waiver), a health certificate signed by a doctor
                        and – in the case of a dive trip ‐ an internationally accepted diving
                        certificate. If the contracting party does not have the required diving
                        certificates and/or diving experience as required for participation, SN is
                        entitled to decide to offer an alternative programme for the contracting
                        party in question or to exclude this person from (certain parts of) the
                        (diving) programme. These limitations apply also if the contracting party is
                        not in possession of the correct (diving) gear, as stated in publications of
                        SN. In the case of exclusion (or of a mandatory alternative programme) the
                        contracting party is not entitled to make any claims (for restitution). In
                        the case of all (other) travel elements, the contracting party should enjoy
                        generally sound health for the activities which they are to undertake. In
                        any case, if the contracting party ‐ undertaking the trip of SN ‐ does not
                        enjoy generally sound health, SN has the right to offer the contracting
                        party an alternative programme or to exclude him from (continuation of) the
                        trip / travel elements. In the case of such an alternative programme or
                        exclusion, SN will not accept any claims (for restitution).
                    </p>
                    <p className="bold">8.3</p>
                    <p>
                        If SN offers the contracting party an air travel component, including
                        helicopter flights, all liability (such as but not limited to death, injury,
                        delays, cancellations, refunds, loss and damage of baggage) of SN is
                        excluded, even if such an event is at the cost of other travel elements of
                        the trip booked or if such an event would change, postpone, cancel the
                        (nature/scope) of the trip and /or other travel elements) and for this
                        travel component the conditions of the relevant airline and/or helicopter
                        operator and – if applicable ‐ international conventions apply.
                    </p>
                    <p className="bold">8.4</p>
                    <p>
                        SN may not be held liable for loss, damage and robbery of travel documents,
                        baggage or other possessions.
                    </p>
                    <p className="bold">8.5</p>
                    <p>
                        Not withstanding to what is mentioned elsewhere, SN shall accept no
                        liability for damage for which there is a claim to compensation based on a
                        travel and/or cancellations insurance (whether effected or not).
                    </p>
                    <p className="bold">8.6</p>
                    <p>
                        SN may not be held liable for damage as a consequence of the travel contract
                        not being correctly implemented, if the deficiency in the execution of the
                        contract is attributable to the contracting party.
                    </p>
                    <p className="bold">8.7</p>
                    <p>
                        The trips offered by SN are mainly conducted in “marginal zones” and require
                        the qualification of expedition trips to places where infrastructure and
                        (medical) facilities are often lacking. On booking the trip, the contracting
                        party fully understands that those trips can not be comparable with any
                        other trip. If for any reason such as but not limited to weather conditions,
                        sea currents, nautical reasons, ice‐conditions etc., the decision is taken
                        by SN to change the programme and/or the programme cannot be carried out
                        according to the travel description and (certain) places described in the
                        travel programme cannot be visited and/or SN deviates from the programme, if
                        SN has the opinion that such deviation will benefit the quality of the
                        programme, or the trip has to be postponed or (partly) cancelled, SN is not
                        liable for any claims, such as but not limited to refunds, damages,
                        non‐fulfilled expectations etc. of the contracting party.
                    </p>
                    <p className="bold">8.8</p>
                    <p>
                        Notwithstanding to what is mentioned in 8.7, SN has the right to delay or
                        postpone the (remainder of the) trip with a maximum of 24 hours, for any
                        reason in terms of a proper and / or safe execution of the trip, such as but
                        not limited to ship operational matters including spare parts, documents,
                        provisions, crew and staff related matters including flight arrivals. SN
                        accepts no claims such as but not limited to refunds. In the case of longer
                        delays (except for helicopter flights/operations), the contracting party has
                        the right to cancel the trip and SN will refund the travel price booked by
                        SN, or in the case this longer delay occurs during the trip, the travel
                        price will be paid back on a pro rata basis. Other travel elements such as,
                        but not limited to, flights and hotels will not be refunded.
                    </p>
                    <p>
                        If for any reason the helicopter flights/operations can not be offered, such
                        as but not limited to weather, ice, nautical conditions, technical reasons
                        and break‐downs of the helicopters, no claims of any kind such as but not
                        limited to refunds ‐ will be accepted by SN, not even if such an event would
                        change, postpone, cancel the (nature/scope) of the trip and /or other travel
                        elements of the trip.
                    </p>
                    <p className="bold">8.9</p>
                    <p>
                        Should SN decide to carry out a medical evacuation during the trip and this
                        may result in alteration or cancellation of the scheduled trip, SN accepts
                        no claims of any kind such as but not limited to refunds.
                    </p>
                    <h5>9. Obligations of the contracting party</h5>
                    <p>
                        In the case of a (travel) organisation: instead of "contracting party" (in
                        paragraphs 9.1 up to and including 9.4) read also “the client(s) of the
                        (travel) organiser”.
                    </p>
                    <p className="bold">9.1</p>
                    <p>
                        The contracting party is obliged to comply with all instructions given by SN
                        and the travel leadership (such as but not limited to trip leaders, guides,
                        diving instructor and diving assistants, captain and crew of the ships,
                        helicopter personal/pilots, local agents and the personnel of accommodation
                        providers such as hotels, resorts and diving locations) in order to benefit
                        the sound execution of the trip. The contracting party is 100 % liable for
                        damage caused by any improper behaviour, such as but not limited to damage
                        to the environment, damage towards fellow travellers or material, damage to
                        the hotels, ships or resorts, to be judged according to the standards of
                        behaviour of the model traveller. The contracting party must not commit any
                        breach of any enactment or Law in the country, including regulations and
                        guidelines such as but not limited to the Association of Arctic Expeditions
                        Cruise Operators (AECO), Antarctic Treaty and International Association of
                        Antarctica Tour Operators (IAATO) and any breach or violation is solely the
                        responsibility of the travel participant.
                    </p>
                    <p className="bold">9.2</p>
                    <p>
                        Apart from possible punishment by Law, any contracting party who commits a
                        breach of any enactment, Law or guideline and / or causes such disturbance
                        or who threatens to cause such disturbance, that the proper execution of
                        (part of) a trip is seriously hindered or could result in danger for the
                        contracting party and/or the fellow travellers including SN and/or the
                        environment, can by or on behalf of SN (trip leadership or the local
                        representatives) be excluded from (continuation of) the trip (components).
                        In the case of exclusion, the contracting party is not entitled to make any
                        claim for restitution of the price or part of the price.
                    </p>
                    <p className="bold">9.3</p>
                    <p>
                        If the disturbing behaviour and/or damage and/or breach/violation (as
                        described in paragraphs 9.1 and 9.2) should occur, all costs resulting from
                        this shall be charged to the contracting party.
                    </p>
                    <p className="bold">9.4</p>
                    <p>
                        The contracting party is obliged to inform the tour leadership of SN of any
                        negligence noted in the execution of the travel contract, which is noted by
                        him at the location – in the case of a (travel) organisation: noted by the
                        client(s) of the (travel) organisation. This should be done as quickly as
                        possible, in writing, or other appropriate form of communication, to the
                        relevant tour leader/expedition leader of SN or the captain of the vessel,
                        who shall immediately do his utmost to find an appropriate solution.
                    </p>
                    <p className="bold">9.5</p>
                    <p>
                        If an immediate solution for the complaints cannot be found, the contracting
                        party – in the case of a (travel) organisation the client(s) of the (travel)
                        organisation should present these complaints to the relevant (travel)
                        organisation, where the trip was booked, upon which the (travel) organiser
                        shall inform SN ‐ shall be obliged to report the complaint to SN and request
                        help.
                    </p>
                    <p className="bold">9.6</p>
                    <p>
                        SN (incl. the representatives, trip leadership, local agent) is entitled to
                        reject the complaint in the case that the complaint does not seriously
                        influence the character of the trip and/or the complaint results only in
                        hindrance of minor significance, if exaggerated demands are made, if it is
                        impossible to provide help to the contracting party within the set time
                        limit, if the deficiency in the implementation of the contract is
                        attributable to the contracting party him/herself, if the deficiency in the
                        execution of the contract could not have been foreseen or could not be
                        neutralised, or if the deficiency in the execution of the contract is
                        attributable to situations of force majeure (that is those abnormal and
                        unforeseeable circumstances that are independent of the will of whosoever
                        claims it and of which the consequences, despite every precaution having
                        been taken, could not have been avoided, see also situations of force
                        majeure as described in par. 5.3).
                    </p>
                    <p className="bold">9.7</p>
                    <p>
                        In the case that the complaint has not been satisfactorily dealt with during
                        the trip, the contracting party – in the case of a (travel) organisation the
                        client(s) of the (travel) organisation should present these complaints to
                        the relevant (travel) organisation, where the trip was booked, upon which
                        the (travel) organiser shall present the complaint to SN ‐ shall present the
                        complaint to SN at the very latest within one month of the termination of
                        the trip (the last travel day). The complaint must be presented in writing.
                    </p>
                    <p className="bold">9.8</p>
                    <p>
                        In the case that the complaint has then not been satisfactorily settled by
                        SN or if proper satisfaction has been not given in this matter, the
                        contracting party is entitled to present the dispute to the Courts of Norway
                        (which courts shall have exclusive jurisdiction).
                    </p>
                    <h5>10. Baggage / Travel documents and insurance</h5>
                    <p>
                        In the case of a (travel) organisation: for "contracting party" (in the
                        paragraphs 10.1 up to and including 10.4) read also “the client(s) of the
                        (travel) organiser”.
                    </p>
                    <p className="bold">10.1</p>
                    <p>
                        The contracting party must him/herself obtain the necessary information with
                        regard to and must have in his possession on departure and during the trip
                        the necessary travel documents, such as a valid passport and any required
                        visa, diving certificates/documents proof of inoculations and vaccinations.
                        In the case that the participant is unable to make (part of) a trip due to
                        lack of such a travel document, SN is not liable and may not be held
                        (financially) responsible.
                    </p>
                    <p className="bold">10.2</p>
                    <p>
                        The contracting party must comply with the current import restrictions of
                        the various destinations and the amount. SN may not be held liable for e.g.
                        damage and prison sentences which could be imposed in the case of a
                        contravention.
                    </p>
                    <p className="bold">10.3</p>
                    <p>
                        The obligation of SN to provide assistance to a contracting party in need is
                        greatly impeded if it is not possible to resort to the S.O.S. help service
                        included in travel and baggage insurance.
                    </p>
                    <p className="bold">10.4</p>
                    <p>
                        IT IS MANDATORY that the contracting party effects the appropriate travel
                        insurance, including a medical, accident and repatriation/evacuation
                        insurance. SN furthermore STRONGLY RECOMMENDS the contracting party to
                        effect cancellation insurance. In case of a medical problem arising during
                        the voyage, either on board or on shore, which results in costs for medical
                        treatment, evacuation, use of aircraft or repatriation etc. etc. the
                        responsibility for payment of these costs belongs solely to the contracting
                        party. IT IS MANDATORY for the contracting party to ensure that such
                        eventualities are covered by travel insurance (incl. medical, accident and
                        repatriation/evacuation insurance). In any case, if not covered by
                        appropriate travel insurance the responsibility still remains with the
                        contracting party and SN specifically declines any responsibility
                        whatsoever.
                    </p>
                    <h5>11. General</h5>
                    <p className="bold">11.1</p>
                    <p>
                        If the duration of the trip as stated in the publication is given in days,
                        the day of departure and the day of arrival, irrespective of departure or
                        arrival times, are counted as full days.
                    </p>
                    <p className="bold">11.2</p>
                    <p>
                        The laws of Norway apply to the travel contract and all matters ensuing from
                        this contract.
                    </p>
                    <p className="bold">11.3</p>
                    <p>
                        This contract is made with: SeilNorge AS, 8985 Ylvingen, Norway - a company
                        registered in Norway with number 995 785 773.
                    </p>
                </div>
            );

        case 'nb_NO':
        default:
            return (
                <div>
                    <p className="bold">
                        VI ANBEFALER DEG Å LESE NØYE GJENNOM VÅRE REISEVILKÅR FØR DU BESTILLER EN
                        REISE
                    </p>
                    <p>
                        Vær oppmerksom på at vilkårene nedenfor er en del av den avtalen du inngår
                        med oss når du melder deg på en reise. Les derfor gjennom vilkårene før du
                        melder deg på.
                    </p>
                    <h5>§ 1. VIKTIGE UTGANGSPUNKT / SPESIELLE FORHOLD</h5>
                    <p>
                        SeilNorge arrangerer spesialturer og tilrettelegger for individuelle reiser.
                        SeilNorge forholder seg til lov om Pakkereiser nr. 57. 1995 med forskrifter
                        og de alminnelige vilkår for Pakkereiser som er utarbeidet av Den Norske
                        Reisebransjeforening (DNR) i samarbeid med Forbrukerombudet (FO).
                    </p>
                    <p>
                        Vår turproduksjon er basert på naturbaserte opplevelser og aktiviteter som
                        stiller spesielle krav både til oss som arrangør og våre deltagere. Vi
                        legger opp reiseruter hvor vi benytter lokale underleverandører samt bruker
                        transportmidler hvis drift og regularitet til tider blir berørt av
                        plutselige og uforutsigbare endringer. På havet er sikkerheten alltid i
                        fokus, og vi vil uten forvarsel kunne måtte endre planlagt seilas og finne
                        alternativer for å holde gjester og mannskap trygge.
                    </p>
                    <p>
                        Våre destinasjoner også være mer utsatt for skiftende naturgitte forhold enn
                        vanlige feriemål. Alt dette krever fleksibilitet i vårt produksjonsapparat.
                        Disse forholdene fører imidlertid til at våre beskrivelser av reiseruter,
                        turinnhold og tidsangivelser slik de framgår av vårt materiell, fra tid til
                        annen må endres på kort varsel. Der vi har mulighet til det, vil slike
                        endringer bli meddelt før avtaleinngåelse. Men der behovet for endringer
                        inntrer etter bestilling, enten før eller etter avreise, vil vi i så stor
                        grad som mulig sørge for at innhold/aktiviteter/reisemål/transport ligger så
                        tett opp til det opprinnelige som mulig, og innenfor den samme prisrammen.
                    </p>
                    <p>
                        Denne usikkerheten er altså en del av den “pakken” vi tilbyr. Vi vil
                        selvsagt, med dette forbeholdet om visse endringer som utgangspunkt og del
                        av avtalen, respektere de rettigheter kundene har etter pakkereiseloven
                        dersom endringene er av slik art at reisen likevel anses mangelfull.
                    </p>
                    <p>
                        Våre spesielle produksjonsforhold har ført til at vi må ha egne bestemmelser
                        om størrelse av depositum, betalingstidspunkter, gebyrer og avbestilling mot
                        vederlag i forhold til normalreglene i Alminnelige vilkår for Pakkereiser,
                        se nedenstående. Punktene nedenfor sammen med informasjonen i
                        detaljprogrammet utgjør våre avtalevilkår. Om SeilNorge gir opplysninger om
                        endringer i ruter, betingelser etc. før kjøp av reisen, vil disse korrigerte
                        opplysningene erstatte tilsvarende punkter i detaljprogrammet. Avtalen
                        mellom arrangøren og kunden utgjøres således av disse reisebetingelsene,
                        eventuelt korrigert informasjon og ordrebekreftelse. Reiseruter, reisetider,
                        tidtabeller, dag-til-dag program og annen informasjon på vår nettside
                        og/eller prisliste er fastlagt lang tid i forveien, gjerne et år eller mer.
                        Disse opplysningene kan derfor være ukorrekte før reisen begynner, eller
                        endringer kan inntre etter at reisen er påbegynt. Våre reiseruter er derfor
                        å anse som foreløpige. I slike tilfeller har vi som arrangør, i samarbeid
                        med lokale arrangører, som mål å erstatte det kansellerte eller endrede
                        programmet med tilsvarende innhold av samme kvalitet. Normalt vil slike
                        endringer bli opplyst allerede ved bestilling av reisen, eventuelt straks vi
                        er kjent med saken.
                    </p>
                    <p>
                        Som turoperatør gjør vi alt som står i vår makt for å løse eventuelle
                        problemer som kan oppstå. I ekstreme tilfeller kan i siste omgang et
                        reisemål bli byttet ut med et annet. Hvor ytre omstendigheter (“force
                        majeure”) gjør det umulig å gjennomføre reiseopplegget, medfører det normalt
                        ingen reduksjon i reisens pris.
                    </p>
                    <p>
                        Vær oppmerksom på at våre reiser innebærer at det kan oppstå farer og
                        ulykker. Du som kunde må være inneforstått med at deltagelse gjøres på eget
                        ansvar. Dette elementet er langt mer fremtredende på våre reiser enn på de
                        fleste andre pakkereiser. Våre turledere er erfarne for våre reisemål og
                        deres anvisninger må følges. Dersom det likevel oppstår ulykker eller ubehag
                        kan det ikke gjøres gjeldende ansvar overfor turleder eller overfor oss.
                    </p>
                    <p>
                        Våre reiser foregår ofte i et værutsatt område, og reisene kan være basert
                        på en logistikk som er sårbar for dårlig vær, tekniske problemer eller rett
                        og slett uhell. Det kan eksempelvis innebære at man ikke rekker planlagt
                        hjemreise. Vi vil i så tilfelle hjelpe deg med å endre hjemreisetidspunktet
                        på flybilletten, men vi kan ikke garantere at nye billetter vil bli utstedt
                        uten kostnader for deg som deltager. Sikkerheten for deltakerne på turene
                        vil alltid være i fokus for våre turledere og for oss. Dersom det oppstår
                        uventede situasjoner grunnet værforhold, temperaturer, sykdom mm. og dette
                        etter turlederens vurdering av sikkerhet for alle deltagerne gjør det
                        nødvendig å foreta endringer i reisen etter at den er startet, plikter du å
                        følge det endrede opplegg.
                    </p>
                    <p>
                        Merkostnader som følge av nye billetter, transportkostnader, kost og losji
                        mm må du i så tilfelle dekke selv. SeilNorge opptrer noen ganger som
                        agent/formidler for ulike leverandører og samarbeidspartnere, og det er i de
                        tilfellene disse som er kontraktspartner. Vi har således ikke noe ansvar for
                        kontraktsmessig leveranse av de formidlede tjenester.
                    </p>
                    <h5>§ 2. DITT ANSVAR</h5>
                    <p className="bold">§ 2.1</p>
                    <p>
                        Avbestillingsbeskyttelse må tegnes samtidig med at reisen bestilles. Hvis
                        slik beskyttelse ikke er tegnet, gjelder våre vanlige avbestillingsregler
                        §5. Eventuell avbestillingen må skje skriftlig umiddelbart etter at man ble
                        klar over at reisen ikke lar seg gjennomføre. Du er selv ansvarlig for å
                        kontakte ditt forsikringsselskap.
                    </p>
                    <p className="bold">§ 2.2</p>
                    <p>
                        Du er pliktig å ha tegnet en fullgod syke og ulykkesforsikring som også skal
                        dekke en eventuell hjemtransport.
                    </p>
                    <p className="bold">§ 2.3</p>
                    <p>
                        Du har selv ansvaret for å kontrollere at mottatte reisedokumenter stemmer
                        overens med din bestilling.
                    </p>
                    <p className="bold">§ 2.4</p>
                    <p>
                        Noen av våre turer er fysisk og teknisk krevende. Du plikter derfor å være i
                        god fysisk og mental form og godt teknisk forberedt ved avreise. Kontakt
                        SeilNorge for krav til fysisk form og teknisk kompetanse for din tur.
                    </p>
                    <p className="bold">§ 2.5</p>
                    <p>
                        Du står ansvarlig for å holde arrangøren orientert om en eventuell
                        adresseendring slik at informasjon og reisedokument kommer frem i tide.
                    </p>
                    <p className="bold">§ 2.6</p>
                    <p>
                        Du plikter å holde deg orientert om avganger og etterkomme anvisninger som
                        arrangør, transportør, lufthavn e.l. gir i den anledning.
                    </p>
                    <p className="bold">§ 2.7</p>
                    <p>
                        Du skal følge de instrukser, anvisninger og pålegg som turlederne gir på
                        reisen.
                    </p>
                    <p className="bold">§ 2.8</p>
                    <p>
                        Du plikter under reisen å rette deg etter de regler og bestemmelser som er
                        gitt av myndigheter, arrangøren eller arrangørens representanter, herunder
                        de ordensbestemmelser som måtte være gitt av det enkelte hotell, transportør
                        e.l.
                    </p>
                    <p className="bold">§ 2.9</p>
                    <p>
                        Du må ikke opptre slik at det er til sjenanse for de medreisende. Dersom du
                        grovt tilsidesetter ditt ansvar og dine plikter, kan du nektes å delta eller
                        utelukkes fra resten av reisen, uten at du kan kreve tilbakebetaling fra
                        arrangøren. Ved utelukkelse etter at reisen er påbegynt, kan du pålegges å
                        bære omkostningene ved hjemreisen selv.
                    </p>
                    <p>
                        Du kan gjøres erstatningsansvarlig hvis du med vilje eller uaktsomhet
                        påfører arrangøren tap bl.a. ved ikke å overholde de bestemmelser som er
                        nevnt ovenfor.
                    </p>
                    <p className="bold">§ 2.10</p>
                    <p>
                        SeilNorge påtar seg intet ansvar for tap eller ulempe som skyldes den
                        reisendes egen forsømmelse.
                    </p>
                    <h5>§ 3. BESTILLING/DEPOSITUM/BETALING/ENDRING</h5>
                    <p className="bold">§ 3.1</p>
                    <p>
                        Påmelding/bestilling av reiser med SeilNorge skal skje skriftlig eller
                        elektronisk, og er bindende. Angrefristlovens hevingsrett gjelder ikke for
                        reservasjonstjenester. Forsinket betaling av depositum og totalsum anses som
                        et vesentlig mislighold, og gir SeilNorge rett til å heve bestillingen, men
                        vårt krav på depositum faller ikke bort ved slik heving og vil om nødvendig
                        bli inndrevet.
                    </p>
                    <p className="bold">§ 3.2</p>
                    <p>
                        Faktura for depositum, på 6000,-, utsendes av SeilNorge etter mottatt
                        påmelding og forfaller innen 10 dager. For kurs/reiser med totalbeløp under
                        kr 10 000 faktureres hele beløpet ved påmelding og forfaller innen 10 dager.
                        Halvparten av dette beløpet anses da som depositum.
                    </p>
                    <p className="bold">§ 3.3</p>
                    <p>
                        Betaling. Reisens restbeløp (minus depositum) må være SeilNorge i hende
                        senest 60 dager før avreise. Ved eventuell bestilling etter dette, forfaller
                        hele reisens beløp til betaling innen 3 dager.
                    </p>
                    <p className="bold">§ 3.4</p>
                    <p>
                        Endringer i bestillingen er mulig for turen innenfor samme sesong, forutsatt
                        at endringen lar seg gjennomføre.
                    </p>
                    <h5>§ 4 SEILNORGE SITT ANSVAR</h5>
                    <p className="bold">§ 4.1</p>
                    <p>Ved kjøp av en pakkereise gjelder pakkereiseloven.</p>
                    <p className="bold">§ 4.2</p>
                    <p>
                        SeilNorge forplikter seg til å gjennomføre et avtalt program med reiserute
                        og innhold i følge utsendt reisebeskrivelse. Det tas imidlertid forbehold om
                        forhold som ligger utenfor vår kontroll.
                    </p>
                    <p className="bold">§ 4.3</p>
                    <p>
                        Ved våre seilturer kreves et minimum av deltakere for at reisen skal kunne
                        gjennomføres. Minimum deltagerantall framkommer i turbeskrivelsen på
                        nettsiden vår. Dersom vi ikke oppnår et minimumsantall kan vi likevel ofte
                        gjennomføre reisen mot et pristillegg. Dersom vi må innstille reisen, vil
                        den reisende få beskjed om dette senest 30 dager før avreise. Hele det
                        innbetalte beløpet vil bli refundert.
                    </p>
                    <h5>§ 5. AVBESTILLING OG REFUSJON</h5>
                    <p className="bold">§ 5.1</p>
                    <p>Eventuell avbestilling må skje skriftlig eller elektronisk.</p>
                    <p>
                        Det gjelder ikke som avmelding å bare la være å betale fakturaen(e), du må
                        sende skriftlig beskjed (e-post) og motta skriftlig bekreftelse fra
                        SeilNorge om at avmeldingen er mottatt.
                    </p>
                    <p>
                        Avbestillingsdato regnes som den dato avbestillingen kommer SeilNorge i
                        hende. Dersom en avbestillingsfrist faller på en helligdag eller alminnelig
                        fridag, må en eventuell avbestilling være innkommet på nærmeste vanlige
                        virkedag før denne.
                    </p>
                    <p className="bold">§ 5.2</p>
                    <p>
                        Refusjon ved avbestilling av en reise skjer etter følgende regler: Ved
                        avbestilling av reisen mer enn 60 dager før avreise tapes depositum. Ved
                        avbestilling mellom 59 og 30 dager før avreise tapes 50 prosent av reisens
                        totalsum. Ved avbestilling mindre enn 30 dager før avreise tapes 100 prosent
                        av reisens totalsum.
                    </p>
                    <p className="bold">§ 5.3</p>
                    <p>
                        Ved uteblivelse på avreisedagen gis ingen refusjon, uansett årsak, og
                        eventuelle krav må rettes til vedkommenes forsikringsselskap.
                    </p>
                    <h5>§ 6. REKLAMASJON / TVISTESPØRSMÅL</h5>
                    <p className="bold">§ 6.1</p>
                    <p>
                        Om du mener at det foreligger en feil/ mangel ved reisen må du ta dette opp
                        med turleder umiddelbart. Om feilen/mangelen ikke blir rettet og du ønsker å
                        reklamere på reisen når du kommer hjem, må det senest en måned etter at
                        turen er avsluttet sendes en skriftlig, begrunnet klage til SeilNorge.
                    </p>
                    <p className="bold">§ 6.2</p>
                    <p>
                        Dersom det oppstår uenighet om forståelsen av ovenstående reisebetingelser,
                        kan dette bringes inn for et tvisteløsningsorgan. Et hvert søksmål skal
                        reguleres etter norsk rett.
                    </p>
                    <p className="bold">§ 6.3</p>
                    <p>
                        Det tas forbehold om trykkfeil i detaljprogrammet og prisliste, samt på våre
                        nettsider.
                    </p>
                    <h5>§ 8. RETTIGHETER I FORBINDELSE MED TEKST OG BILLEDBRUK</h5>
                    <p>
                        På noen av våre turer publiserer vi bilder på sosiale medier, og her nevnes
                        deltakere med fornavn. SeilNorge benytter også turbilder aktivt i sin
                        markedskommunikasjon. Dersom man ikke ønsker å være med på slike bilder, må
                        turleder underrettes senest ved avreise.
                    </p>
                </div>
            );
    }
};

export default Terms;
