export default function reducer(state = 'nb_NO', action) {
    switch (action.type) {
        case 'CHANGE_LOCALE':
            return {
                ...state,
                locale: action.payload,
            };
        default:
            return state;
    }
}
