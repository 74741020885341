export default function reducer(
    state = {
        loaded: false,
    },
    action
) {
    switch (action.type) {
        case 'SET_LOADED_STATE':
            return { ...state, loaded: action.payload };
        default:
            return state;
    }
}
