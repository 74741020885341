export default function convertToLocalPrice(price, locale) {
    const originalPrice = String(price);
    const priceThousands = originalPrice.slice(0, originalPrice.length - 3);
    const priceHundreds = originalPrice.slice(originalPrice.length - 3, originalPrice.length);

    switch (locale) {
        case 'en_US':
            return priceThousands + ',' + priceHundreds;
        default:
            return priceThousands + '.' + priceHundreds + ',-';
    }
}
