import React from 'react';
import { Row, Col } from 'react-bootstrap';

const i18n = {
    nb_NO: {
        successHeading: 'Takk! Vi holder deg oppdatert!',
        successDescription: 'Ta gjerne kontakt hvis du lurer på noe.',
    },
    en_US: {
        successHeading: 'Thank you! We will keep you posted!',
        successDescription: 'Please let us know if you have any questions.',
    },
};

const WaitingListThanks = ({ locale }) => {
    const lang = i18n[locale];
    return (
        <Row className="content short-row">
            <Col xs={8} xsOffset={2}>
                <h2 className="black">{lang.successHeading}</h2>
                <p className="centred">{lang.successDescription}</p>
            </Col>
        </Row>
    );
};

export default WaitingListThanks;
