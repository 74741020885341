/**
 * Created by hajer on 17/08/17.
 */
import axios from 'axios';
import * as _ from 'lodash';

const API_URL = 'https://loopback.seilnorge.no/api';

export function fetchAllBlogsByCategory(filter, locale) {
    console.log('the filter is', filter);
    if (filter !== 'all') {
        return function (dispatch) {
            const urlFilter = {
                where: { locale, or: [{ tags: { like: '%' + filter + '%' } }] },
                order: 'date DESC',
            };
            return axios
                .get(
                    API_URL +
                        '/BlogEntries?filter=' +
                        encodeURIComponent(JSON.stringify(urlFilter)),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            // 'x-api-key': token,
                        },
                    }
                )
                .then(function (response) {
                    dispatch({ type: 'FETCH_ALL_BLOGS_BY_CATEGORY_FULFILLED', payload: response });
                    dispatch({ type: 'SET_LOADED_STATE', payload: true });
                })
                .catch(function (error) {
                    dispatch({ type: 'FETCH_ALL_BLOGS_BY_CATEGORY_REJECTED', payload: error });
                });
        };
    } else {
        return function (dispatch) {
            const urlFilter = { where: { locale }, order: 'date DESC' };
            return axios
                .get(
                    API_URL +
                        '/BlogEntries?filter=' +
                        encodeURIComponent(JSON.stringify(urlFilter)),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            // 'x-api-key': token,
                        },
                    }
                )
                .then(function (response) {
                    dispatch({ type: 'FETCH_ALL_BLOGS_FULFILLED', payload: response });
                    dispatch({ type: 'SET_LOADED_STATE', payload: true });
                })
                .catch(function (error) {
                    dispatch({ type: 'FETCH_ALL_BLOGS_REJECTED', payload: error });
                });
        };
    }
}

export function fetchfrontBlogs(locale) {
    return function (dispatch) {
        const urlFilter = {
            where: { locale, featuredMainPage: true },
            limit: 3,
            order: 'date DESC',
        };
        axios
            .get(API_URL + '/BlogEntries?filter=' + encodeURIComponent(JSON.stringify(urlFilter)), {
                headers: {
                    'Content-Type': 'application/json',
                    // 'x-api-key': token,
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_FRONT_BLOGS_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_FRONT_BLOGS_REJECTED', payload: error });
            });
    };
}

export function fetchSliderBlogs(locale) {
    return function (dispatch) {
        const urlFilter = { where: { locale, featuredSlideshow: true }, order: 'date DESC' };
        axios
            .get(API_URL + '/BlogEntries?filter=' + encodeURIComponent(JSON.stringify(urlFilter)), {
                headers: {
                    'Content-Type': 'application/json',
                    // 'x-api-key': token,
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_SLIDER_BLOGS_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_SLIDER_BLOGS_REJECTED', payload: error });
            });
    };
}

export function fetchBlog(id) {
    return function (dispatch) {
        return axios
            .get(API_URL + '/BlogEntries/' + id, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_BLOG_BY_ID_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_BLOG_BY_ID_REJECTED', payload: error });
            });
    };
}

export function fetchAllCategoriesBlogs(locale) {
    return function (dispatch) {
        const filter = {
            where: {
                locale: locale,
                isHidden: false,
            },
        };
        return axios
            .get(API_URL + '/BlogCategories?filter=' + encodeURIComponent(JSON.stringify(filter)), {
                headers: {
                    'Content-Type': 'application/json',
                    // 'x-api-key': token,
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_ALL_BLOGS_CATEGORIES_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_ALL_BLOGS_CATEGORIES_FULFILLED', payload: error });
            });
    };
}

export function changeFilter(filtered) {
    return function (dispatch) {
        dispatch({ type: 'CHANGE_FILTER', payload: filtered });
    };
}
