import React, { Component } from 'react';

class UtsolgtEnglish extends Component {
    render() {
        const background = { fill: '#FF6600' };
        const text = { fill: '#FFFFFF' };

        return (
            <svg
                className="soldout-banner-gfx"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 50"
            >
                <title id="title">Sold out banner</title>

                <rect style={background} width="500" height="50" />
                <path
                    style={text}
                    d="M193.26,16.13c3.4,0,6.32,1.24,6.61,4.7v.35h-4.24c-.08-1-.7-1.78-2.24-1.78s-2.27.67-2.27,1.59c0,1.16.78,1.48,2,1.81,1.78.54,4.45,1,5.78,2.35a5.08,5.08,0,0,1,1.38,3.37v.27c0,4.21-3.59,5.62-7,5.62-3.67,0-6.64-1.38-7-5.1v-.38h4.35c.05,1.57,1.16,2.24,2.78,2.24s2.46-.78,2.46-1.78-.3-1.46-1.84-1.94c-1.94-.7-4.75-1.11-6-2.4a5.08,5.08,0,0,1-1.35-3.27V21.5C186.65,17.53,190.07,16.13,193.26,16.13Z"
                />
                <path
                    style={text}
                    d="M211.38,16c5.18,0,9.26,3.59,9.26,8.85v.65c0,5.26-4,8.85-9.26,8.85s-9.26-3.59-9.26-8.85V24.9C202.12,19.64,206.19,16,211.38,16Zm0,14.6c3,0,4.62-2.4,4.62-5.43s-1.51-5.43-4.62-5.43-4.56,2.4-4.56,5.43S208.43,30.65,211.35,30.65Z"
                />
                <path style={text} d="M223.42,16.46h4.45v14h7.42V34H223.42Z" />
                <path
                    style={text}
                    d="M245.42,16.46c4.4,0,8.53,2.92,8.53,8.45v.65c0,5.62-4.13,8.45-8.56,8.45h-7.5V16.46Zm-3.13,3.48V30.52h2.83c2.94,0,4.21-2.13,4.21-5.37s-1.35-5.21-4.16-5.21Z"
                />
                <path
                    style={text}
                    d="M269.86,16c5.18,0,9.26,3.59,9.26,8.85v.65c0,5.26-4,8.85-9.26,8.85s-9.26-3.59-9.26-8.85V24.9C260.6,19.64,264.67,16,269.86,16Zm0,14.6c3,0,4.62-2.4,4.62-5.43s-1.51-5.43-4.62-5.43-4.56,2.4-4.56,5.43S266.91,30.65,269.83,30.65Z"
                />
                <path
                    style={text}
                    d="M289.38,34.41c-5,0-7.58-2.51-7.58-7.13V16.46h4.51V28c0,1.84,1.27,2.73,3.19,2.73,1.65,0,3-.89,3-2.73V16.46h4.37V27.28C296.91,31.89,294.32,34.41,289.38,34.41Z"
                />
                <path style={text} d="M299.45,16.46H313.7V20h-4.8V34h-4.45V20h-5Z" />
            </svg>
        );
    }
}

export default UtsolgtEnglish;
