import axios from 'axios';

const API_URL = 'https://loopback.seilnorge.no/lambda';

export function fetchArticle(apiToken, id, locale) {
    return function (dispatch) {
        return axios
            .get(API_URL + '/articles/' + id + '?locale=' + locale, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiToken,
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_ARTICLE_BY_ID_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_ARTICLE_BY_ID_REJECTED', payload: error });
            });
    };
}
