/**
 * Created by hajer on 17/08/17.
 */
import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import Header from '../common/Header';

import { fetchAllBlogs } from '../../reducers/BlogActions';
import { fetchAllCategoriesBlogs } from '../../reducers/BlogActions';
import { connect } from 'react-redux';

class FilterBlogs extends Component {
    handleChange(event) {
        this.props.onUserInput(event.target.value);
        // add the orange bottom border when a category is selected
        if (event.target.className === 'btn btn-default') {
            if (this.PreviousTarget) this.PreviousTarget.className = 'btn btn-default';
            event.target.className = 'btn btn-default-selected';
        } else {
            event.target.className = 'btn btn-default';
            history.pushState('', '', '/blog/all');
        }
        this.PreviousTarget = event.target;
    }

    componentDidMount() {
        const { token, fetchedBlogCategoriesData, allcategories, locale, lang } = this.props;
        if (!fetchedBlogCategoriesData) {
            this.props.dispatch(fetchAllCategoriesBlogs(locale));
        }
    }

    componentDidUpdate() {
        const { token, fetchedBlogCategoriesData, allcategories, locale, lang } = this.props;
        if (!fetchedBlogCategoriesData) {
            this.props.dispatch(fetchAllCategoriesBlogs(locale));
        }
    }

    render() {
        const { fetchedBlogCategoriesData, allcategories, lang } = this.props;

        if (fetchedBlogCategoriesData) {
            var xcategories = allcategories.map(category => (
                <li className="blog-category" key={'category' + category.id}>
                    <Button
                        key={category.id}
                        onClick={this.handleChange.bind(this)}
                        value={category.tag}
                    >
                        {category.name}
                    </Button>
                </li>
            ));
        }

        return (
            <Row className="category-bar-container">
                <div className="category-bar">
                    <div className="inner-wrapper">
                        <ul>
                            <li className="blog-title">
                                <Button>{lang.blog} / </Button>
                            </li>
                            {xcategories}
                        </ul>
                    </div>
                </div>
            </Row>
        );
    }
}

const i18n = {
    nb_NO: {
        blog: 'Blogg',
    },
    en_US: {
        blog: 'Blog',
    },
};

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        fetchedBlogCategoriesData: state.blog.fetchedBlogCategoriesData,
        allcategories: state.blog.categories,
        locale: state.locale,
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(FilterBlogs);
