import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { addSubscriber } from '../../reducers/FormActions';
import { connect } from 'react-redux';

import NewsletterForm from './NewsletterForm';
import NewsletterThanks from './NewsletterThanks';

import '../../../css/newsletter.css';

class Newsletter extends Component {
    render() {
        const { subscribeComplete } = this.props;

        if (subscribeComplete) {
            return (
                <Row className="newsletter-card card">
                    <Col xs={12}>
                        <NewsletterThanks {...this.props} />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="newsletter-card card">
                    <Col xs={12}>
                        <NewsletterForm {...this.props} />
                    </Col>
                </Row>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        subscribeComplete: state.booking.subscribeComplete,
        locale: state.locale,
    };
}

const mapDispatchToProps = dispatch => ({
    addSubscriber: () => dispatch(addSubscriber()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
