import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import LinkNavItem from './LinkNavItem';
import LinkMenuItem from './LinkMenuItem';
import Logo from '../gfx/Logo';
import LogoEnglish from '../gfx/LogoEnglish';
import FlagNorway from '../gfx/FlagNorway';
import FlagUK from '../gfx/FlagUK';

import URLBuilder from '../../helpers/URLBuilder';

class Navigation extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
        };
    }

    toggleMenuBackground() {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        const { lang, locale } = this.props;

        let flag;
        let logo;

        switch (locale) {
            case 'en_US':
                flag = (
                    <LinkNavItem to={'https://seilnorge.no'}>
                        <FlagNorway />
                    </LinkNavItem>
                );
                logo = <LogoEnglish className="logo" />;
                break;
            default:
                flag = (
                    <LinkNavItem to={'https://sailnorway.com'}>
                        <FlagUK />
                    </LinkNavItem>
                );
                logo = <Logo className="logo" />;
                break;
        }

        return (
            <Navbar className={this.props.classNavbar} collapseOnSelect fluid={true}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <a href="/">{logo}</a>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse
                    onEntering={this.toggleMenuBackground.bind(this)}
                    onExited={this.toggleMenuBackground.bind(this)}
                >
                    <Nav className="navigation-main" pullRight>
                        <NavDropdown eventKey={1} title={lang.trips} id="basic-nav-dropdown">
                            <LinkMenuItem to="/alltrips/SkiSeil" children={lang.alltripsSkiSail} />
                            <LinkMenuItem
                                to="/alltrips/Sommer-Høst"
                                children={lang.alltripsSummerAutum}
                            />
                            <LinkMenuItem
                                to="/alltrips/Vinter-Vår"
                                children={lang.alltripsWinterSpring}
                            />
                            <LinkMenuItem
                                to="/alltrips/Ekspedisjon"
                                children={lang.alltripsExpedition}
                            />
                            <LinkMenuItem
                                to="/alltrips/Seilkurs"
                                children={lang.alltripsSailingCourse}
                            />
                            <LinkMenuItem
                                to="/alltrips/Våre%20båter"
                                children={lang.alltripsOurBoats}
                            />
                            <LinkMenuItem
                                to="/alltrips/Trænafestivalen"
                                children={lang.alltripsTraenaFestival}
                            />
                            <LinkMenuItem
                                to="/alltrips/Lag%20din%20egen"
                                children={lang.alltripsTailorYourOwn}
                            />
                        </NavDropdown>

                        <LinkNavItem to="/turkalender/Alle" children={lang.calendar} />
                        <LinkNavItem to="/blog/all" children={lang.blog} />
                        <LinkNavItem to={URLBuilder.article(1)} children={lang.practicalInfo} />
                        <LinkNavItem to={URLBuilder.article(2)} children={lang.aboutUs} />
                        {flag}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const i18n = {
    nb_NO: {
        trips: 'Turer',
        alltripsSkiSail: 'Ski & Seil',
        alltripsSummerAutum: 'Sommer / Høst',
        alltripsWinterSpring: 'Vinter / Vår',
        alltripsExpedition: 'Ekspedisjon',
        alltripsSailingCourse: 'Seilkurs',
        alltripsOurBoats: 'Våre båter',
        alltripsTraenaFestival: 'Trænafestivalen',
        alltripsTailorYourOwn: 'Lag din egen',
        custom: 'Lag din egen',
        calendar: 'Turkalender',
        blog: 'Blogg',
        practicalInfo: 'Praktisk info',
        aboutUs: 'Om oss',
    },
    en_US: {
        trips: 'Trips',
        alltripsSkiSail: 'Ski & Sail',
        alltripsSummerAutum: 'Summer / Autumn',
        alltripsWinterSpring: 'Winter / Spring',
        alltripsExpedition: 'Expedition',
        alltripsSailingCourse: 'Sailing courses',
        alltripsOurBoats: 'Our boats',
        alltripsTraenaFestival: 'Træna Festival',
        alltripsTailorYourOwn: 'Tailor your own',
        custom: 'Tailor your own',
        calendar: 'Activity calendar',
        blog: 'Blog',
        practicalInfo: 'Practical info',
        aboutUs: 'About us',
    },
};

function mapStateToProps(state) {
    return {
        lang: i18n[state.locale],
        locale: state.locale,
    };
}

export default connect(mapStateToProps, null)(Navigation);
