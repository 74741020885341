import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

class Facebook extends Component {
    componentDidMount() {
        // Trigger call to FB's parse function to ensure the Facebook Page plugin in render() is found by the FB SDK and
        // painted. If FB is already defined, trigger immediately, otherwise wait for fb_init event to be triggered
        // (see index.html for details)
        if (typeof window.FB !== 'undefined') {
            window.FB.XFBML.parse();
        } else {
            document.addEventListener(
                'fb_init',
                e => console.log('*** ERIC 3 ***') && window.FB.XFBML.parse()
            );
        }
    }

    render() {
        const lang = this.props.lang;

        return (
            <Row className="newsletter-card card">
                <Col xs={12}>
                    <Row>
                        <Col xs={12}>
                            <h4 className="black">{lang.heading}</h4>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <a href="https://www.facebook.com/seilnorge" target="_blank">
                        <img
                            src="https://seilnorge-images.imgix.net/images/fb_forsiden.jpg"
                            alt="SeilNorge på Facebook"
                            title="SeilNorge på Facebook"
                        />
                    </a>
                </Col>
            </Row>
        );
    }
}

const i18n = {
    nb_NO: {
        heading: 'Finn oss på Facebook',
    },
    en_US: {
        heading: 'Find us on Facebook',
    },
};

function mapStateToProps(state) {
    return {
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(Facebook);
