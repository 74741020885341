/**
 * RequestFormFactory.js
 *
 * Created: 8 March 2018 by Eric Bolikowski
 * Last update: 8 March 2018 by Eric Bolikowski
 *
 * Boat and Experience make up two out of three product types. Both let users send a "request",
 * to later be manually handled by SailNorway staff (as opposed to the third product type, Trip,
 * which has a fixed price, date etc, and has a complete booking + payment process).
 * As the Boat and Experience request forms are almost identical, they're built by the
 * RequestFormFactory. It takes a requestType parameter that must be either "boat" or
 * "experience", and adapts produced form accordingly.
 *
 */

import axios from 'axios/index';
import React from 'react';
import { Button, Clearfix, Col, Grid, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { deprecatedGetStoreState } from '../../../reducers/Store';
import Header from '../Header';

const LOOPBACK_URL = 'https://loopback.seilnorge.no/api';

const RequestFormFactory = requestType => {
    const renderField = ({
        input,
        label,
        placeholder,
        type,
        meta: { touched, error, warning },
    }) => (
        <div className="form-group">
            <label>{label}</label>
            <div>
                <input
                    className="form-control"
                    {...input}
                    placeholder={placeholder ? placeholder : label}
                    type={type}
                />
                {touched &&
                    ((error && <span className="error">{error}</span>) ||
                        (warning && <span className="warning">{warning}</span>))}
            </div>
        </div>
    );

    const renderTextarea = ({ input, label, placeholder, meta: { touched, error, warning } }) => (
        <div className="form-group">
            <label>{label}</label>
            <div>
                <textarea
                    className="form-control"
                    {...input}
                    placeholder={placeholder ? placeholder : label}
                ></textarea>
                {touched &&
                    ((error && <span className="error">{error}</span>) ||
                        (warning && <span className="warning">{warning}</span>))}
            </div>
        </div>
    );

    const i18n = {
        nb_NO: {
            required: 'Må fylles ut',
            invalidEmail: 'Ugyldig e-post',
            heading: 'Vi trenger litt info om deg og din forespørsel',
            fieldNameLabel: 'Navn',
            fieldEmailLabel: 'E-post',
            fieldMobileLabel: 'Telefon',
            fieldMessageLabel: 'Hva lurer du på og hvordan kan vi bidra?',
            proudMembersOf: 'Stolte medlemmer av',
            travelConditions: 'Reisebetingelser',
            nextButtonText: 'Neste',
            processing: 'Behandler...',
            successHeading: 'Tusen takk for forespørselen',
            successDescription: 'Du hører snart fra oss.',
        },
        en_US: {
            required: 'Required',
            invalidEmail: 'Invalid email',
            heading: 'Please provide some information about you and your request',
            fieldNameLabel: 'Name',
            fieldEmailLabel: 'Email',
            fieldMobileLabel: 'Phone number',
            fieldMessageLabel: 'How can we help and what is your request?',
            proudMembersOf: 'Proud member of',
            travelConditions: 'Terms and conditions',
            nextButtonText: 'Continue',
            processing: 'Processing...',
            successHeading: 'Thank you for booking with us',
            successDescription: "You'll be hearing from us shortly.",
        },
    };

    class RequestForm extends React.Component {
        constructor() {
            super();
            this.state = {
                busy: false,
            };
        }

        submitForm() {
            const state = deprecatedGetStoreState();

            const request = Object.assign({}, state.form.requestForm.values, {
                entityType: requestType,
                entityId: this.props.match.params.id,
            });

            this.setState({ busy: true });

            const that = this;

            axios
                .post(LOOPBACK_URL + '/requests', request)
                .then(function (response) {
                    that.setState({ busy: false, done: true });
                })
                .catch(function (error) {
                    that.setState({ busy: false });
                });
        }

        render() {
            const { locale, match } = this.props;

            const lang = i18n[locale];

            const required = value => (value ? undefined : lang.required);
            const emailRegex = /^[A-Z0-9a-z._%+-A-Z0-9a-z]+@[A-Z0-9a-z]{3,99}\.[A-Z0-9a-z]{2,4}/;
            const properEmail = value =>
                value && !emailRegex.test(value) ? lang.invalidEmail : undefined;

            var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

            const rgfLogo = s3BaseUrl + 'eksterne/rgf_logo_jpg.jpg';

            let busyScreenActive = this.state.busy;

            return (
                <Grid fluid={true}>
                    <Header image={s3BaseUrl + 'header_booking_xl.jpg'} header={lang.headerText} />

                    {this.state.done && (
                        <Row className="content short-row">
                            <Col xs={8} xsOffset={2}>
                                <h2 className="black">{lang.successHeading}</h2>
                                <p className="centred">{lang.successDescription}</p>
                            </Col>
                        </Row>
                    )}
                    {!this.state.done && (
                        <Row className="content">
                            <Col xs={12} sm={6} smOffset={3}>
                                <h3 className="black">{lang.heading}</h3>

                                <div>
                                    <Field
                                        name="name"
                                        label={lang.fieldNameLabel}
                                        type="text"
                                        component={renderField}
                                        validate={[required]}
                                    />
                                    <Field
                                        name="email"
                                        label={lang.fieldEmailLabel}
                                        type="email"
                                        component={renderField}
                                        validate={[required, properEmail]}
                                    />
                                    <Field
                                        name="mobile"
                                        label={lang.fieldMobileLabel}
                                        type="text"
                                        component={renderField}
                                    />
                                    <Field
                                        name="message"
                                        label={lang.fieldMessageLabel}
                                        component={renderTextarea}
                                        validate={[required]}
                                    />

                                    <Button
                                        type="submit"
                                        className="orange pull-right"
                                        onClick={this.submitForm.bind(this)}
                                    >
                                        {lang.nextButtonText}
                                    </Button>
                                </div>
                                <Clearfix />
                                <div className="centred" style={{ padding: '30px 0' }}>
                                    <p className="bold centred">{lang.proudMembersOf}</p>
                                    <a href="https://reisegarantifondet.no">
                                        <img
                                            src={rgfLogo}
                                            style={{ width: '100px' }}
                                            alt="Reisegarantifondet logo"
                                        />
                                    </a>
                                </div>
                            </Col>

                            {busyScreenActive && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        background: 'rgba(0,0,0,0.7)',
                                        zIndex: 9999999,
                                    }}
                                >
                                    <h3 style={{ position: 'absolute', left: '45%', top: '50%' }}>
                                        {lang.processing}
                                    </h3>
                                </div>
                            )}
                        </Row>
                    )}
                </Grid>
            );
        }
    }

    function mapStateToProps(state) {
        return {
            locale: state.locale,
            lang: i18n[state.locale],
            request: state.request,
        };
    }

    const connnectedForm = connect(mapStateToProps)(RequestForm);

    return reduxForm({
        form: 'requestForm',
        fields: ['name', 'email', 'mobile', 'message'],
    })(connnectedForm);
};

export default RequestFormFactory;
