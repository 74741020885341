import React, { Component } from 'react';

class FlagNorway extends Component {
    render() {
        return (
            <div className="flag-container">
                <svg id="NORWAY" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 21.82">
                    <title>norway for web 2</title>
                    <rect width="30" height="21.82" fill="#ef4135" />
                    <polygon
                        points="8.18 0 8.18 8.18 0 8.18 0 9.54 9.55 9.54 9.55 0 8.18 0"
                        fill="#fff"
                    />
                    <polygon
                        points="0 12.27 9.55 12.27 9.55 21.82 8.18 21.82 8.18 13.64 0 13.64 0 12.27"
                        fill="#fff"
                    />
                    <polygon
                        points="12.27 21.82 13.64 21.82 13.64 13.64 30 13.64 30 12.27 12.27 12.27 12.27 21.82"
                        fill="#fff"
                    />
                    <polygon
                        points="30 9.54 12.27 9.54 12.27 0 13.64 0 13.64 8.18 30 8.18 30 9.54"
                        fill="#fff"
                    />
                    <polygon
                        points="9.55 0 9.55 9.54 0 9.54 0 12.27 9.55 12.27 9.55 21.82 12.27 21.82 12.27 12.27 30 12.27 30 9.54 12.27 9.54 12.27 0 9.55 0"
                        fill="#003e7e"
                    />
                </svg>
            </div>
        );
    }
}

export default FlagNorway;
