import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class HeaderSeparator extends Component {
    render() {
        return (
            <Row className="header-separator">
                <Col xs={12}>
                    <div className="content-container">
                        <h2>{this.props.header}</h2>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default HeaderSeparator;
