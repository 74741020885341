import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import { fetchInstagramLatest } from '../../reducers/SocialActions';
import { connect } from 'react-redux';

class Instagram extends Component {
    componentDidMount() {
        this.props.dispatch(fetchInstagramLatest(this.props.token));
    }

    render() {
        const { instaFetched, instaLatest, lang } = this.props;

        return (
            <Row className="newsletter-card card">
                <Col xs={12}>
                    <Row>
                        <Col xs={12}>
                            <h4 className="black">{lang.heading}</h4>
                        </Col>
                    </Row>
                    {instaFetched && (
                        <div>
                            <Row className="no-gutter grid">
                                <Col xs={4}>
                                    <a href={instaLatest[0].link} target="_blank">
                                        <div
                                            className="employee-image card-background"
                                            style={{
                                                backgroundImage: [
                                                    'url(' +
                                                        instaLatest[0].images.standard_resolution
                                                            .url +
                                                        ')',
                                                ],
                                            }}
                                        ></div>
                                    </a>
                                </Col>
                                <Col xs={4}>
                                    <a href={instaLatest[1].link} target="_blank">
                                        <div
                                            className="employee-image card-background"
                                            style={{
                                                backgroundImage: [
                                                    'url(' +
                                                        instaLatest[1].images.standard_resolution
                                                            .url +
                                                        ')',
                                                ],
                                            }}
                                        ></div>
                                    </a>
                                </Col>
                                <Col xs={4}>
                                    <a href={instaLatest[2].link} target="_blank">
                                        <div
                                            className="employee-image card-background"
                                            style={{
                                                backgroundImage: [
                                                    'url(' +
                                                        instaLatest[2].images.standard_resolution
                                                            .url +
                                                        ')',
                                                ],
                                            }}
                                        ></div>
                                    </a>
                                </Col>
                            </Row>
                            <Row className="no-gutter grid">
                                <Col xs={4}>
                                    <a href={instaLatest[3].link} target="_blank">
                                        <div
                                            className="employee-image card-background"
                                            style={{
                                                backgroundImage: [
                                                    'url(' +
                                                        instaLatest[3].images.standard_resolution
                                                            .url +
                                                        ')',
                                                ],
                                            }}
                                        ></div>
                                    </a>
                                </Col>
                                <Col xs={4}>
                                    <a href={instaLatest[4].link} target="_blank">
                                        <div
                                            className="employee-image card-background"
                                            style={{
                                                backgroundImage: [
                                                    'url(' +
                                                        instaLatest[4].images.standard_resolution
                                                            .url +
                                                        ')',
                                                ],
                                            }}
                                        ></div>
                                    </a>
                                </Col>
                                <Col xs={4}>
                                    <a href={instaLatest[5].link} target="_blank">
                                        <div
                                            className="employee-image card-background"
                                            style={{
                                                backgroundImage: [
                                                    'url(' +
                                                        instaLatest[5].images.standard_resolution
                                                            .url +
                                                        ')',
                                                ],
                                            }}
                                        ></div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
            </Row>
        );
    }
}

const i18n = {
    nb_NO: {
        heading: 'Siste fra Instagram',
    },
    en_US: {
        heading: 'Last from Instagram',
    },
};

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        instaFetched: state.social.instaFetched,
        instaLatest: state.social.insta_latest,
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(Instagram);
