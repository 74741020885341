import React, { Component } from 'react';

class SoMeInstagram extends Component {
    render() {
        return (
            <svg className="some-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 38">
                <title id="title">Instagram Logo</title>
                <path
                    d="M25.4957704,34.1649538 C27.5399846,34.1649538 29.4694921,33.3628769 30.9288411,31.9066462 C32.3882672,30.4503385 33.1919846,28.5249538 33.1919846,26.4851077 L33.1919846,10.4979538 C33.1919846,8.45810769 32.3882672,6.53272308 30.9288411,5.07641538 C29.4694921,3.62010769 27.5399846,2.81810769 25.4957704,2.81810769 L10.4603358,2.81810769 C8.41612163,2.81810769 6.48661413,3.62010769 5.02718801,5.07641538 C3.56776188,6.53272308 2.76404454,8.45810769 2.76404454,10.4979538 L2.76404454,26.4851077 C2.76404454,28.5249538 3.56776188,30.4503385 5.02718801,31.9066462 C6.48661413,33.3628769 8.41612163,34.1649538 10.4603358,34.1649538 L25.4957704,34.1649538 Z M25.4957704,36.9231077 L10.4603358,36.9231077 C4.70711949,36.9231077 -1.54175586e-05,32.2260308 -1.54175586e-05,26.4851077 L-1.54175586e-05,10.4979538 C-1.54175586e-05,4.75703077 4.70711949,0.0599538462 10.4603358,0.0599538462 L25.4957704,0.0599538462 C31.2489867,0.0599538462 35.9561216,4.75703077 35.9561216,10.4979538 L35.9561216,26.4851077 C35.9561216,32.2260308 31.2489867,36.9231077 25.4957704,36.9231077 L25.4957704,36.9231077 Z"
                    id="Fill-1"
                    fill="#FF6E00"
                    mask="url(#mask-2)"
                ></path>
                <path
                    d="M17.9859315,11.9044077 C14.4052034,11.9044077 11.5025396,14.8009462 11.5025396,18.3739462 C11.5025396,21.9470231 14.4052034,24.8435615 17.9859315,24.8435615 C21.5666595,24.8435615 24.4693233,21.9470231 24.4693233,18.3739462 C24.4693233,14.8009462 21.5666595,11.9044077 17.9859315,11.9044077 M27.9348051,18.3661769 C27.9348051,23.8227154 23.5018715,28.2461769 18.0335717,28.2461769 C12.565349,28.2461769 8.13241542,23.8227154 8.13241542,18.3661769 C8.13241542,12.9095615 12.565349,8.4861 18.0335717,8.4861 C23.5018715,8.4861 27.9348051,12.9095615 27.9348051,18.3661769"
                    id="Fill-4"
                    fill="#FF6E00"
                ></path>
                <path
                    d="M30.438185,8.33244615 C30.438185,9.62444615 29.3885576,10.6719077 28.0937139,10.6719077 C26.7989473,10.6719077 25.7493199,9.62444615 25.7493199,8.33244615 C25.7493199,7.04044615 26.7989473,5.99298462 28.0937139,5.99298462 C29.3885576,5.99298462 30.438185,7.04044615 30.438185,8.33244615"
                    id="Fill-6"
                    fill="#FF6E00"
                ></path>
            </svg>
        );
    }
}

export default SoMeInstagram;
