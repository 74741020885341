/**
 * Created by Shems on 29/03/2017.
 */
/**
 * Created by Shems on 29/03/2017.
 */
import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

class FilterProducts extends Component {
    PreviousTarget;

    render() {
        const { fetchedTopicPages, allTopicPages } = this.props;

        if (!fetchedTopicPages) {
            return <div></div>;
        }

        return (
            <Row className="topic-pages-bar-container">
                <div className="topic-pages-bar">
                    <div className="topic-pages-inner-wrapper">
                        <ul>
                            {allTopicPages.map(topic => (
                                <li className="topic-page-title" key={topic.id}>
                                    <NavLink
                                        style={{ fontSize: '20px' }}
                                        to={topic.keyword}
                                        activeClassName="active"
                                    >
                                        {topic.title}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Row>
        );
    }
}

const i18n = {
    nb_NO: {
        buttonTextSkiSail: 'Ski & Seil',
        buttonTextSummerAutumn: 'Sommer / Høst',
        buttonTextWinterSpring: 'Vinter / Vår',
        buttonTextExpeditions: 'Ekspedisjon',
        buttonTextSailngCourses: 'Seilkurs',
    },
    en_US: {
        buttonTextSkiSail: 'Ski & Sail',
        buttonTextSummerAutumn: 'Summer / Autumn',
        buttonTextWinterSpring: 'Winter / Spring',
        buttonTextExpeditions: 'Expedition',
        buttonTextSailngCourses: 'Sailing courses',
    },
};

function mapStateToProps(state) {
    return {
        fetchedTopicPages: state.trip.fetchedTopicPages,
        allTopicPages: state.trip.allTopicPages,
    };
}

export default connect(mapStateToProps, null)(FilterProducts);
