export default function reducer(
    state = {
        fetching: false,
        fetched: false,
        apiToken: null,
        error: null,
    },
    action
) {
    switch (action.type) {
        case 'API_SET_TOKEN':
            return { ...state, apiToken: 'DhpNZPsqLu6iYREfTS78G5HXQaYUqTRR7J2ceeaJ' };
        default:
            return state;
    }
}
