import React, { Component } from 'react';
import { Row, Col, Glyphicon } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import URLBuilder from '../../helpers/URLBuilder';
import Utsolgt from '../gfx/Utsolgt';
import UtsolgtEnglish from '../gfx/UtsolgtEnglish';

import { connect } from 'react-redux';
import moment from 'moment';

import convertToLocalPrice from '../../helpers/ConvertToLocalPrice';
import { fetchTrip } from '../../reducers/TripActions';

class CalendarCard extends Component {
    convertToNorwegianMonthNames(month) {
        switch (month) {
            case 0:
                return 'januar';
            case 1:
                return 'februar';
            case 2:
                return 'mars';
            case 3:
                return 'april';
            case 4:
                return 'mai';
            case 5:
                return 'juni';
            case 6:
                return 'juli';
            case 7:
                return 'august';
            case 8:
                return 'september';
            case 9:
                return 'oktober';
            case 10:
                return 'november';
            case 11:
                return 'desember';
            default:
                return 'måned';
        }
    }

    convertToEnglishMonthNames(month) {
        switch (month) {
            case 0:
                return 'January';
            case 1:
                return 'February';
            case 2:
                return 'March';
            case 3:
                return 'April';
            case 4:
                return 'May';
            case 5:
                return 'June';
            case 6:
                return 'July';
            case 7:
                return 'August';
            case 8:
                return 'September';
            case 9:
                return 'October';
            case 10:
                return 'November';
            case 11:
                return 'December';
            default:
                return 'month';
        }
    }

    convertToReadableDate(date, locale) {
        var depDate = new Date(date);

        switch (locale) {
            case 'nb_NO':
                var returnDate =
                    moment(depDate).utc().date() +
                    '. ' +
                    this.convertToNorwegianMonthNames(depDate.getMonth()) +
                    ' ' +
                    depDate.getFullYear();
                return returnDate;
            case 'en_US':
                var returnDate =
                    this.convertToEnglishMonthNames(depDate.getMonth()) +
                    ' ' +
                    moment(depDate).utc().date() +
                    ', ' +
                    depDate.getFullYear();
                return returnDate;
        }
    }

    handleClick(tripId, slug){
        this.props.fetchTrip(this.props.token, tripId, this.props.locale)
    }

    render() {
        const { locale, lang, trip } = this.props;

        var departureDate = this.convertToReadableDate(this.props.trip.departure_date, locale);
        var soldOutBanner;

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        switch (locale) {
            case 'nb_NO':
                soldOutBanner = <Utsolgt />;
                break;
            case 'en_US':
                soldOutBanner = <UtsolgtEnglish />;
                break;
        }

        return (
            <Row className="travel-stats">
                <Col xs={12} sm={8} smOffset={2}>
                    {/* START: Trip row */}
                    <Row>
                        {this.props.trip.sold_out == 1 && (
                            <div className="soldout-banner-mini">{soldOutBanner}</div>
                        )}

                        <Col xs={12}>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <div
                                        style={{
                                            float: 'right',
                                            color: '#ff6e00',
                                            fontSize: '20px',
                                            'text-align': 'right',
                                        }}
                                    >
                                        <div>
                                            {trip.capacity_status && (
                                                <img
                                                    src={`${s3BaseUrl}trip_capacity_status/${trip.capacity_status}_${locale}.png`}
                                                    style={{ width: '100px', height: '47px' }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <Link 
                                        to={URLBuilder.trip(
                                            this.props.trip.trip_id,
                                            this.props.trip.slug
                                        )}
                                        //This updates trip in redux
                                        onClick={() => this.handleClick(
                                            this.props.trip.trip_id,
                                            this.props.trip.slug
                                        )}
                                    >
                                        <div
                                            className="glyphicon-custom-link"
                                            style={{ marginBottom: '5px' }}
                                        >
                                            <Glyphicon
                                                glyph="circle-arrow-right"
                                                className="orange"
                                            />
                                            <h5 className="black" style={{ marginBottom: 0 }}>
                                                {this.props.trip.listing_heading}
                                            </h5>
                                        </div>
                                    </Link>
                                    <div>
                                        <h6
                                            className="black"
                                            style={{ marginTop: '2px', fontSize: '14px' }}
                                        >
                                            <em>{this.props.trip.trip_calendar_subtitle}</em>
                                        </h6>
                                    </div>
                                </Col>
                                <Col xs={12} lg={12}>
                                    <div>
                                        <div
                                            style={{
                                                float: 'right',
                                                color: '#ff6e00',
                                                fontSize: '20px',
                                                'text-align': 'right',
                                                position: 'relative',
                                                bottom: '5px',
                                            }}
                                        >
                                            {convertToLocalPrice(this.props.trip.price)}
                                        </div>
                                        <Glyphicon glyph="calendar" className="orange" />
                                        <p>{departureDate} &nbsp; &nbsp;</p>
                                        <Glyphicon glyph="time" className="orange" />
                                        <p>
                                            {this.props.trip.travel_days} {lang.duration}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* END: Trip row */}

                    <Row>
                        <Col xs={12}>
                            <hr style={{ borderColor: '#000' }} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        locale: state.locale,
        lang: i18n[state.locale],
        token: state.cognito.apiToken
    };
}

const mapDispahToProps = {
    fetchTrip
}

const i18n = {
    nb_NO: {
        duration: 'dager',
    },
    en_US: {
        duration: 'days',
    },
};

export default connect(mapStateToProps, mapDispahToProps)(CalendarCard);
