import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import '../../../css/header.css';

class Header extends Component {
    render() {
        const lang = this.props.lang;

        let backgroundImage = {
            backgroundImage: ['url(' + this.props.image + ')'],
        };

        return (
            <Row style={backgroundImage} className="no-gutter card-background header-card card">
                <Col xs={12}>
                    <div className="content-container">
                        <p className="teaser">{lang.uniqueAccess}</p>
                        <h1>{lang.awesomeNature}</h1>
                        <div className="chevron"></div>
                    </div>
                </Col>
            </Row>
        );
    }
}

const i18n = {
    nb_NO: {
        uniqueAccess: 'Unik tilgang til',
        awesomeNature: 'Verdens råeste natur',
    },
    en_US: {
        uniqueAccess: 'Unique access',
        awesomeNature: 'The rawest nature in the world',
    },
};

function mapStateToProps(state) {
    return {
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(Header);
