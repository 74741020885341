import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../../../css/header.css';

class Header extends Component {
    render() {
        let backgroundImage = {
            backgroundImage: ['url(' + this.props.image + ')'],
        };

        return (
            <Row style={backgroundImage} className="no-gutter card-background large-card card">
                <Col xs={12}>
                    <div className="content-container">
                        <h2 className={this.props.textClass}>{this.props.header}</h2>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default Header;
