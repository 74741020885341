let URLBuilder = {
    trip: (tripId, tripSlug) => `/trips/${tripId}-${tripSlug}`,
    boat: (tripId, tripSlug) => `/boats/${tripId}-${tripSlug}`,
    boatRequest: tripId => `/boats/${tripId}/request`,
    experience: (tripId, tripSlug) => `/experiences/${tripId}-${tripSlug}`,
    experienceRequest: tripId => `/experiences/${tripId}/request`,
    blog: (blogId, blogSlug) => `/blog-details/${blogId}-${blogSlug}`,
    article: artId => {
        let artSlug;
        switch (artId) {
            case 1:
                artSlug = 'praktisk-info';
                break;
            case 2:
                artSlug = 'om-oss';
                break;
            case 3:
                artSlug = 'lag-din-egen-tur';
                break;
            default:
                artSlug = 'undefined';
                break;
        }
        return `/articles/${artId}-${artSlug}`;
    },
};

export default URLBuilder;
