import React from 'react';
import { Row, Col } from 'react-bootstrap';

const LineHorisontal = () => (
    <Row>
        <Col xs={6} xsOffset={3}>
            <hr className="content-divider" />
        </Col>
    </Row>
);

export default LineHorisontal;
