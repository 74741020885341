import axios from 'axios';

const API_URL = 'https://loopback.seilnorge.no/lambda';

export function fetchInstagramLatest(token) {
    return function (dispatch) {
        axios
            .get(API_URL + '/social', {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': token,
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_INSTAGRAM_LATEST_FULFILLED', payload: response });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_INSTAGRAM_LATEST_REJECTED', payload: error });
            });
    };
}
