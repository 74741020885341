import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import HeaderFront from './common/HeaderFront';
import CampaignCard from './campaign/CampaignCard';
import TripCard from './trip/TripCard';
import Newsletter from './newsletter/Newsletter';
import InfoCardLeft from './common/InfoCardLeft';
import { fetchFrontTrips } from '../reducers/TripActions';
import { connect } from 'react-redux';
import InfoCardRight from './common/InfoCardRight';
import Instagram from './social/Instagram';
import Facebook from './social/Facebook';
import { setLoadedState } from '../reducers/ContentActions';

import URLBuilder from '../helpers/URLBuilder';
import InfoCard from './common/InfoCard';

class Front extends Component {
    componentDidMount() {
        if (!this.props.dataFetched) {
            this.props.dispatch(setLoadedState(false));
            this.props.dispatch(fetchFrontTrips(this.props.locale));
        }
    }

    render() {
        const { dataFetched, frontTrips, locale, lang } = this.props;

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        var jobCard;

        switch (locale) {
            case 'en_US':
                break;
            default:
                jobCard = (
                    <InfoCard
                        image={s3BaseUrl + 'header_jobb_card_3{size}.jpg'}
                        listing_header={lang.infoCardWorkHeader}
                        button_link={URLBuilder.article(4)}
                        button_link_target=""
                        button_text={lang.infoCardWorkButtonText}
                    />
                );
        }

        return (
            <Grid fluid={true}>
                <HeaderFront image={s3BaseUrl + 'forside_2_xl.jpg'} />
                <CampaignCard
                    image={s3BaseUrl + 'header_turkalender{size}.jpg'}
                    listing_intro={lang.campaignListingIntro}
                    listing_header={lang.campaignListingHeader}
                    button_link="/turkalender/Alle"
                    button_text={lang.buttonTextReadMore}
                />

                <Row>
                    <Col xs={12}>
                        <Row className="no-gutter">
                            {dataFetched &&
                                frontTrips.map(trip => (
                                    <TripCard
                                        key={trip.id}
                                        listing_intro={trip.listing_intro}
                                        listing_header={trip.listing_heading}
                                        button_text={lang.buttonTextReadMore}
                                        image={s3BaseUrl + trip.listing_image}
                                        button_link={URLBuilder.trip(trip.id, trip.slug)}
                                    />
                                ))}
                        </Row>
                    </Col>
                </Row>
                <InfoCardRight
                    image={s3BaseUrl + 'forside_eventyrere{size}.jpg'}
                    info_header={lang.infoCardLocalsHeader}
                    info_text={lang.infoCardLocalsText}
                    info_link={URLBuilder.article(2)}
                    link_text={lang.infoCardLocalsButtonText}
                />
                <InfoCardLeft
                    image={s3BaseUrl + 'forside_littrockie{size}.jpg'}
                    info_header={lang.infoCardRookieHeader}
                    info_text={lang.infoCardRookieText}
                    info_link={URLBuilder.article(1)}
                    link_text={lang.infoCardRookieButtonText}
                    card_colour="black"
                />
                {jobCard}
                <Row>
                    <Col xs={12} sm={4}>
                        <Facebook />
                    </Col>
                    <Col xs={12} sm={4}>
                        <Newsletter />
                    </Col>
                    <Col xs={12} sm={4}>
                        <Instagram />
                    </Col>
                </Row>
                <InfoCard
                    image={s3BaseUrl + 'batleie/batleie_2{size}.jpg'}
                    listing_header={lang.infoCardBoatHeader}
                    button_link="/alltrips/Våre%20båter"
                    button_link_target="_blank"
                    button_text={lang.infoCardBoatButtonText}
                />
            </Grid>
        );
    }
}

const i18n = {
    nb_NO: {
        campaignListingIntro: 'Mange ulike turer',
        campaignListingHeader: 'Utforsk ditt neste seileventyr',
        buttonTextReadMore: 'Les mer',
        infoCardLocalsHeader: 'Lokalkjente eventyrere',
        infoCardLocalsText:
            'SeilNorge har lang erfaring som turarrangør. Med opparbeidet lokalkunnskap og et unikt nettverk av steder og destinasjoner, kommer vi tettest på det beste av Nord-Norge. Våre skippere og mannskap er lokalkjente, drives av lidenskap og gir deg ekte opplevelser.',
        infoCardLocalsButtonText: 'Mer om oss',
        infoCardRookieHeader: 'Litt rookie?',
        infoCardRookieText:
            'Du trenger ingen seilerfaring for å seile med oss - du vil lære det du trenger å kunne underveis. Enten du har mye eller lite erfaring vil vi engasjere og utfordre deg i fellesskapet om bord i båten.',
        infoCardRookieButtonText: 'Mer om det praktiske',
        infoCardWorkHeader: 'Jobbe i SeilNorge?',
        infoCardWorkButtonText: 'Les om ledige stillinger',
        infoCardBoatHeader: 'Seile kysten på egen hånd? Vi har båter til leie',
        infoCardBoatButtonText: 'Se båtene',
    },
    en_US: {
        campaignListingIntro: 'Many different trips',
        campaignListingHeader: 'Explore your next sailadventure',
        buttonTextReadMore: 'Read more',
        infoCardLocalsHeader: 'Local knowhow',
        infoCardLocalsText:
            'SailNorway has a solid experience as tour operator. With our local knowledge and network of partners and destinations, we come close on the best of northern Norway. Our skippers and crew have local knowhow, are run by passion and will provide you with true experiences.',
        infoCardLocalsButtonText: 'More about us',
        infoCardRookieHeader: 'Rookie?',
        infoCardRookieText:
            "You don't need previous experience to sail with us - you will learn what you need to know as we go along. No matter how much experience, we will challenge you and encourage you as part of the team running the boat.",
        infoCardRookieButtonText: 'More about practical stuff',
        infoCardWorkHeader: '',
        infoCardWorkButtonText: '',
        infoCardBoatHeader: 'Sail the coast on your own? Rent boats from us!',
        infoCardBoatButtonText: 'Check out the boats',
    },
};

function mapStateToProps(state) {
    return {
        dataFetched: state.trip.fetchedFront,
        frontTrips: state.trip.front_trips,
        locale: state.locale,
        lang: i18n[state.locale], //,
    };
}

export default connect(mapStateToProps, null)(Front);
