export default function reducer(
    state = {
        fetching: false,
        fetched: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case 'FETCH_ARTICLE_BY_ID_START':
            return { ...state, fetching: true };
        case 'FETCH_ARTICLE_BY_ID_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_ARTICLE_BY_ID_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                article: action.payload.data[0],
            };
        default:
            return state;
    }
}
