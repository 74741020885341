import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import SoMeInstagram from '../gfx/SoMeInstagram';
import SoMeFacebook from '../gfx/SoMeFacebook';
import SoMeEmail from '../gfx/SoMeEmail';
import '../../../css/footer.css';

class Footer extends Component {
    render() {
        let now = new Date();
        const lang = this.props.lang;
        const year = new Date().getFullYear();

        return (
            <div className="container-fluid">
                <Row className="footer card">
                    <Col xs={12}>
                        <div className="content-container">
                            <Row>
                                <Col xs={12}>
                                    <div className="some-icons">
                                        <a href="https://www.instagram.com/seilnorge/">
                                            <SoMeInstagram />
                                        </a>
                                        <a href="https://www.facebook.com/seilnorge">
                                            <SoMeFacebook />
                                        </a>
                                        <a href="mailto:flaskepost@seilnorge.no">
                                            <SoMeEmail />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <h4>{lang.boatRentalHeading}</h4>
                                    <a href={lang.boatRentalEmail}>
                                        <div className="btn">{lang.contactUs}</div>
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div className="company-info">
                                        <p className="white centred bold">
                                            SeilNorge AS &copy; {year}
                                        </p>
                                        <p
                                            className="white centred"
                                            dangerouslySetInnerHTML={{
                                                __html: lang.companyContactInformation.replace(
                                                    /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                    'https://seilnorge-images.imgix.net/images'
                                                ),
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const i18n = {
    nb_NO: {
        boatRentalHeading: 'Har du spørsmål eller kommentarer?',
        boatRentalEmail: 'mailto:flaskepost@seilnorge.no',
        contactUs: 'Kontakt oss',
        companyContactInformation:
            'Vågen, 8985 Ylvingen  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;  Tlf. (+47) 41 29 79 00',
    },
    en_US: {
        boatRentalHeading: 'Do you have questions or comments?',
        boatRentalEmail: 'mailto:post@sailnorway.com',
        contactUs: 'Contact us',
        companyContactInformation:
            'Vågen, 8985 Ylvingen  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;  Phone +47 41 29 79 00',
    },
};

function mapStateToProps(state) {
    return {
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(Footer);
