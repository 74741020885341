import * as _ from 'lodash';

export default function reducer(
    state = {
        fetching: false,
        fetched: false,
        fetchingFront: false,
        fetchedFront: false,
        fetchingTripData: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case 'FETCH_TRIP_START':
            return { ...state, fetching: true };
        case 'FETCH_TRIP_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_TRIP_FULFILLED':
            return { ...state, fetching: false, fetched: true };
        case 'FETCH_TRIP_BY_ID_START':
            return { ...state, fetching: true };
        case 'FETCH_TRIP_BY_ID_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_TRIP_BY_ID_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetched: true,
                trip: action.payload.data,
            };
        case 'FETCH_FRONT_TRIPS_START':
            return { ...state, fetchingFront: true };
        case 'FETCH_FRONT_TRIPS_REJECTED':
            return { ...state, fetchingFront: false, error: action.payload };
        case 'FETCH_FRONT_TRIPS_FULFILLED':
            return {
                ...state,
                fetchingFront: false,
                fetchedFront: true,
                front_trips: action.payload.data,
            };
        case 'FETCH_ALL_TRIPS_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_ALL_TOPIC_PAGES_REJECTED':
            return { ...state, fetching: false, error: action.payload };
        case 'FETCH_ALL_TOPIC_PAGES_FULFILLED':
            return {
                ...state,
                fetching: false,
                fetchedTopicPages: true,
                allTopicPages: action.payload.data,
            };
        default:
            return state;
    }
}
