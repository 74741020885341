import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { changeCalendarFilter } from '../../reducers/CalendarActions';

class CalendarFilter extends Component {
    handleChange(filterKey) {
        this.props.dispatch(changeCalendarFilter(filterKey));
        history.pushState('', '', '/turkalender/' + filterKey);
    }

    render() {
        const { locale, lang, activeFilter } = this.props;

        //console.log(this.props.activeFilter)
        //console.log(this.props.match.params.filter)

        return (
            <Row className="topic-pages-bar-container">
                <div className="topic-pages-bar">
                    <div className="topic-pages-inner-wrapper">
                        <ul>
                            {filters.map(filterKey => (
                                <li className="topic-page-title" key={filterKey}>
                                    <a
                                        style={{ fontSize: '20px' }}
                                        className={activeFilter === filterKey ? 'active' : ''}
                                        value={filterKey}
                                        onClick={this.handleChange.bind(this, filterKey)}
                                    >
                                        {lang[filterKey]}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Row>
        );
    }
}

const filters = [
    'Alle',
    'SkiSeil',
    'Sommer-Høst',
    'Vinter-Vår',
    'Ekspedisjon',
    'Trænafestivalen',
    'Seilkurs',
];

const i18n = {
    nb_NO: {
        Alle: 'Alle',
        SkiSeil: 'Ski & Seil',
        'Sommer-Høst': 'Sommer / Høst',
        'Vinter-Vår': 'Vinter / Vår',
        Ekspedisjon: 'Ekspedisjon',
        Trænafestivalen: 'Trænafestivalen',
        Seilkurs: 'Seilkurs',
    },
    en_US: {
        Alle: 'All',
        SkiSeil: 'Ski & Sail',
        'Sommer-Høst': 'Summer / Autumn',
        'Vinter-Vår': 'Winter / Spring',
        Ekspedisjon: 'Expedition',
        Trænafestivalen: 'Træna Festival',
        Seilkurs: 'Sailing courses',
    },
};

function mapStateToProps(state, ownProps) {
    return {
        locale: state.locale,
        lang: i18n[state.locale],
        activeFilter: state.calendar.activeFilter || ownProps.match.params.filter,
    };
}

export default connect(mapStateToProps, null)(CalendarFilter);
