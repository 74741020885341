import axios from 'axios';
import * as _ from 'lodash';

const API_URL = 'https://loopback.seilnorge.no/lambda';
export const LOOPBACK_URL = 'https://loopback.seilnorge.no/api';

export function fetchTrip(token, id, locale) {
    return function (dispatch) {
        return axios
            .get(API_URL + '/trips/' + id + '?locale=' + locale, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': token,
                },
            })
            .then(function (response) {
                if (
                    response &&
                    response.data &&
                    response.data.related_trips &&
                    _.isArray(response.data.related_trips)
                ) {
                    response.data.related_trips = response.data.related_trips.filter(
                        o => o !== null
                    );
                }
                dispatch({ type: 'FETCH_TRIP_BY_ID_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_TRIP_BY_ID_REJECTED', payload: error });
            });
    };
}

export function fetchFrontTrips(locale) {
    return function (dispatch) {
        return axios
            .get(API_URL + '/front?locale=' + locale, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_FRONT_TRIPS_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_FRONT_TRIPS_REJECTED', payload: error });
            });
    };
}

export function fetchAllTopicPages(token, locale) {
    return function (dispatch) {
        return axios
            .get(LOOPBACK_URL + '/topicPages/?preprocessLocale=' + locale, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': token,
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_ALL_TOPIC_PAGES_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_ALL_TOPIC_PAGES_REJECTED', payload: error });
            });
    };
}

export function changeFilter(filters) {
    return function (dispatch) {
        dispatch({ type: 'CHANGE_FILTER', payload: filters });
    };
}
