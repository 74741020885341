import * as _ from 'lodash';

export default function reducer(
    state = {
        fetching: false,
        fetched: false,
        error: null,
        booking: [],
        participantCompleted: false,
        bookingComplete: false,
        bookingFailed: false,
        chevronState: false,
        termsState: true,
        subscribeComplete: false,
    },
    action
) {
    let newState;

    switch (action.type) {
        case 'REQUEST_SUBMISSION_SUCCESS':
            return {
                ...state,
                requestSubmissionComplete: true,
            };
        case 'REQUEST_SUBMISSION_REJECTED':
            return {
                ...state,
                requestSubmissionComplete: false,
                error: action.payload,
            };
        case 'START_BUSY_SCREEN':
            return {
                ...state,
                busyScreenActive: true,
            };
        case 'STOP_BUSY_SCREEN':
            return {
                ...state,
                busyScreenActive: false,
            };
        default:
            return state;
    }
}
