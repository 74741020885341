import axios from 'axios';
import * as _ from 'lodash';

const API_URL = 'https://loopback.seilnorge.no/lambda';
const LOOPBACK_URL = 'https://loopback.seilnorge.no/api';

export function fetchBoat(id) {
    return function (dispatch) {
        const filter = {
            include: 'trips',
        };

        axios
            .get(
                LOOPBACK_URL +
                    '/boats/' +
                    id +
                    '?filter=' +
                    encodeURIComponent(JSON.stringify(filter)),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(function (response) {
                dispatch({ type: 'FETCH_BOAT_BY_ID_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                console.log('*************************');
                console.log(error);
                console.log('*************************');
                dispatch({ type: 'FETCH_BOAT_BY_ID_REJECTED', payload: error });
            });
    };
}
