import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

const i18n = {
    nb_NO: {
        header: 'Flaskepost',
        subHeader: 'Skriv inn din e-post og vi holder deg oppdatert med våre nyhetsbrev',
        yourEmail: 'Din e-post',
        send: 'Meld meg på',
    },
    en_US: {
        header: 'Bottlemail',
        subHeader: "Input your email adress and we'll keep you posted with our newsletters",
        yourEmail: 'Email',
        send: 'Sign me up',
    },
};

const requiredFactory = lang => {
    console.log('ze lang', lang);
    let msg;
    switch (lang) {
        default:
        case 'nb_NO':
            msg = 'Må fylles ut';
            break;
        case 'en_US':
            msg = 'Required';
            break;
    }

    return value => (value ? undefined : msg);
};
const emailRegex = /^[A-Z0-9a-z._%+-A-Z0-9a-z]+@[A-Z0-9a-z]{3,99}\.[A-Z0-9a-z]{2,4}/;
const properEmailFactory = lang => {
    let msg;
    switch (lang) {
        default:
        case 'nb_NO':
            msg = 'Ugyldig e-post';
            break;
        case 'en_US':
            msg = 'Invalid email';
            break;
    }

    return value => (value && !emailRegex.test(value) ? '' : undefined);
};

const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div className="form-group">
        <div>
            <input className="form-control" {...input} placeholder={label} type={type} />
            {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span className="warning">{warning}</span>))}
        </div>
    </div>
);

const Newsletter = ({
    addSubscriber,
    subscribeComplete,
    fields: { email },
    handleSubmit,
    locale,
}) => {
    console.log('locale is', locale);
    const lang = i18n[locale];
    // Required crashes the rendering without a real error.
    const required = useMemo(() => requiredFactory(locale), [locale]);
    const properEmail = useMemo(() => properEmailFactory(locale), [locale]);

    return (
        <div className="content-container">
            <h4 className="black">{lang.header}</h4>
            <p className="centred">{lang.subHeader}</p>
            <div className="form-inline">
                <form onSubmit={handleSubmit(addSubscriber)} className="form-bullet">
                    <Row>
                        <Col xs={7} sm={12} md={7}>
                            <Field
                                name="email"
                                label={lang.yourEmail}
                                type="email"
                                component={renderField}
                                validate={[required, properEmail]}
                            />
                        </Col>
                        <Col xs={5} sm={12} md={5}>
                            <Button type="submit">{lang.send}</Button>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
};

export default reduxForm({
    form: 'newsletter',
    fields: ['email'],
})(Newsletter);
