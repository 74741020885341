import React, {Component} from 'react';
import { Checkbox } from 'react-bootstrap';
import convertToNorwegianPrice from '../../helpers/ConvertToNorwegianPrice';
import { connect } from 'react-redux'
import { toggleTripOption } from '../../reducers/FormActions';


const mapDispatchToProps = {
    toggleTripOption
}

const mapStateToProps = (state) => ({
    trip: state.trip.trip,
    booking: state.booking.booking,
})

const i18n = {
    nb_NO: {        
        extraOptions: 'Tilleggsvalg',
    },
    en_US: {
        extraOptions: 'Optional extras',
    },
};


class TripOptions extends Component {
    render(){
        let trip = this.props.trip
        let booking = this.props.booking
        const lang = i18n[this.props.locale];

        if (trip.options && trip.options.length > 0) {
            return (
                <div>
                    <h4 className="black">{lang.extraOptions}</h4>

                    <table className="table">
                        <tbody>
                            {trip.options.map(option => (
                                <tr>
                                    <td>
                                        <div
                                            className="form-group"
                                            style={{ marginBottom: 0 }}
                                            key={option.id}
                                        >
                                            <Checkbox
                                                checked={
                                                    option.price == 0 ||
                                                    (
                                                    booking.options 
                                                    &&
                                                    booking.options.find(
                                                        o => o.id == option.id)
                                                    !==
                                                    undefined
                                                    )
                                                }
                                                onChange={e =>
                                                    this.props.toggleTripOption(option)
                                                }
                                                style={{ marginBottom: 0 }}
                                            >
                                                {option.name}
                                                {option.description && (
                                                    <span
                                                        className="help-block"
                                                        dangerouslySetInnerHTML={{
                                                            __html: option.description
                                                                .replace(
                                                                    new RegExp(/\n/, 'g'),
                                                                    '<br />'
                                                                )
                                                                .replace(
                                                                    /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                                    'https://seilnorge-images.imgix.net/images'
                                                                ),
                                                        }}
                                                    ></span>
                                                )}
                                            </Checkbox>
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        {convertToNorwegianPrice(option.price)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return '';
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripOptions);
