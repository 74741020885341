import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class CampaignCard extends Component {
    render() {
        let backgroundImage = {
            backgroundImage: ['url(' + this.props.image.replace('{size}', '_xl') + ')'],
        };

        const teaserClass = 'teaser ' + this.props.text_class;

        return (
            <Link to={this.props.button_link} className="card-link">
                <Row style={backgroundImage} className="no-gutter card-background large-card card">
                    <Col xs={12}>
                        <div className="content-container">
                            <p className={teaserClass}>{this.props.listing_intro}</p>
                            <h2 className={this.props.text_class}>{this.props.listing_header}</h2>
                            <div className="btn btn-default">{this.props.button_text}</div>
                        </div>
                    </Col>
                </Row>
            </Link>
        );
    }
}

export default CampaignCard;
