/**
 * Created by hajer on 17/08/17.
 */
import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import Header from '../common/Header';

import { fetchAllBlogs } from '../../reducers/BlogActions';
import { fetchAllCategoriesBlogs } from '../../reducers/BlogActions';
import { connect } from 'react-redux';
import { BlogCard } from './BlogCard';
import URLBuilder from '../../helpers/URLBuilder';

class FilteredBlogs extends Component {
    constructor() {
        super();
        this.state = {
            initialBlogArray: [],
            blogsToShow: [],
            lengthToShow: 3,
            btn: true,
        };
    }

    showMore() {
        this.setState({ lengthToShow: this.state.lengthToShow + 3 });
        this.state.blogsToShow = this.state.initialBlogArray.slice(0, this.state.lengthToShow);
        if (this.state.blogsToShow.length >= this.state.initialBlogArray.length) {
            this.setState({ btn: false });
        } else {
            this.setState({ btn: true });
        }
    }

    render() {
        const { token, fetchedBlogData, allblogs, filteredBlogs, locale } = this.props;

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        var mainArticle;
        var cardsArticle = [];
        if (fetchedBlogData && filteredBlogs === undefined) {
            if (this.props.filterText == '' || this.props.filterText == 'all') {
                this.state.initialBlogArray = allblogs || [];
                this.state.blogsToShow = this.state.initialBlogArray.slice(
                    0,
                    this.state.lengthToShow
                );

                if (this.state.blogsToShow.length >= this.state.initialBlogArray.length) {
                    this.state.btn = false;
                } else {
                    this.state.btn = true;
                }

                var blogsList = this.state.blogsToShow.map(blog => (
                    <BlogCard
                        key={'blogList-Allblogs' + blog.id + blog.tags}
                        image={'https://seilnorge-images.imgix.net/images/' + blog.imageS3Id}
                        blog={blog}
                        button_link={URLBuilder.blog(blog.id, blog.tags)}
                    />
                ));
            } else {
                for (var blog in allblogs) {
                    if (allblogs[blog].featuredCategoryPage) {
                        mainArticle = (
                            <Row>
                                <Col xs={6}>
                                    <div className="main-article">
                                        <h4>{allblogs[blog].slug} /</h4>
                                        <h5>{allblogs[blog].title}</h5>
                                        <p>{allblogs[blog].entryText}</p>
                                        <a
                                            className="card-link "
                                            href={URLBuilder.blog(
                                                allblogs[blog].id,
                                                allblogs[blog].tags
                                            )}
                                            target={URLBuilder.blog(
                                                allblogs[blog].id,
                                                allblogs[blog].tags
                                            )}
                                        >
                                            Les mer
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        );
                        for (var b = parseInt(blog) + 1; b < allblogs.length; b++) {
                            cardsArticle.push(allblogs[b]);
                        }
                        break;
                    } else {
                        cardsArticle.push(allblogs[blog]);
                    }
                }

                this.state.initialBlogArray = cardsArticle;
                this.state.blogsToShow = this.state.initialBlogArray.slice(
                    0,
                    this.state.lengthToShow
                );
                if (this.state.blogsToShow.length >= this.state.initialBlogArray.length) {
                    this.state.btn = false;
                } else {
                    this.state.btn = true;
                }

                var blogsList = this.state.blogsToShow.map(blog => (
                    <BlogCard
                        key={'blogList-Card' + blog.id + blog.tags}
                        image={'https://seilnorge-images.imgix.net/images/' + blog.imageS3Id}
                        blog={blog}
                        button_link={URLBuilder.blog(blog.id, blog.tags)}
                    />
                ));
            }
        }
        return (
            <Grid fluid={true} key="grid-filtered-blogs">
                <Row className="content" key="filteredBlogs">
                    {mainArticle}
                    <Row>
                        <Col xs={12}>{blogsList}</Col>
                    </Row>
                </Row>
                <Row className="load-more">
                    <a hidden={!this.state.btn} onClick={this.showMore.bind(this)}>
                        Load more
                    </a>
                </Row>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        fetchedBlogData: state.blog.fetchedBlogData,
        allblogs: state.blog.allblogs,
        filteredBlogs: state.blog.filteredBlogs,
        locale: state.locale,
    };
}

export default connect(mapStateToProps, null)(FilteredBlogs);
