import React from 'react';
import { hydrate } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { configureStore } from './js/reducers/Store';

const store = configureStore(window.__PRELOADED_STATE__);
const dehydratedReactQueryState = window.__REACT_QUERY_STATE__;

const queryClient = new QueryClient();

hydrate(
    // <StripeProvider apiKey="pk_live_K9xzufAjYl7fZRSdJPxou48u">
    <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedReactQueryState}>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </Hydrate>
    </QueryClientProvider>,
    // </StripeProvider>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}

// <!-- Hotjar Tracking Code for www.seilnorge.no -->
(function (h, o, t, j, a, r) {
    h.hj =
        h.hj ||
        function () {
            (h.hj.q = h.hj.q || []).push(arguments);
        };
    h._hjSettings = { hjid: 777433, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

// Google tagmanager
(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-NVLVQJP');

// This function will be called by Facebook SDK after load
window.fbAsyncInit = function () {
    /*FB.init({
    appId      : 'your-app-id',
    xfbml      : true,
    version    : 'v2.9'
  }); */
    // Dispatch document-wide 'fb_init' event so components can use it. Facebook's Page plugin is used in
    // the Facebook component, and uses this event to trigger Facebook SDK document parsing, which again
    // triggers drawing of the Page plugin
    document.dispatchEvent(new Event('fb_init'));
};
// Load Facebook SDK for JavaScript
(function (d, s, id) {
    var js,
        fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/nb_NO/sdk.js#xfbml=1&version=v2.9';
    fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'facebook-jssdk');
