import axios from 'axios/index';

const LOOPBACK_URL = 'https://loopback.seilnorge.no/api';

export function changeCalendarFilter(filter) {
    return function (dispatch) {
        dispatch({ type: 'CHANGE_FILTER', payload: filter });
    };
}

export function fethcAllProducts(locale) {
    return function (dispatch) {
        axios
            .get(LOOPBACK_URL + '/_special/loadTopicPageData/?locale=' + locale, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(function (response) {
                dispatch({ type: 'FETCH_ALL_PRODUCTS_FULFILLED', payload: response });
                dispatch({ type: 'SET_LOADED_STATE', payload: true });
            })
            .catch(function (error) {
                dispatch({ type: 'FETCH_ALL_PRODUCTS_REJECTED', payload: error });
            });
    };
}
