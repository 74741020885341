/**
 * Created by hajer on 07/09/17.
 */
import React, { Component } from 'react';
import HeaderFront from '../common/HeaderFront';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import Logo from '../gfx/Logo';
import { Carousel } from 'react-responsive-carousel';

export class SlideShow extends Component {
    render() {
        return (
            <Row>
                <Carousel
                    autoPlay={true}
                    emulateTouch={true}
                    showArrows={true}
                    width="100%"
                    infiniteLoop={true}
                    showStatus={false}
                >
                    {this.props.slides.map((slide, index) => (
                        <div key={index}>
                            <Row
                                style={{
                                    backgroundImage: ['url(' + slide.imageUrl + ')'],
                                }}
                                className="no-gutter card-background header-card card"
                            >
                                <Col xs={12} hidden={slide.caption.first}>
                                    <div className="blog-slideshow">
                                        <h5>{slide.caption.title}</h5>
                                        <p style={{ fontFamily: 'fabriga' }}>
                                            {slide.caption.description}
                                        </p>
                                        <a
                                            className="card-link "
                                            href={slide.caption.link}
                                            target={slide.caption.link}
                                        >
                                            Les mer
                                        </a>
                                    </div>
                                </Col>
                                <Logo hidden={slide.caption.first} />
                            </Row>
                        </div>
                    ))}
                </Carousel>
            </Row>
        );
    }
}
