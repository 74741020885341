import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import Helmet from 'react-helmet';
import striptags from 'striptags';

import Header from './common/Header';

import '../../css/article.css';

import { fetchArticle } from '../reducers/ArticleActions';
import { connect } from 'react-redux';

const no = `
      <p class="bold">Er du p&aring;meldt en av v&aring;re seilturer, kurs og ekspedisjoner, og klar for ditt livs eventyr? Og plutselig dukker det opp tusen sp&oslash;rsm&aring;l? Du f&aring;r svar p&aring; ihvertfall 999 av dem her. Her finner du informasjon om alt fra hvordan du pakker til alder p&aring; dine medseilere. Er det flere ting du lurer p&aring; etter &aring; ha lest svarene under er du alltid velkommen til &aring; sende oss en <a href="mailto:flaskepost@seilnorge.no">e-post!</a></p>
      <h4 class="black">Bestilling og betaling</h4>
      <p>Velkommen med p&aring; tur! For &aring; bestille en tur g&aring;r du inn p&aring; den enkelte tur, velger avreisedato (noen av turene v&aring;re har flere avreisedatoer) og fyller inn bestillingsskjemaet. Bestillingsskjemaet ber om grunnleggende informasjon. Kundedata blir ikke delt med eller solgt til en tredjepart og de oppbevares if&oslash;lge norsk lov. Etter &aring; ha fylt ut alle opplysninger blir du bedt om &aring; betale et depositum p&aring; 6000,- med bank- eller kredittkort. Noen kredittkort har gebyr, men de vanligste har ikke. Ca. 70 dager f&oslash;r avreise vil du motta sluttfaktura p&aring; e-post med restbel&oslash;pet. Denne fakturaen har en betalingsfrist p&aring; 10 dager. Hvis du melder deg p&aring; mindre enn 60 dager f&oslash;r turstart vil du f&aring; faktura p&aring; hele bel&oslash;pet med en gang.</p>
      <h4 class="black">Reise</h4>
      <p>Vi anbefaler alle v&aring;re deltagere &aring; reise mest mulig milj&oslash;vennlig. &Aring; ta tog eller kj&oslash;re flere i samme bil er da det beste. &Aring; reise langsomt gir ogs&aring; en annen dimensjon til ferien enn &aring; bare fly over alle de vakre stedene landet v&aring;rt har &aring; by p&aring;. Du finner spesifikke tips for reise under hver tur.</p>
      <h4 class="black">Mat og m&aring;ltider underveis</h4>
      <p>P&aring; v&aring;re turer bidrar alle i drift av b&aring;ten, og matlagingen g&aring;r p&aring; rundgang. I forkant av tur setter vi opp en meny. Har du allergier, s&aring; meld inn dette til oss i forkant og vi skal ta hensyn til det (eventuelt be deg handle inn ting du trenger). Underveis seiler vi av og til innom restauranter, brygger og spisesteder der vi f&aring;r servert lokale spesialiteter p&aring; bord dekket med duk og krystall! De aller fleste m&aring;ltidene er inkludert, og dette er markert i dag-for-dag-programmet med F - frokost, L - lunsj og M - middag.</p>
      <h4 class="black">V&aring;re reisevilk&aring;r</h4>
      <p>V&aring;re reisevilk&aring;r finner du her. Ved bestilling m&aring; du krysse av for at du har lest og akseptert reisevilk&aring;rene.</p>
      <h4 class="black">Utstyr</h4>
      <p>I god tid f&oslash;r avreise sender vi deg en utstyrsliste med all informasjon om det du trenger &aring; ta med p&aring; tur.</p>
      <h4 class="black">Reiseforsikring</h4>
      <p>Du m&aring; ha en gyldig reiseforsikring for &aring; bli med p&aring; tur, og denne b&oslash;r ogs&aring; dekke en eventuell avbestilling av tur. Les v&aring;re reisevilk&aring;r for avbestillingsregler og refusjon.</p>
      <h4 class="black">Hva slags folk er med p&aring; tur - og hvor mange er vi om bord?</h4>
      <p>Mange kommer alene, noen reiser som par, noen reiser som en vennegjeng. Fellesnevneren for alle er at dette er flotte mennesker du blir kjent med og blir venner med, som er nysgjerrige og ivrige etter &aring; l&aelig;re mer om omr&aring;det vi bes&oslash;ker. Og ikke minst l&aelig;re &aring; seile. Det &aring; dele storsl&aring;tte opplevelser skaper sterke b&aring;nd! Mange lurer p&aring; alderssammensetningen p&aring; v&aring;re turer, men det er ikke s&aring; viktig for oss. Det spiller ingen rolle om du er ung eller gammel s&aring; lenge du vil v&aelig;re med p&aring; tur og har tenkt &aring; gj&oslash;re ditt for at du selv og resten av mannskapet skal f&aring; en topp ferie. Antall deltagere varierer fra tur til tur. F&aring;r vi mange deltagere utvider vi med flere b&aring;ter og skippere. P&aring; Ski &amp; Seil er vi stort sett 1 til 2 b&aring;ter med 6 - 8 personer i hver b&aring;t. P&aring; turene til og fra Tr&aelig;na i forbindelse med festivalen, som er v&aring;re mest popul&aelig;re turer, er vi opptil 7 - 8 b&aring;ter.</p>
      <h4 class="black">Sikkerhet</h4>
      <p>Vi tar sikkerhet p&aring; alvor. P&aring; sj&oslash;en trener vi p&aring; h&aring;ndtering av ulike situasjoner som kan oppst&aring; p&aring; havet. Mann-over-bord, brann, forlat fart&oslash;y, mastehavari og grunnst&oslash;ting er noen av disse. &Aring; bygge deltagernes kompetanse p&aring; hvordan de skal h&aring;ndtere kritiske situasjoner er viktige elementer for bedre sikkerhet om bord. Om bord i b&aring;ten og i fjellet m&aring; alle vite hva de skal gj&oslash;re i potensielt farlige situasjoner. For oss er det viktig at du som deltager l&aelig;res opp raskt nok til &aring; kunne v&aelig;re deltaker om bord og ikke passasjer. Det bidrar til l&aelig;ring, mestring og &oslash;kt sikkerhet for b&aring;t og mannskap. Det er ogs&aring; derfor v&aring;re turer er for seilkurs &aring; regne og ikke en tur der man er en passiv passasjer.</p>
      <p>F&oslash;r Ski &amp; Seil-turene trener vi p&aring; og repeterer s&oslash;k og redningsteknikker, og bruk av skredutstyr. Mange elementer avgj&oslash;r sikkerheten p&aring; en topptur. God planlegging, ydmykhet i forhold til v&aelig;r og skredfare, og en samstemt gruppe p&aring; tur er viktig.</p>
      <p>F&oslash;r turstart vil du f&aring; tilsendt en sikkerhets- og risikoanalyse for b&aring;de ski og seiling. F&oslash;r turen vil vi ogs&aring; be deg om &aring; fylle ut et egenerkl&aelig;ringsskjema med informasjon om dine p&aring;r&oslash;rende i tilfelle noe skulle skje.</p>
      <p>V&aring;re skippere har alle mye seilerfaring, de er testet i ulike situasjoner, og de oppfyller Sj&oslash;fartsdirektoratets krav til sertifikater og kurs for v&aring;r type virksomhet.</p>
      <h4 class="black">Minimum antall p&aring;meldte: 5 personer</h4>
      <p>Har vi under fem p&aring;meldte p&aring; en tur avlyser vi og du vil f&aring; tilbud om &aring; v&aelig;re med p&aring; en annen tur, eller f&aring; pengene tilbake.</p>
      <h4 class="black">Sp&oslash;rsm&aring;l og svar</h4>
      <p class="bold">Jeg har sendt inn bestillingsskjema, men hvordan vet jeg at bestillingen har g&aring;tt igjennom?</p>
      <p>N&aring;r du sender inn bestillingen vil du f&aring; en automatisk e-post fra oss som enten bekrefter betalingen du nettopp har gjort med kredittkort, eller med faktura p&aring; depositum (hvis du huket av for det alternativet i p&aring;meldingsskjemaet). Vi vil v&aelig;re i jevnlig kontakt f&oslash;r avreise og sende ut aktuell informasjon p&aring; e-post, men har du noen sp&oslash;rsm&aring;l er det bare &aring; ta kontakt, enten det er et &aring;r - eller dagen f&oslash;r - avreise.</p>
      <p class="bold">Jeg har ikke f&aring;tt sluttfaktura enda, er det noe feil med min bestilling?</p>
      <p>Normalt f&aring;r du sluttfakturaen for turen 70 dager f&oslash;r avreise, men av og til rekker vi ikke &aring; gj&oslash;re dette akkurat 70 dager f&oslash;r. Alt er allikevel i orden med bestillingen din, og fakturaen er rett rundt hj&oslash;rnet. Dersom det er 50 dager igjen til avreise og du fortsatt ikke har mottatt fakturaen er det fint om du kontakter oss.</p>
      <p class="bold">N&aring;r f&aring;r jeg utstyrslisten for turen?</p>
      <p>Utstyrsliste f&aring;r du tilsendt sammen med det f&oslash;rste velkomstbrevet fra oss, kort tid etter p&aring;melding.</p>
      <p class="bold">Visum</p>
      <p>Selv om Nord-Norge for mange "s&oslash;ringa" kan v&aelig;re sv&aelig;rt eksotisk, trenger du ikke visum for &aring; reise til Lofoten eller Helgelandskysten. Men husk pass eller annet ID-kort hvis du skal v&aelig;re med p&aring; en av turene til Svalbard!</p>
      <p class="bold">Hva er Tr&aelig;nafestivalen?</p>
      <p>Har du ikke h&oslash;rt om Tr&aelig;nafestivalen? Det er helt greit, og, som de ville sagt p&aring; den andre siden av dammen: You're in for a treat! Tr&aelig;nafestivalen er flere ganger k&aring;ret til en av verdens beste festivaler, og kanskje den festivalen med de flotteste naturomgivelsene i hele verden. En helg i juli, langt ute i havgapet p&aring; Helgelandskysten blir det lille samfunnet forvandlet til en fantastisk musikkfestival, og du kan bare begynne &aring; glede deg!</p>
      <p class="bold">M&aring; jeg v&aelig;re i god form for &aring; reise med SeilNorge?</p>
      <p>Det &aring; st&aring; p&aring; dekk og styre en skute krever ikke all verdens fysikk, men aktivitetene vi gj&oslash;r p&aring; dagtid kan v&aelig;re fjellturer, kajakkpadling, surfing, fisking osv., og er du i grei form vil du f&aring; mer ut av de opplevelsene! Og s&aring; har jo helsa generelt godt av at du er i god form, s&aring; ...
`;

const en = `
<p dir="ltr"><strong>Are you already booked one of our trips, excursion or courses? Are you ready for the adventure of a lifetime? If you are left with a thousand questions, you&rsquo;ll find the answers to 999 of them here. The information below covers everything you need to know, from what to pack to the age of other participants. Should you have further questions, please feel free to send us an email.</strong></p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>Booking and payment</strong></p>
<p dir="ltr">Welcome! To book your trip, simple select your trip, choose a departure date (some of our trips have several departure dates) and fill in the form. The form will ask for basic information. Your information is confidential. We don&rsquo;t share information with any third parties. We ask for a deposit of 6000 NOK, payable with credit or debit card. Some credit cards charge a fee. You will receive a final invoice by email approximately 70 days before departure, which needs to be paid within 10 days. However, if you book a trip with less than 60 days, we will invoice you the full amount.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>Getting there</strong></p>
<p dir="ltr">We encourage use of environmentally friendly methods of transport. Train or carpooling is the best option. Travelling at a leisurely pace offers another dimension to your holiday, and gives you the opportunity to see more of our beautiful country. You&rsquo;ll find specific hints and tips under each trip.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>Food and meals</strong></p>
<p dir="ltr">Everyone contributes on-board, and cooking is a shared chore. Before we set sail we put together a menu. Be sure to let us know in advance if you suffer any allergies (you might need to bring some of your own provisions). Occasionally we moor up and nip into restaurants, piers and eateries to try local delicacies. Most meals are included, and have been specified in the day to day programme with the codes B &ndash; Breakfast, L &ndash; Lunch and D &ndash; Dinner.</p>
<p dir="ltr"><strong><br />Terms and Conditions</strong><br /><br />To complete your booking you&rsquo;ll need to agree to our terms and conditions.</p>
<p dir="ltr"><strong><br />Equipment</strong><br /><br />You will receive a full list of recommended equipment well in advance.</p>
<p dir="ltr"><strong><br />Insurance</strong><br /><br />You need valid travel insurance. Ideally one which covers cancellation. Please read our terms and conditions on cancellations and reimbursements.</p>
<p><strong><br />Who are the people on board, and how many on each boat?</strong><br /><br />A lot of people travel alone, some as a couple and some as a group of friends. Most importantly, they&rsquo;re a great bunch people, all curious and eager to learn how to sail and learn about the areas we visit. Sharing grand experiences likes these create great bonds and future friendships. Age is not important to us. All ages are welcome as long as you&rsquo;ll do your bit to contribute towards everyone having a great trip. Each trip is different, with different amounts of participants. We can always add more boats and more skippers. On our Ski &amp; Sail trips we normally have 1 &ndash; 2 boats with 6 - 8 people in each boat. On our most popular trip to Traena festival we can be as many as 7 &ndash; 8 boats.</p>
<p dir="ltr"><strong><br />Safety</strong></p>
<p dir="ltr">We are serious about safety! At sea we&rsquo;ll practice how to handle different situations. Amongst these; man-over-board, fire, abandon ship, dismasting and running aground. It&rsquo;s important to build knowledge, understanding and competency around critical situations. Our trips are centred on learning how to sail, so it&rsquo;s important that you are able to participate rather than being a passive passenger.</p>
<p dir="ltr">Before our Ski &amp; Sail trips we practice and repeat search and rescue techniques, including use of avalanche equipment. You will be required to fill in a declaration form with information of next of kind.</p>
<p dir="ltr">Our skippers are very experienced in every situation, and they meet the Norwegian Maritime Directorate's requirements.</p>
<p dir="ltr"><br /><strong>Minimum participants</strong><br />A trip will be canceled if there are less than 5 participants, and you will be offered the option of joining us on another trip or receiving a full refund.</p>
<p dir="ltr"><br /><strong>I&rsquo;ve booked a trip and submitted an order form. How do I know you have received it?</strong></p>
<p dir="ltr">You will receive an automatic response confirming your payment. We will be in regular contact with you and you will receive all relevant information by email ahead of time. However, feel free to contact us at any time.</p>
<p dir="ltr"><br /><strong>I haven&rsquo;t received my final invoice. Is there something wrong with my booking?</strong></p>
<p dir="ltr">You will normally receive the final invoice 70 days before departure, give or take. Your booking is still a&rsquo;ok and your invoice is imminent. However, should you not have received it within 50 days prior to departure, please do get in contact.</p>
<p dir="ltr"><strong><br />Visa/travel permit</strong></p>
<p dir="ltr">Northern Norway may seem exotic for those down south, but rest assured, you don&rsquo;t need a Visa to go to Lofoten or Helgelandskysten. For trips to Svalbard you will need to bring your passport or other valid international ID card.</p>
<p dir="ltr"><strong><br />What is the Traena festival?</strong></p>
<p dir="ltr">The festival has been voted one of the best music festivals in the world, surrounded by the most magnificent scenery. Every year, a weekend in July, a small community, far out at sea along the coast of Helgeland, is transformed into a fantastic music festival. This is one to look forward to!</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>Do I have to be physically fit to book with SeilNorge?</strong></p>
<p dir="ltr">Steering a sailing boat is not physically demanding. However, you may enjoy our other activities, like fishing, kayaking, surfing, hiking etc. more if you have a basic level of fitness.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>What is the minimum age limit?</strong></p>
<p>Minimum age is 14, and between 14-18 you will need to bring a parent/guardian. We are currently developing a family concept with no age limit.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>Can I contact the skipper?</strong></p>
<p dir="ltr">All our skippers work freelance, so any contact prior to departure goes through our offices. We can forward your details to the skipper if it&rsquo;s urgent you speak with him. Your skipper will receive all contact details regardless before departure.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>How do I cancel due to illness?</strong></p>
<p dir="ltr">Oh dear! We will need your cancellation in writing. Please read our terms and conditions and our rules on reimbursement. Contact your insurance company for reimbursements. You will need a doctor&rsquo;s notice and written confirmation from of what you paid. SeilNorge does not reimburse with less than 30 days to departure.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>I&rsquo;m travelling alone, will I be lonely?</strong></p>
<p dir="ltr">Our trips are very social, so if we can promise you one thing, it&rsquo;s that you won&rsquo;t be lonely. We travel in small groups of likeminded people. Almost 50% of our participants travel alone, and you&rsquo;ll quickly get to know the crew. On trips with several boats we make sure solo travelers are placed in the same boat.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>I haven&rsquo;t booked &lsquo;Komfypakka&rsquo;. What do I get then?</strong></p>
<p dir="ltr">We love the old Swedish sailor (80), Sven Yrvind, who sailed around the world in an 8 (eight) foot boat. He says; "Most people misunderstand life. Comfort does not make you happy. On the contrary it makes you lazy, fat and bored...".</p>
<p dir="ltr">During the summer we often use sporty boats, which are great speed and regattas. However, these have less space on board, no berths and no boiler. Without the &lsquo;komfypakka&rsquo; you&rsquo;ll need to bring your own sleeping bag. 50 % of our participants choose this option.</p>
<p dir="ltr">The &lsquo;komfypakka&rsquo; guarantees you space on board a boat with hot water and berths. The berths have duvets, pillows and bed linen. You&rsquo;ll share a berth with another participant. If you travel alone, you&rsquo;ll share with another participant of the same sex.</p>
<p dir="ltr">None of the above is relevant for our Ski &amp; Sail- and Svalbard trips, as we use our most comfortable boats.</p>
<p dir="ltr">&nbsp;</p>
<p dir="ltr"><strong>Can I bring a suitcase?</strong></p>
<p dir="ltr">Can you sit in a sauna with a down jacket? Ascend Mount Everest in Manolo Blahniks (designer shoes)? Serve whale meat to Paul Watson? Get our drift? You can - but avoid the suitcase! We&rsquo;d much prefer a rucksack or a duffel bag or anything which can be stowed away.</p>
`;

class ArticleDetail extends Component {
    componentDidMount() {
        if (!this.props.dataFetched) {
            this.props.dispatch(
                fetchArticle(this.props.token, this.props.match.params.id, this.props.locale)
            );
        }
    }

    render() {
        const { dataFetched, article } = this.props;

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        return (
            <Grid fluid={true}>
                <Helmet>
                    <title>{article.heading}</title>
                    <meta
                        property="og:image"
                        content={`https://seilnorge-images.imgix.net/images/${article.header_image.replace(
                            '{size}',
                            '_xl'
                        )}`}
                    />
                    <meta
                        property="og:description"
                        content={striptags(article.text).trim().split('\n')[0]}
                    />
                </Helmet>
                {dataFetched && (
                    <div>
                        <Header
                            image={s3BaseUrl + article.header_image.replace('{size}', '_xl')}
                            header={article.heading}
                        />
                        <Row className="content">
                            <Col xs={12} charSet="UTF-8">
                                <div className="row">
                                    <div
                                        className="col-xs-12 col-md-8 col-md-offset-2"
                                        dangerouslySetInnerHTML={{
                                            __html: article.text.replace(
                                                /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                'https://seilnorge-images.imgix.net/images'
                                            ),
                                        }}
                                    ></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        dataFetched: state.article.fetched,
        article: state.article.article,
        locale: state.locale,
        lang: i18n[state.locale],
    };
}

const i18n = {
    nb_NO: {
        header: 'Turkalender',
    },
    en_US: {
        header: 'Activity calendar',
    },
};

export default connect(mapStateToProps, null)(ArticleDetail);
