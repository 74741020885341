import React, { Component } from 'react';

class LogoEnglish extends Component {
    render() {
        return (
            <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 936.72 186.8">
                <title id="title">SailNorway Logo</title>
                <path
                    d="M186.51,81c-3.65-26.15-17.18-48.93-36.93-62.91A38.31,38.31,0,0,0,136.74,12c-4.88-2.6-23.12-12-31.79-12-9.6,0-13.16,5.05-16,8.53-2.31,2.86-3.9,6.91-.61,8.65a59.06,59.06,0,0,1,28.07,31.31,53.78,53.78,0,0,1,1.88,35.59A44.85,44.85,0,0,1,65.37,44a44.63,44.63,0,0,1,7.42-29.06,7.51,7.51,0,0,0,0-8.2h0a6.54,6.54,0,0,0-7.43-2.82,93.62,93.62,0,0,0,51.72,179.9c29.08-7.53,52.55-28.13,63.91-56.9A91.08,91.08,0,0,0,186.51,81ZM164.2,49.51a20.07,20.07,0,0,1,2.93,12.95,40.93,40.93,0,0,1-12,23.83,53.33,53.33,0,0,1-29.32,14.49,1.57,1.57,0,0,1-1.61-2.27,67.43,67.43,0,0,0-.53-60.81l0,0c-3.48-5.71-2.79-10,2.31-14.23,4-3.29,11.56-.66,17.34,3.43A75.48,75.48,0,0,1,164.2,49.51ZM100.66,11.89a8.32,8.32,0,0,1,4.28-1.17c1.72,0,4.7.88,8.22,2.21A4.29,4.29,0,0,1,115,19.52l-.06.07a2.93,2.93,0,0,1-4.44.3A67,67,0,0,0,100.66,11.89ZM54.7,45a55.54,55.54,0,0,0,56.22,50.58,1.56,1.56,0,0,1,1.43,2.33,57.61,57.61,0,0,1-8.83,11.25,13.9,13.9,0,0,1-11,3.88C71.5,111.2,58.46,100.76,51,91.7c-9-11-14.52-25.41-15.71-39.88A31.23,31.23,0,0,1,49.7,22.89q2.78-1.75,5.72-3.28a1.57,1.57,0,0,1,2.14,2A55.53,55.53,0,0,0,54.7,45ZM15.81,64.86a84,84,0,0,1,5.95-12.75,1.56,1.56,0,0,1,2.88.72c1.43,16.59,7.77,33.09,18.11,45.68a68.35,68.35,0,0,0,34.32,22.62,1.59,1.59,0,0,1-.07,3.06,56.72,56.72,0,0,1-46.53-8.69C14.46,104.27,9.06,83.2,15.81,64.86Zm74.91,75a42.82,42.82,0,0,1-24.9,22.47,25.55,25.55,0,0,1-24.15-4.61,82.42,82.42,0,0,1-26.24-37.15A1.41,1.41,0,0,1,17.68,119a68.12,68.12,0,0,0,42.13,17.54q1.75.09,3.49.09A67.79,67.79,0,0,0,112.81,115a8.77,8.77,0,0,1,6-2.78c16.87-.81,32.86-7.32,43.94-18.4a53.34,53.34,0,0,0,9.71-13.26,1.66,1.66,0,0,1,3.1.49,82.58,82.58,0,0,1,.89,14.08A43,43,0,0,1,165,124a65.9,65.9,0,0,1-23.2,15.35c-15.19,6.08-32.87,7.18-47.45-.72A2.61,2.61,0,0,0,90.71,139.82Zm23.7,33.62a82.06,82.06,0,0,1-40.39.19,1.57,1.57,0,0,1-.25-2.94,53.25,53.25,0,0,0,20.65-16.53,3.84,3.84,0,0,1,4.19-1.4c14.11,4.1,31.2,2.93,47.18-3.47a93.58,93.58,0,0,0,9.61-4.49c2.29-1.24,3.19.53,1.37,2.62A84.16,84.16,0,0,1,114.42,173.45Z"
                    transform="translate(0)"
                />
                <path
                    d="M130.29,36.88a6,6,0,0,0,5.13,3.26,5.92,5.92,0,0,0,3.08-.72,2.34,2.34,0,0,1,2.3,0l.12.07a34.38,34.38,0,0,1,4.48,3.19,2.41,2.41,0,0,0,3,0h0A2.43,2.43,0,0,0,149,39.5a40.05,40.05,0,0,0-3.91-5,40.51,40.51,0,0,0-3.11-3,30.4,30.4,0,0,0-3.72-2.73,5.46,5.46,0,0,0-4.25-.41A6,6,0,0,0,130.29,36.88Z"
                    transform="translate(0)"
                />
                <path
                    d="M276.49,51.09c16.69,0,31,6.09,32.46,23v1.72h-20.8c-.4-4.9-3.44-8.74-11-8.74S266,70.43,266,74.94c0,5.7,3.84,7.29,9.67,8.88,8.74,2.65,21.86,5,28.35,11.52a24.92,24.92,0,0,1,6.76,16.56v1.32c0,20.67-17.62,27.55-34.31,27.55-18,0-32.59-6.76-34.18-25v-1.85h21.33c.26,7.68,5.7,11,13.64,11,7.42,0,12.05-3.84,12.05-8.74,0-4.5-1.46-7.15-9-9.54-9.54-3.44-23.32-5.43-29.67-11.79a24.93,24.93,0,0,1-6.62-16V77.45C244,58,260.86,51.09,276.49,51.09Z"
                    transform="translate(0)"
                />
                <path
                    d="M341.53,140.77c-13.11,0-24.37-7.55-24.37-20.66v-1.46a18.23,18.23,0,0,1,5.7-13.38c4.9-5,14.31-7.95,24-7.95a42,42,0,0,1,11.66,1.46v-3c0-6.62-2.91-10.33-8.88-10.33-6.36,0-8.61,3.58-9,7.42v.27H320.73V91.36c0-13.91,14.57-20.8,28.08-20.8,9.8,0,17.88,1.59,23.71,7.55,4.64,4.64,6.36,11.26,6.36,19.61v41.07h-15.5l-2.52-7.68h-.26C357.16,137.06,350.14,140.77,341.53,140.77Zm17-25.7v-2.78a22.23,22.23,0,0,0-9.27-2.12c-5.7,0-11.26,1.85-11.26,7.95,0,4.77,3.71,6.76,9.14,6.76C351.86,124.88,358.49,121.57,358.49,115.07Z"
                    transform="translate(0)"
                />
                <path
                    d="M416.38,55.2c0,6.89-5.83,10.73-12.72,10.73s-12.72-3.84-12.72-10.73v-.93c0-6.89,5.7-11,12.72-11s12.72,4.11,12.72,11ZM393.2,138.79V72.55h20.93v66.23Z"
                    transform="translate(0)"
                />
                <path
                    d="M449.49,114.81c0,6.49,2.25,9,8.88,8.21H460v15.37a43.31,43.31,0,0,1-9.94,1.19c-13.25,0-21.33-5.83-21.33-21.06V46.06h20.8Z"
                    transform="translate(0)"
                />
                <path
                    d="M550.7,52.68v86.11H531.09l-26.76-35.37a213.92,213.92,0,0,1-11.79-19.08h-.26c.13,2.91.93,14.44.93,21.59v32.85H471.88V52.68h20.53L518,86.86c4.9,7.15,9.94,16.43,12.05,20h.26c-.13-2.91-.79-14.44-.79-22.78l-.13-31.4Z"
                    transform="translate(0)"
                />
                <path
                    d="M596.8,70.43c19.08,0,35.37,12.58,35.37,33.91v2.52c0,21.33-16.29,34-35.1,34-19.34,0-35.63-12.58-35.63-33.91v-2.52C561.43,83.15,577.72,70.43,596.8,70.43Zm0,53.12c9.27,0,13.51-7.95,13.51-17.75,0-10.07-4.24-18.15-13.51-18.15s-13.51,7.95-13.51,17.75C583.29,115.47,587.53,123.55,596.8,123.55Z"
                    transform="translate(0)"
                />
                <path
                    d="M662.51,138.79h-20.8V72.55h15.37l3.84,14h.4c.79-9.41,8.35-14.57,16.82-14.57a27.49,27.49,0,0,1,6.62.79V92.16h-8.48c-11.79,0-13.78,6.76-13.78,15.37Z"
                    transform="translate(0)"
                />
                <path
                    d="M776.43,72.55h19.34V74.8l-20.4,64H755.63L742.38,97.72,729,138.79H709.53L688.87,74.94V72.55h20.4L718,102.09a95.29,95.29,0,0,1,2.91,12.72h.26a115.45,115.45,0,0,1,2.65-12.58l8.48-29.67H753l8.74,29.54a95.29,95.29,0,0,1,2.91,12.72h.26a89.58,89.58,0,0,1,2.78-12.58Z"
                    transform="translate(0)"
                />
                <path
                    d="M822.53,140.77c-13.11,0-24.37-7.55-24.37-20.66v-1.46a18.22,18.22,0,0,1,5.7-13.38c4.9-5,14.31-7.95,24-7.95a42,42,0,0,1,11.66,1.46v-3c0-6.62-2.91-10.33-8.88-10.33-6.36,0-8.61,3.58-9,7.42v.27H801.73V91.36c0-13.91,14.57-20.8,28.08-20.8,9.8,0,17.88,1.59,23.71,7.55,4.64,4.64,6.36,11.26,6.36,19.61v41.07h-15.5l-2.52-7.68h-.26C838.16,137.06,831.14,140.77,822.53,140.77Zm17-25.7v-2.78a22.23,22.23,0,0,0-9.27-2.12c-5.7,0-11.26,1.85-11.26,7.95,0,4.77,3.71,6.76,9.14,6.76C832.86,124.88,839.49,121.57,839.49,115.07Z"
                    transform="translate(0)"
                />
                <path
                    d="M901.75,117.06a116.93,116.93,0,0,1,3.84-14.17l10.33-30.34h20.8V74.8l-36.3,87.83H878.17v-2.12l11.66-24.9L864.66,74.8V72.55h22.12l10.6,30.34a110.49,110.49,0,0,1,4.11,14.17Z"
                    transform="translate(0)"
                />
            </svg>
        );
    }
}

export default LogoEnglish;
