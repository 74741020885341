import he from 'he';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import striptags from 'striptags';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import '../../../css/image-gallery.css';
import '../../../css/trip-detail.css';
import { fetchAllTopicPages } from '../../reducers/TripActions';
import Header from '../common/Header';
import FilteredProducts from './FilteredProducts.js';
import FilterProducts from './FilterProducts.js';

class AllProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chevronStateProgram: false,
            chevronStateIncluded: false,
            chevronStateInfo: false,
        };
    }

    componentDidMount() {
        const { token, fetchedTopicPages, allTopicPages, locale, lang } = this.props;

        if (!fetchedTopicPages) {
            this.props.dispatch(fetchAllTopicPages(token, locale));
        }
    }

    componentDidUpdate() {
        const { token, tripDataFetched, fetchedTopicPages, allTopicPages, locale, lang } =
            this.props;

        if (!fetchedTopicPages) {
            this.props.dispatch(fetchAllTopicPages(token, locale));
        }
    }

    render() {
        const { allTopicPages, lang } = this.props;

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        let coverImage = s3BaseUrl + 'header_turer_xl.jpg';
        let coverHeader = lang.headerText;
        let topicHtml = null;

        let filterText = this.props.match.params.filter;

        if (allTopicPages && allTopicPages.length > 0) {
            const currentTopic = allTopicPages.find(
                topic => preprocessFilterText(topic.keyword) == filterText
            );
            if (currentTopic) {
                coverImage = s3BaseUrl + currentTopic.coverImageS3Id;
                coverHeader = currentTopic.title;
                topicHtml = currentTopic.mainHtml;
                topicHtml = topicHtml
                    .replace(/(#000000)/gi, '__OLD_BLACK__')
                    .replace(/(#FFFFFF)/gi, '__OLD_WHITE__')
                    .replace(/(__OLD_BLACK__)/g, '#FFFFFF')
                    .replace(/(__OLD_WHITE__)/g, '#000000');
            }
        }

        return (
            <Grid fluid={true}>
                <Helmet>
                    <title>SeilNorge | {coverHeader}</title>
                    <meta property="og:title" content={coverHeader} />
                    <meta property="og:image" content={coverImage.replace('{size}', '_xl')} />
                    <meta
                        property="og:description"
                        content={striptags(he.decode(topicHtml || '')).trim()}
                    />
                </Helmet>
                <Header image={coverImage} header={coverHeader} />
                <FilterProducts />

                {topicHtml && (
                    <div
                        style={{
                            color: 'white',
                            background: 'black',
                            textAlign: 'center',
                            padding: '45px 20px',
                            marginLeft: '-30px',
                            marginRight: '-30px',
                        }}
                        dangerouslySetInnerHTML={{
                            __html: topicHtml.replace(
                                /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                'https://seilnorge-images.imgix.net/images'
                            ),
                        }}
                    ></div>
                )}

                <FilteredProducts filter={preprocessFilterText(filterText)} />
            </Grid>
        );
    }
}

const i18n = {
    nb_NO: {
        headerText: 'Sett toppseil!',
        buttonTextSkiSail: 'Ski & Seil',
        buttonTextSummerAutumn: '',
        buttonTextWinterSpring: '',
        buttonTextExpeditions: '',
        buttonTextSailngCourses: '',
    },
    en_US: {
        headerText: '',
        buttonTextSkiSail: '',
        buttonTextSummerAutumn: '',
        buttonTextWinterSpring: '',
        buttonTextExpeditions: '',
        buttonTextSailngCourses: '',
    },
};

/**
 * Manual override of a filter text value added on special request 27 Sep 2017 (see SEIL-22 in ServiceDesk)
 * since Seilnorge doesn't like URLs such as http://localhost:3000/alltrips/Ski%20&%20Seil
 */
export function preprocessFilterText(filterText) {
    if (filterText == 'Ski&Seil') filterText = 'Ski & Seil';
    return filterText;
}

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        fetchedTopicPages: state.trip.fetchedTopicPages,
        trip: state.trip,
        allTopicPages: state.trip.allTopicPages,
        filter: state.trip.filters,
        locale: state.locale,
        lang: i18n[state.locale],
    };
}

AllProducts.propTypes = {
    trips: PropTypes.arrayOf(PropTypes.object),
};
export default connect(mapStateToProps, null)(AllProducts);
