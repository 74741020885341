//Warning! Here there be dragons
//Do not spend time refactoring this code, it is a waist of time. 
//Rewrite functionality elsewhere and make this component redundant. 

import React, { Component, useCallback, useMemo } from 'react';
import { Row, Col, Button, Checkbox, Clearfix } from 'react-bootstrap';
import { Field, formValues, reduxForm } from 'redux-form';
// import {Elements, CardElement, injectStripe} from 'react-stripe-elements';
import PaymentSummary from './PaymentSummary';
import { deprecatedGetStoreState } from '../../reducers/Store';
import Rx from 'rxjs/Rx';
import axios from 'axios';
import convertToNorwegianPrice from '../../helpers/ConvertToNorwegianPrice';
import moment from 'moment';
import 'moment/locale/nb';
import TripBookingConfig from '../../../json/TripBookingConfig.json';

const API_URL = 'https://loopback.seilnorge.no';

const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div className="form-group">
        <label>{label}</label>
        <div>
            <input className="form-control" {...input} placeholder={label} type={type} />
            {touched &&
                ((error && <span className="error">{error}</span>) ||
                    (warning && <span className="warning">{warning}</span>))}
        </div>
    </div>
);

const i18n = {
    nb_NO: {
        required: 'Må fylles ut',
        maxLengthPrefix: 'Må være',
        maxLengthPostfix: 'tegn eller færre',
        minLengthPrefix: 'Må være minst',
        minLengthPostfix: 'tegn',
        minYear: 'Kan ikke være tidligere enn i år',
        requiredNumber: 'Må være tall',
        invalidEmail: 'Ugyldig e-post',
        headingPayment: 'Betaling',
        subHeading: 'Nå er det like før vi kan heise seil',
        headingPaymentInformation: 'Betalingsinformasjon',
        paymentType: 'Betalingstype',
        paymentTypeCard: 'Kort',
        paymentTypeInvoice: 'Faktura',
        cardDetails: 'Kortdetaljer',
        postalCode: 'Postnummer',
        nameOnCard: 'Navn på kortholder',
        extraOptions: 'Tilleggsvalg',
        summary: 'Sammendrag',
        description: 'Beskrivelse',
        basePrice: 'Grunnpris',
        orderTotal: 'Totalt',
        depositPayableNow: 'Depositum å betale nå',
        payableNow: 'Totalt å betale nå',
        remainingAmountDue60DaysBeforeDeparture: 'Restbeløp å betale 60 dager før avreise',
        fieldName: 'Navn på kortholder',
        payWithInvoiceClickHere: 'Betale med faktura? Klikk her',
        payButtonText: 'Betal',
        processing: 'Behandler...',
        errorOccuredMessageGeneral: 'En feil oppstod: ',
        errorOccuredMessageStripe:
            'En feil oppstod, vennligst prøv igjen. Vi mottok følgende feil ved behandling av kortet ditt: ',
    },
    en_US: {
        required: 'Required',
        maxLengthPrefix: 'Must be',
        maxLengthPostfix: 'characters or less',
        minLengthPrefix: 'Must be at least',
        minLengthPostfix: 'characters',
        minYear: 'Can not be earlier than the current year',
        requiredNumber: 'Must be a number',
        invalidEmail: 'Invalid email',
        headingPayment: 'Payment',
        subHeading: 'Thank you, we are soon ready to set sail',
        headingPaymentInformation: 'Payment details',
        paymentType: 'Payment Options',
        paymentTypeCard: 'Credit or Debit Card',
        paymentTypeInvoice: 'Invoice by email',
        cardDetails: 'Card details',
        postalCode: 'Post/ZIP code',
        nameOnCard: 'Name on card',
        extraOptions: 'Optional extras',
        summary: 'Order summary',
        description: 'Description',
        basePrice: 'Sum',
        orderTotal: 'Order total',
        depositPayableNow: 'Deposit, payable now',
        payableNow: 'Payable now',
        remainingAmountDue60DaysBeforeDeparture: 'The remainder, due 60 days before departure',
        fieldName: 'Navn på kortholder',
        payWithInvoiceClickHere: 'Pay by invoice? Click here',
        payButtonText: 'Book trip',
        processing: 'Processing...',
        errorOccuredMessageGeneral:
            'Oops! Something went wrong, please try again. We received the following error message: ',
        errorOccuredMessageStripe:
            'Oops! Something went wrong, please try again. We received the following error message processing your payment: ',
    },
};

class BookingFormPayment extends Component {
    constructor() {
        super();
        this.state = {
            stripeElementsError: false,
            stripeElementsEmpty: true,
            busy: true,
        };
        this.doRetrieveServerTime();
    }

    doRetrieveServerTime() {
        Rx.Observable.fromPromise(axios.get(API_URL + '/serverTime'))
            .map(resp => resp.data)
            .subscribe(
                serverTime => {
                    this.setState({ serverTime });
                },
                err => console.log(err) || this.setState({ busy: false }),
                () => this.setState({ busy: false })
            );
    }

    doRetrieveCardFundingType() {
        // On blur, create a Source in Stripe solely to check the {card.funding} value in the result. Then, use this
        // to correctly set the corresponding card fee (as of 14oct2017, NOK 200 for debit/prepaid, NOk 400 for credit)
        this.setState({
            busy: true,
        });
        this.props.stripe.createSource().then(result => {
            if (!result.error) {
                const cardFundingType = result.source.card.funding;
                const three_d_secure = result.source.card.three_d_secure;
                const stripeSourceId = result.source.id;
                const card = result.source.card;
                this.setState({
                    card,
                    cardFundingType,
                    three_d_secure,
                    stripeSourceId,
                });
            }
            this.setState({
                busy: false,
            });
        });
    }

    handleSubmit(event) {

        this.setState({
            busy: true,
        });

        const { handleSubmit, doCardPayment, doInvoicePayment } = this.props;

        event.preventDefault();

        if (this.props.paymentType == 'invoice') {
            this.handleInvoicePayment();
        }

        return false;
    }

    handleInvoicePayment() {
        const { handleSubmit, doCardPayment, doInvoicePayment, locale } = this.props;
        //console.log("doInvoicePayment:", doInvoicePayment)
        //console.log("handleSubmit", handleSubmit)

        let booking = deprecatedGetStoreState().booking.booking;
        //console.log("booking", booking)

        Rx.Observable.fromPromise(createOrder(booking, 'invoice')).subscribe(
            result => {
                this.setState({
                    busy: false,
                });

                if (result.data.clientAction && result.data.clientAction.action == 'redirect') {
                    window.location.href = result.data.clientAction.url;
                    return;
                }
                if (result.error && result.error && result.error.message) {
                    alert(
                        i18n[this.props.locale].errorOccuredMessageGeneral + result.error.message
                    );
                } else {
                    handleSubmit(doInvoicePayment)(event);
                }
            },
            error => {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.error &&
                    error.response.data.error.message
                ) {
                    alert(
                        i18n[this.props.locale].errorOccuredMessageStripe +
                            error.response.data.error.message
                    );
                    this.setState({
                        busy: false,
                    });
                }
            }
        );
    }

    calculatePayableNow() {
        const {
            trip: { trip },
            booking,
        } = deprecatedGetStoreState();

        const now = moment(this.state.serverTime);
        const departure = moment(trip.departure);
        const threshold = departure
            .hours(3)
            .subtract(TripBookingConfig.depositDaysThreshold, 'days');

        const isBeforeThreshold = now.isBefore(threshold);

        if (isBeforeThreshold) {
            return trip.deposit;
        } else {
            let tripPrice = trip.price;
            let cardFee = null;
            switch (this.state.cardFundingType) {
                case 'debit':
                    cardFee = TripBookingConfig.debitCardFee;
                    break;

                case 'credit':
                    cardFee = TripBookingConfig.creditCardFee;
                    break;

                case 'prepaid':
                    cardFee = TripBookingConfig.prepaidCardFee;
                    break;
            }

            const totalPrice =
                tripPrice + cardFee + booking.booking.options.reduce((sum, o) => sum + o.price, 0);

            return totalPrice;
        }
    }

    render() {
        const {
            doCardPayment,
            doInvoicePayment,
            fields: { name, cardNumber, expiryMonth, expiryYear, cvc },
            paymentType,
            handleSubmit,
            locale,
        } = this.props;
        const { booking, trip } = deprecatedGetStoreState();

        const lang = i18n[locale];

        const cardElementOptions = {
            hidePostalCode: true,
            style: {
                base: {},
            },
            onChange: event => {
                this.setState({
                    stripeElementsEmpty: event.empty,
                    stripeElementsError: !!event.error,
                });
            },
            onBlur: event => {
                this.doRetrieveCardFundingType();
            },
        };

        let paymentTypeError;
        if (this.state.showPaymentTypeError) {
            paymentTypeError = <span className="error">Må fylles ut</span>;
        }

        return (
            <Row className="content">
                <Col xs={12} sm={6} smOffset={3}>
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div>
                            {this.props.paymentType == 'card' && (
                                <div>
                                    <label
                                        style={{ width: '100%' }}
                                        className="form-group payment-form-card-element-wrapper"
                                    >
                                        {lang.cardDetails}
                                    </label>

                                    <style
                                        dangerouslySetInnerHTML={{
                                            __html: `
                      .payment-form-card-element-wrapper .StripeElement {
                        background-color: #fbfbfb;
                        padding: 10px;
                        border: 1px solid #979797;
                      }
`,
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        {this.renderOptions()}

                        <PaymentSummary
                            trip={trip}
                            booking={booking}
                            paymentType={paymentType}
                            serverTime={this.state.serverTime}
                            locale={locale}
                            cardFundingType={this.state.cardFundingType}
                        />

                        <Button type="submit" className="orange pull-right">
                            {lang.payButtonText}
                        </Button>
                    </form>
                </Col>

                {this.state.busy && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            background: 'rgba(0,0,0,0.7)',
                            zIndex: 9999999,
                        }}
                    >
                        <h3 style={{ position: 'absolute', left: '45%', top: '50%' }}>
                            {lang.processing}
                        </h3>
                    </div>
                )}
            </Row>
        );
    }

    renderOptions() {
        const {
            trip: { trip },
            booking: { booking },
        } = deprecatedGetStoreState();
        const toggleTripOption = this.props.toggleTripOption;
        const lang = i18n[this.props.locale];

        if (trip.options && trip.options.length > 0) {
            return (
                <div>
                    <h4 className="black">{lang.extraOptions}</h4>

                    <table className="table">
                        <tbody>
                            {trip.options.map(option => (
                                <tr>
                                    <td>
                                        <div
                                            className="form-group"
                                            style={{ marginBottom: 0 }}
                                            key={option.id}
                                        >
                                            <Checkbox
                                                checked={
                                                    option.price == 0 ||
                                                    (booking.options &&
                                                        booking.options.find(
                                                            o => o.id == option.id
                                                        ) !== undefined)
                                                }
                                                onChange={e =>
                                                    toggleTripOption(option) || this.forceUpdate()
                                                }
                                                disabled={option.price == 0}
                                                style={{ marginBottom: 0 }}
                                            >
                                                {option.name}
                                                {option.description && (
                                                    <span
                                                        className="help-block"
                                                        dangerouslySetInnerHTML={{
                                                            __html: option.description
                                                                .replace(
                                                                    new RegExp(/\n/, 'g'),
                                                                    '<br />'
                                                                )
                                                                .replace(
                                                                    /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                                    'https://seilnorge-images.imgix.net/images'
                                                                ),
                                                        }}
                                                    ></span>
                                                )}
                                            </Checkbox>
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        {convertToNorwegianPrice(option.price)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return '';
        }
    }
}

const DecoratedForm = formValues('paymentType')(BookingFormPayment);

const ReduxedForm = reduxForm({
    form: 'payment',
    fields: ['name', 'postalCode'],
    initialValues: {
        paymentType: 'invoice',
    },
})(DecoratedForm);


class BookingFormPaymentWrapper extends Component {
    render() {
        return (
            <div>
                <ReduxedForm {...this.props} />
            </div>
        );
    }
}

export default BookingFormPaymentWrapper;

function createOrder(booking, type = 'card') {
    return axios.post(API_URL + '/api/orders', { type, booking, status: 'pending' });
}
