import React, { Component } from 'react';

class Utsolgt extends Component {
    render() {
        const background = { fill: '#FF6600' };
        const text = { fill: '#FFFFFF' };

        return (
            <svg
                className="soldout-banner-gfx"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 50"
            >
                <title id="title">Utsolgt banner</title>

                <rect style={background} width="500" height="50" />
                <path
                    style={text}
                    d="M199.43,34.17c-5,0-7.58-2.51-7.58-7.12V16.23h4.51V27.75c0,1.83,1.27,2.72,3.18,2.72,1.65,0,3-.89,3-2.72V16.23H207V27.05C207,31.66,204.37,34.17,199.43,34.17Z"
                />
                <path style={text} d="M209.49,16.23h14.24v3.59h-4.8V33.77h-4.45V19.82h-5Z" />
                <path
                    style={text}
                    d="M231.82,15.91c3.4,0,6.31,1.24,6.61,4.69V21H234.2c-.08-1-.7-1.78-2.24-1.78s-2.27.67-2.27,1.59c0,1.16.78,1.48,2,1.81,1.78.54,4.45,1,5.77,2.35a5.07,5.07,0,0,1,1.38,3.37v.27c0,4.21-3.59,5.61-7,5.61-3.67,0-6.64-1.38-7-5.1V28.7h4.34c.05,1.56,1.16,2.24,2.78,2.24s2.45-.78,2.45-1.78-.3-1.46-1.83-1.94c-1.94-.7-4.75-1.11-6-2.4a5.08,5.08,0,0,1-1.35-3.26v-.27C225.21,17.31,228.64,15.91,231.82,15.91Z"
                />
                <path
                    style={text}
                    d="M249.92,15.83c5.18,0,9.25,3.59,9.25,8.85v.65c0,5.26-4,8.85-9.25,8.85s-9.25-3.59-9.25-8.85v-.65C240.67,19.42,244.74,15.83,249.92,15.83Zm0,14.59c3,0,4.61-2.4,4.61-5.42s-1.51-5.42-4.61-5.42S245.33,22,245.33,25,247,30.42,249.89,30.42Z"
                />
                <path style={text} d="M261.95,16.23h4.45v14h7.42v3.53H261.95Z" />
                <path
                    style={text}
                    d="M284.2,34.17c-5.75,0-9.44-3.8-9.44-8.85v-.65c0-5.29,4-8.85,9.2-8.85,4.42,0,7.47,1.65,8.07,5.61v.35h-4.34c-.27-1.57-1.62-2.27-3.59-2.27-3.18,0-4.75,2.1-4.75,5.5,0,3.1,1.86,5.53,5.26,5.53a6.2,6.2,0,0,0,3.86-1.21V27.62H284V24.06h8.47v6.56A10.39,10.39,0,0,1,284.2,34.17Z"
                />
                <path style={text} d="M293.91,16.23h14.24v3.59h-4.8V33.77H298.9V19.82h-5Z" />
            </svg>
        );
    }
}

export default Utsolgt;
