/**
 * Created by Shems on 29/03/2017.
 */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { LOOPBACK_URL } from '../../reducers/TripActions';

import TripCard from '../trip/TripCard';
import ExperienceCard from '../experience/ExperienceCard';
import BoatCard from '../boat/BoatCard';

import { useSelector } from 'react-redux';

import URLBuilder from '../../helpers/URLBuilder';
import axios from 'axios';

export async function fetchTrips(locale, token) {
    let res = await axios.get(LOOPBACK_URL + '/_special/loadTopicPageData?locale=' + locale, {
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': token,
        },
    });

    return res.data;
}

const FilteredProducts = ({ filter }) => {
    const locale = useSelector(state => state.locale);
    const token = useSelector(state => state.cognito.apiToken);
    const lang = i18n[locale];

    const trips = useQuery('all_trips_products', () => fetchTrips(locale, token));

    if (trips.error) {
        return <div>{trips.error.toString()}</div>;
    }

    if (trips.isLoading) {
        return (
            <div>
                <Loader type="TailSpin" color="#FF6600" height={100} width={100} />
            </div>
        );
    }

    let filteredTrips;
    const searchArray = filter.split('-');
    if (searchArray.length === 2) {
        const filteredTrips1 = trips.data.filter(
            trip => trip.keywords.indexOf(searchArray[0]) !== -1
        );
        const filteredTrips2 = trips.data.filter(
            trip => trip.keywords.indexOf(searchArray[1]) !== -1
        );
        filteredTrips = filteredTrips1.concat(filteredTrips2);
    } else {
        filteredTrips = trips.data.filter(trip => trip.keywords.indexOf(filter) !== -1);
    }
    filteredTrips = _.uniqBy(filteredTrips, trip => trip.trip_id);

    const s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

    let xtrips = filteredTrips.map((product, index) => {
        switch (product.type) {
            case 'Trip':
                return (
                    <TripCard
                        key={product.trip_id}
                        image={s3BaseUrl + product.listing_image}
                        listing_intro={product.listing_intro}
                        listing_header={product.listing_heading}
                        button_link={URLBuilder.trip(product.trip_id, product.slug)}
                        button_text={lang.buttonTextReadMore}
                        trip_card_index={index}
                        trip_card_count={filteredTrips.length}
                    />
                );

            case 'Boat':
                return (
                    <BoatCard
                        key={product.trip_id}
                        image={s3BaseUrl + product.listing_image}
                        listing_intro={product.listing_intro}
                        listing_header={product.listing_heading}
                        button_link={URLBuilder.boat(product.trip_id, product.slug)}
                        button_text={lang.buttonTextReadMore}
                        trip_card_index={index}
                        trip_card_count={filteredTrips.length}
                    />
                );

            case 'Experience':
                return (
                    <ExperienceCard
                        key={product.trip_id}
                        image={s3BaseUrl + product.listing_image}
                        listing_intro={product.listing_intro}
                        listing_header={product.listing_heading}
                        button_link={URLBuilder.experience(product.trip_id, product.slug)}
                        button_text={lang.buttonTextReadMore}
                        trip_card_index={index}
                        trip_card_count={filteredTrips.length}
                    />
                );
        }
    });

    return (
        <Row>
            <Col xs={12}>
                <Row className="no-gutter">{xtrips}</Row>
            </Col>
        </Row>
    );
};

const i18n = {
    nb_NO: {
        buttonTextReadMore: 'Les mer',
    },
    en_US: {
        buttonTextReadMore: 'Read more',
    },
};

export default FilteredProducts;
