import * as _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { Grid, Row, Col, Clearfix, Glyphicon, PanelGroup, Panel } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom';

import LineHorisontal from '../common/LineHorisontal';
import Quote from './Quote';
import HeaderSeparator from '../common/HeaderSeparator';
import TripCard from '../trip/TripCard';

import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import '../../../css/image-gallery.css';
import '../../../css/trip-detail.css';

import { fetchBoat } from '../../reducers/BoatActions';
import { setLoadedState } from '../../reducers/ContentActions';
import { connect } from 'react-redux';

import URLBuilder from '../../helpers/URLBuilder';
import Utsolgt from '../gfx/Utsolgt';
import UtsolgtEnglish from '../gfx/UtsolgtEnglish';

class BoatDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chevronStateProgram: false,
            chevronStateIncluded: false,
            chevronStateInfo: false,
        };
    }

    componentDidMount() {
        if (!this.props.dataFetched) {
            this.props.dispatch(fetchBoat(this.props.match.params.id));
        } else {
            this.props.dispatch(setLoadedState(true));
        }
    }

    componentDidUpdate() {
        const { match, boat, token, dataFetched, locale } = this.props;

        if (!this.props.dataFetched) {
            this.props.dispatch(fetchBoat(this.props.match.params.id));
        }

        moment.locale(locale);

        if (dataFetched) {
            if (match.params.id != boat.id) {
                this.props.dispatch(fetchBoat(token, match.params.id, locale));
                window.scrollTo(0, 0);
            } else {
                this.props.dispatch(setLoadedState(true));
            }
        }
    }

    toggleChevron(event) {
        var newChevronStateProgram;
        var newChevronStateIncluded;
        var newChevronStateInfo;

        switch (event) {
            case '1':
                newChevronStateProgram = !this.state.chevronStateProgram;
                newChevronStateIncluded = false;
                newChevronStateInfo = false;
                break;
            case '2':
                newChevronStateProgram = false;
                newChevronStateIncluded = !this.state.chevronStateIncluded;
                newChevronStateInfo = false;
                break;
            case '3':
                newChevronStateProgram = false;
                newChevronStateIncluded = false;
                newChevronStateInfo = !this.state.chevronStateInfo;
                break;
            default:
        }

        this.setState({
            chevronStateProgram: newChevronStateProgram,
            chevronStateIncluded: newChevronStateIncluded,
            chevronStateInfo: newChevronStateInfo,
        });
    }

    convertToLocalPrice(price, locale) {
        const originalPrice = String(price);
        const priceThousands = originalPrice.slice(0, originalPrice.length - 3);
        const priceHundreds = originalPrice.slice(originalPrice.length - 3, originalPrice.length);

        switch (locale) {
            case 'en_US':
                return priceThousands + ',' + priceHundreds;
            default:
                return priceThousands + '.' + priceHundreds + ',-';
        }
    }

    createImageList(images, locale) {
        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        const imageList = images.map(function (image) {
            return {
                original: s3BaseUrl + image.s3Id.replace('{size}', '_sm'),
                srcSet:
                    s3BaseUrl +
                    image.s3Id.replace('{size}', '_md') +
                    ' 768w, ' +
                    s3BaseUrl +
                    image.s3Id.replace('{size}', '_lg') +
                    ' 992w, ' +
                    s3BaseUrl +
                    image.s3Id.replace('{size}', '_xl') +
                    ' 1200w',
            };
        });

        return imageList;
    }

    render() {
        console.log('render');
        console.log(this.props);

        const { dataFetched, boat, locale, lang } = this.props;

        var chevronIconProgram = this.state.chevronStateProgram
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';
        var chevronIconIncluded = this.state.chevronStateIncluded
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';
        var chevronIconInfo = this.state.chevronStateInfo
            ? 'glyphicon glyphicon-chevron-up pull-right'
            : 'glyphicon glyphicon-chevron-down pull-right';

        var s3BaseUrl = 'https://seilnorge-images.imgix.net/images/';

        const headerSpecification = (
            <Row>
                <Col xs={8}>
                    <h5 className="bold">{lang.sidebarHeadingSpecification}</h5>
                </Col>
                <Col xs={4}>
                    <span className={chevronIconProgram}></span>
                </Col>
            </Row>
        );

        const headerSailAreaHomePort = (
            <Row>
                <Col xs={8}>
                    <h5 className="bold">{lang.sidebarHeadingSailAreaHomePort}</h5>
                </Col>
                <Col xs={4}>
                    <span className={chevronIconIncluded}></span>
                </Col>
            </Row>
        );

        if (!boat) return <Grid />;

        var soldOutBanner;

        switch (locale) {
            case 'en_US':
                soldOutBanner = <UtsolgtEnglish />;
                break;
            default:
                soldOutBanner = <Utsolgt />;
                break;
        }

        // Preprocess boat.text: on 12 January this field was upgraded in CMS to be a TinyMCE field, i.e. we have to expect
        // that boat.text can contain HTML. Since this field will sometimes be non-HTML, sometimes HTML, and that text
        // inside should use <p>s for correct layout (the original template below was <p>{boat.text}</p>, check for non-HTML
        // and if so, pad the text with <p> and </p>.
        // RegExp gotten from: https://stackoverflow.com/a/15458987
        if (!/<[a-z][\s\S]*>/i.test(boat.text[locale])) {
            boat.text[locale] = '<p>' + boat.text[locale] + '</p>';
        }

        console.log('render');

        return (
            <Grid fluid={true}>
                {dataFetched && (
                    <div>
                        <Row className="no-gutter gallery-card">
                            <Col xs={12}>
                                <ImageGallery
                                    items={this.createImageList(boat.images, locale)}
                                    showThumbnails={false}
                                    showPlayButton={false}
                                    showFullscreenButton={false}
                                    userBrowserFullscreen={false}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} mdHidden lgHidden>
                                <div className="book-trip-container">
                                    {boat.soldOut == 0 && (
                                        <Link
                                            to={URLBuilder.boatRequest(this.props.match.params.id)}
                                            className="btn btn-default orange book-trip"
                                        >
                                            {lang.bookingButton}
                                        </Link>
                                    )}
                                    {boat.soldOut == 1 && (
                                        <div className="soldout-banner-small">{soldOutBanner}</div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <h2 className="black">{boat.heading[locale]}</h2>
                            </Col>
                        </Row>
                        <Row className="content">
                            <Col xs={12} md={7}>
                                <Row>
                                    <Col xs={12}>
                                        <p className="bold">{boat.ingress[locale]}</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: boat.text[locale]
                                                    .replace(/\n/g, '<br />')
                                                    .replace(
                                                        /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                        'https://seilnorge-images.imgix.net/images'
                                                    ),
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <LineHorisontal />
                                {boat.quoteText[locale] && (
                                    <Quote
                                        text={boat.quoteText[locale]}
                                        name={boat.quoteName[locale]}
                                    />
                                )}
                            </Col>
                            <Col xs={12} md={5}>
                                <Row>
                                    <Col xs={12} xsHidden smHidden className="centred">
                                        <Link
                                            to={URLBuilder.boatRequest(this.props.match.params.id)}
                                            className="btn btn-default large orange"
                                        >
                                            {lang.bookingButton}
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <PanelGroup
                                            accordion
                                            onSelect={this.toggleChevron.bind(this)}
                                            id="boat-details"
                                        >
                                            {boat.specificationsAndEquipment.length > 0 && (
                                                <Panel eventKey="1" className="accordion-large">
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            {headerSpecification}
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        {boat.specificationsAndEquipment.map(
                                                            function (spec, i) {
                                                                return (
                                                                    <div key={i}>
                                                                        <p className="bold">
                                                                            {spec.heading[locale]}
                                                                        </p>
                                                                        <p
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: spec.text[
                                                                                    locale
                                                                                ]
                                                                                    .replace(
                                                                                        /\n/g,
                                                                                        '<br />'
                                                                                    )
                                                                                    .replace(
                                                                                        /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                                                        'https://seilnorge-images.imgix.net/images'
                                                                                    ),
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </Panel.Body>
                                                </Panel>
                                            )}
                                            {boat.sailAreaAndHomePort.length > 0 && (
                                                <Panel
                                                    eventKey="2"
                                                    onSelect={this.toggleChevron.bind(this)}
                                                    className="accordion-large"
                                                >
                                                    <Panel.Heading>
                                                        <Panel.Title toggle>
                                                            {headerSailAreaHomePort}
                                                        </Panel.Title>
                                                    </Panel.Heading>
                                                    <Panel.Body collapsible>
                                                        {boat.sailAreaAndHomePort.map(function (
                                                            bullet,
                                                            i
                                                        ) {
                                                            return (
                                                                <div key={i}>
                                                                    <p className="bold">
                                                                        {bullet.heading[locale]}
                                                                    </p>
                                                                    <p
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: bullet.text[
                                                                                locale
                                                                            ]
                                                                                .replace(
                                                                                    /\n/g,
                                                                                    '<br />'
                                                                                )
                                                                                .replace(
                                                                                    /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                                                                    'https://seilnorge-images.imgix.net/images'
                                                                                ),
                                                                        }}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </Panel.Body>
                                                </Panel>
                                            )}
                                        </PanelGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Clearfix></Clearfix>
                        {boat.trips.length > 0 && (
                            <HeaderSeparator header={lang.relatedTripsHeading} />
                        )}
                        {boat.trips.length > 0 && (
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        {boat.trips.map(trip => (
                                            <TripCard
                                                key={trip.id}
                                                listing_intro={trip.listingIntro[locale]}
                                                listing_header={trip.listingHeading[locale]}
                                                image={s3BaseUrl + trip.listingImage}
                                                button_link={URLBuilder.trip(
                                                    trip.id,
                                                    trip.slug[locale]
                                                )}
                                                button_text={lang.buttonTextReadMore}
                                            />
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}
            </Grid>
        );
    }
}

const i18n = {
    nb_NO: {
        travelDays: 'dager',
        day: 'Dag',
        sidebarHeadingSpecification: 'Spesifikasjon & Utstyr',
        sidebarHeadingSailAreaHomePort: 'Seilingsområde og hjemmehavn',
        relatedTripsHeading: 'Turer i 2019 med denne båten',
        buttonTextReadMore: 'Les mer',
        bookingButton: 'Be om tilbud og tilgjengelighet',
        waitingListButton: 'Bestill - NB! Få plasser!',
        included: 'Inkludert',
        notIncluded: 'Ikke inkludert',
        currency: '',
    },
    en_US: {
        travelDays: 'days',
        day: 'Day',
        sidebarHeadingSpecification: 'Specifications & Equipment',
        sidebarHeadingSailAreaHomePort: 'Sail Area and Home Port',
        relatedTripsHeading: 'Trips in 2019 with this boat',
        buttonTextReadMore: 'Read more',
        bookingButton: 'Ask us for price and availability',
        waitingListButton: 'Book now - Few available seats!',
        included: 'Included',
        notIncluded: 'Not included',
        currency: ' NOK',
    },
};

function mapStateToProps(state) {
    return {
        token: state.cognito.apiToken,
        dataFetched: state.boat.fetched,
        boat: state.boat.boat,
        locale: state.locale,
        lang: i18n[state.locale],
    };
}

export default connect(mapStateToProps, null)(BoatDetail);
