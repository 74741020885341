import React, { Component } from 'react';

class SoMeFacebook extends Component {
    render() {
        return (
            <svg className="some-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 38">
                <title id="title">Facebook Logo</title>
                <path
                    d="M16.3333851,10.8537487 L10.9481344,10.8537487 L10.9481344,7.40648162 C10.9481344,6.11349705 11.8283136,5.81048622 12.4469902,5.81048622 L16.2440915,5.81048622 L16.2440915,0.11969376 L11.0119155,0.101015011 C5.20570966,0.101015011 3.88544095,4.34524206 3.88544095,7.05988704 L3.88544095,10.8537487 L0.524177134,10.8537487 L0.524177134,16.7147253 L3.88544095,16.7147253 L3.88544095,33.3076814 L10.9481344,33.3076814 L10.9481344,16.7147253 L15.7147084,16.7147253 L16.3333851,10.8537487 Z"
                    id="Fill-1"
                    fill="#FF6E00"
                ></path>
            </svg>
        );
    }
}

export default SoMeFacebook;
