import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from "react-redux";

const i18n = {
    nb_NO: {
        successHeading: 'Tusen takk for bestillingen',
        successDescription: 'Vi sender deg nå mer informasjon om turen også sees vi på brygga.',
    },
    en_US: {
        successHeading: 'Thank you for booking with us',
        successDescription:
            "Will now send you a bit more information about the trip and then we'll see each other at the dock",
    },
};

const BookingThanks = ({ locale }) => {
    const trip = useSelector((state) => state.trip.trip);
    console.log("trip in booking thanks", trip)

    const lang = i18n[locale];
    return (
        <Row className="content short-row">
            <Col xs={8} xsOffset={2}>
                <h2 className="black">{lang.successHeading}</h2>
                <p className="centred">{lang.successDescription}</p>
            </Col>
        </Row>
    );
};

export default BookingThanks;
