import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import '../../../css/quote.css';

class Quote extends Component {
    render() {
        return (
            <Row>
                <Col xs={12} sm={10} smOffset={1}>
                    <div className="quote-container">
                        <p className="quote-mark">“</p>
                        <p
                            className="quote-text"
                            dangerouslySetInnerHTML={{
                                __html: this.props.text
                                    .replace(/\n/g, '<br />')
                                    .replace(
                                        /https:\/\/(www\.)?seilnorge\.no\/images/g,
                                        'https://seilnorge-images.imgix.net/images'
                                    ),
                            }}
                        />
                        <p className="right bold">{this.props.name}</p>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default Quote;
